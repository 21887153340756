import axios from "axios";
import { store } from "../redux/store";
import { updateToken } from "../redux/auth/auth.actions";

export const api = axios.create({
	baseURL: `https://287n318e5m.execute-api.eu-central-1.amazonaws.com/dev`,
});

export const req = axios.create({
	baseURL: `https://287n318e5m.execute-api.eu-central-1.amazonaws.com/dev`,
});

req.interceptors.request.use(
	config => {
		const token = store.getState().auth.currentUser.tokens.access_token;
		if (token) {
			config.headers["Authorization"] = "Bearer " + token;
		}
		config.headers["Content-Type"] = "application/json";
		return config;
	},
	error => {
		Promise.reject(error);
	},
);

req.interceptors.response.use(
	response => {
		return response;
	},
	async function(error) {
		const state = store.getState().auth.currentUser;
		const originalRequest = error.config;
		if (error.response.status === 401 && !originalRequest._retry) {
			originalRequest._retry = true;
			const username = state.username;
			const refreshToken = state.tokens.refresh_token;
			const result = await axios.post(
				"https://287n318e5m.execute-api.eu-central-1.amazonaws.com/dev/api-auth/refresh_token",
				{ username: username, refresh_token: refreshToken },
			);
			store.dispatch(updateToken(result.data.access_token));
			const accessToken = state.tokens.access_token;
			axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
			return req(originalRequest);
		}
		return Promise.reject(error);
	},
);
