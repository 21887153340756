import carouselTypes from "./carousel.types";
import { req } from "../../axios/axios";
import toBase64Handler from "../../custom/helpers/base64handler";
import { NotificationManager } from "react-notifications";
import { CompassCalibrationOutlined } from "@material-ui/icons";

//--------GETTING IMAGES------------
export const getImagesStart = () => ({
  type: carouselTypes.GET_IMAGES_START,
});
export const getImagesSuccess = (carouselImagesArray) => ({
  type: carouselTypes.GET_IMAGES_SUCCESS,
  payload: carouselImagesArray,
});
export const getImagesFailure = (errorMessage) => ({
  type: carouselTypes.GET_IMAGES_FAILURE,
  payload: errorMessage,
});

export const getImagesAsync = () => {
  return async (dispatch) => {
    try {
      dispatch(getImagesStart());
      const result = await req.get("/api-news-carousel/images");
      dispatch(getImagesSuccess(result.data));
    } catch (error) {
      dispatch(getImagesFailure(error.message));
    }
  };
};
//--------ADDING IMAGE--------------
export const addImageStart = () => ({
  type: carouselTypes.ADD_IMAGE_START,
});
export const addImageSuccess = () => ({
  type: carouselTypes.ADD_IMAGE_SUCCESS,
});
export const addImageFailure = (errorMessage) => ({
  type: carouselTypes.ADD_IMAGE_FAILURE,
  payload: errorMessage,
});

export const addImageAsync = (file, message) => {
  return async (dispatch) => {
    try {
      dispatch(addImageStart());
      const result = await toBase64Handler(file);
      await req.post("/api-news-carousel/upload_image", { base64: result });
      await dispatch(getImagesAsync());
      NotificationManager.success(message);
      dispatch(addImageSuccess());
    } catch (error) {
      dispatch(addImageFailure(error.message));
    }
  };
};
//---------DELETING IMAGE-----------
export const deleteImageStart = () => ({
  type: carouselTypes.DELETE_IMAGE_START,
});
export const deleteImageSuccess = () => ({
  type: carouselTypes.DELETE_IMAGE_SUCCESS,
});
export const deleteImageFailure = (errorMessage) => ({
  type: carouselTypes.DELETE_IMAGE_FAILURE,
  payload: errorMessage,
});

export const deleteImageAsync = (id, message) => {
  return async (dispatch) => {
    try {
      dispatch(deleteImageStart());
      await req.delete("/api-news-carousel/delete_image", {
        data: { uuid: id },
      });
      await dispatch(getImagesAsync());
      NotificationManager.success(message);
      dispatch(deleteImageSuccess());
    } catch (error) {
      dispatch(deleteImageFailure(error.message));
    }
  };
};
//-------OTHERS-------------
export const clearErrorMessages = () => ({
  type: carouselTypes.CLEAR_ERROR_MESSAGES,
});
export const clearImages = () => ({
  type: carouselTypes.CLEAR_IMAGES,
});
