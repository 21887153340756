import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { Select as MaterialSelect } from "@material-ui/core";

const Select = ({
	array,
	label,
	value,
	handleChange,
	inputProps,
	style,
	none,
	placeHolder,
	disabled,
	name,
	variant,
	otherProps,
	error,
}) => {
	return (
		<FormControl variant="outlined" style={{ ...style }}>
			<InputLabel id="demo-simple-select-outlined-label">{label}</InputLabel>
			<MaterialSelect
				labelId="demo-simple-select-outlined-label"
				id="demo-simple-select-outlined"
				value={value}
				onChange={handleChange}
				label={label}
				inputProps={inputProps}
				disabled={disabled}
				name={name}
				variant={variant}
				displayEmpty={placeHolder ? true : false}
				error={error}
				{...otherProps}
			>
				{none || placeHolder ? (
					<MenuItem value="" disabled={placeHolder ? true : false}>
						<em>{placeHolder ? placeHolder : "None"}</em>
					</MenuItem>
				) : null}
				{array.map((item, index) => (
					<MenuItem key={index} value={item}>
						{item}
					</MenuItem>
				))}
			</MaterialSelect>
		</FormControl>
	);
};

export default Select;
