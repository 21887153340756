import authTypes from "./auth.types";
import { api } from "../../axios/axios";

export const logInStart = () => ({
	type: authTypes.LOG_IN_START,
});

export const logInSuccess = credentials => ({
	type: authTypes.LOG_IN_SUCCESS,
	payload: credentials,
});

export const logInFailure = message => ({
	type: authTypes.LOG_IN_FAILURE,
	payload: message,
});

export const logOut = () => ({
	type: authTypes.LOG_OUT,
});

export const updateToken = token => ({
	type: authTypes.UPDATE_TOKEN,
	payload: token,
});

//---------thunk-----------
export const logInAsync = credentials => {
	return async dispatch => {
		try {
			dispatch(logInStart());
			const res = await api.post("/api-auth/login", credentials);
			if (res.data.role !== "admin" && res.data.role !== "staff")
				throw "not admin or staff";
			dispatch(logInSuccess(res.data));
		} catch (error) {
			dispatch(logInFailure(error));
		}
	};
};
