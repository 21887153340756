import { createSelector } from "reselect";

export const selectCarousel = (state) => state.carousel;

//-------1ST LEVEL SELECTORS-------------

export const selectCarouselImages = createSelector(
  [selectCarousel],
  (carousel) => carousel.carouselImages
);

export const selectCarouselIsloading = createSelector(
  [selectCarousel],
  (carousel) => carousel.isLoading
);

export const selectCarouselErrorMessages = createSelector(
  [selectCarousel],
  (carousel) => carousel.errorMessages
);
