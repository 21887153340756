import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useIntl, FormattedMessage } from "react-intl";
import {
  LinearProgress,
  CircularProgress,
  Divider,
  TextField,
} from "@material-ui/core";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  selectEnglishDescription,
  selectArabicDescription,
  selectCompanyIsLoading,
  selectCompanyErrorMessages,
} from "../../../redux/company/company.selectors";
import {
  updateCompanyInfoAsync,
  getCompanyInfoAsync,
} from "../../../redux/company/company.actions";

const SettingsPage = ({
  arabicDescription,
  englishDescription,
  isLoading,
  errorMessage,
  getCompanyInfo,
  updateCompanyInfo,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const intl = useIntl();
  const nothing = () => {};

  useEffect(() => {
    getCompanyInfo();
    setIsEditing(false);
  }, []);

  const validationSchema = Yup.object().shape({
    arabic_description: Yup.string().required(
      intl.formatMessage({ id: "THIS.FIELD.IS.REQUIRED" })
    ),
    english_description: Yup.string().required(
      intl.formatMessage({ id: "THIS.FIELD.IS.REQUIRED" })
    ),
  });
  const formik = useFormik({
    initialValues: {
      arabic_description: arabicDescription,
      english_description: englishDescription,
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await updateCompanyInfo(
        {
          desc_arabic: values.arabic_description,
          desc_english: values.english_description,
        },
        intl.formatMessage({ id: "DESCRIPTIONS.WERE.UPDATED" })
      );
      errorMessage.updateCompanyInfo ? nothing() : setIsEditing(false);
    },
  });

  return (
    <>
      <h1
        style={{
          marginBottom: "20px",
          marginTop: "10px",
          fontWeight: "bold",
          fontSize: "40px",
        }}
      >
        <FormattedMessage id="SETTINGS" />
      </h1>
      {isLoading.getCompanyInfo ? (
        <LinearProgress />
      ) : errorMessage.getCompanyInfo ? (
        <div className="mb-2 alert alert-custom alert-light-danger alert-dismissible">
          <div
            className="alert-text font-weight-bold"
            style={{ textAlign: "center" }}
          >
            <FormattedMessage id="SOMETHING.WENT.WRONG.,.PLEASE.TRY.AGAIN" />
          </div>
        </div>
      ) : (
        <div className="card card-custom  gutter-b py-5 px-4">
          <form onSubmit={formik.handleSubmit}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
                marginBottom: "15px",
              }}
            >
              <h1
                style={{
                  fontWeight: "bold",
                  fontSize: "30px",
                }}
              >
                <FormattedMessage id="APP.INFO" />
              </h1>
              {isEditing ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  {" "}
                  <button
                    className="btn btn-danger font-weight-bolder font-size-sm mr-2"
                    disabled={isLoading.updateCompanyInfo}
                    onClick={() => {
                      setIsEditing(false);
                      formik.resetForm();
                    }}
                  >
                    <FormattedMessage id="CANCEL" />
                  </button>{" "}
                  <button
                    className="btn btn-primary font-weight-bolder font-size-sm mr-0"
                    type="submit"
                    disabled={
                      !(formik.dirty && formik.isValid) ||
                      isLoading.updateCompanyInfo
                    }
                  >
                    {isLoading.updateCompanyInfo ? (
                      <CircularProgress size={13} color="inherit" />
                    ) : (
                      <FormattedMessage id="SAVE" />
                    )}
                  </button>{" "}
                </div>
              ) : (
                <a
                  className="btn btn-success font-weight-bolder font-size-sm "
                  onClick={() => {
                    setIsEditing(true);
                  }}
                >
                  <FormattedMessage id="EDIT" />
                </a>
              )}
            </div>
            <Divider style={{ marginBottom: "15px" }} />
            <div className="row px-3">
              <div className="col-md-6 col-s-12">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: "5px",
                  }}
                >
                  <h3>
                    {" "}
                    <FormattedMessage id="ENGLISH.DESCRIPTION" />{" "}
                  </h3>
                  {formik.touched["english_description"] &&
                  formik.errors["english_description"] ? (
                    <span style={{ color: "red" }}>
                      {formik.errors["english_description"]}
                    </span>
                  ) : (
                    <div></div>
                  )}
                </div>
                <TextField
                  dir="ltr"
                  style={{ width: "100%" }}
                  rows={6}
                  multiline
                  variant="outlined"
                  inputProps={{
                    readOnly: !isEditing ? true : false,
                  }}
                  error={
                    formik.touched["english_description"] &&
                    formik.errors["english_description"]
                      ? true
                      : false
                  }
                  {...formik.getFieldProps("english_description")}
                />
              </div>
              <div className="col-md-6 col-s-12">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: "5px",
                  }}
                >
                  <h3>
                    {" "}
                    <FormattedMessage id="ARABIC.DESCRIPTION" />{" "}
                  </h3>
                  {formik.touched["arabic_description"] &&
                  formik.errors["arabic_description"] ? (
                    <span style={{ color: "red" }}>
                      {formik.errors["arabic_description"]}
                    </span>
                  ) : (
                    <div></div>
                  )}
                </div>
                <TextField
                  dir="rtl"
                  style={{ width: "100%" }}
                  rows={6}
                  multiline
                  variant="outlined"
                  inputProps={{
                    readOnly: !isEditing ? true : false,
                  }}
                  error={
                    formik.touched["arabic_description"] &&
                    formik.errors["arabic_description"]
                      ? true
                      : false
                  }
                  {...formik.getFieldProps("arabic_description")}
                />
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  arabicDescription: selectArabicDescription,
  englishDescription: selectEnglishDescription,
  isLoading: selectCompanyIsLoading,
  errorMessage: selectCompanyErrorMessages,
});

const mapDispatchToProps = (dispatch) => ({
  getCompanyInfo: () => dispatch(getCompanyInfoAsync()),
  updateCompanyInfo: (requestBody, message) =>
    dispatch(updateCompanyInfoAsync(requestBody, message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage);
