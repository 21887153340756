import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { LinearProgress, TextField, Divider } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Scrollbar } from "react-scrollbars-custom";
import { useIntl, FormattedMessage } from "react-intl";
import DnsRoundedIcon from "@material-ui/icons/DnsRounded";
import SensorsTableRow from "./SensorsTableRow.component";
import TableWidget from "../../../../custom/widgets/TableWidget/TableWidget.component";
import Select from "../../../../custom/compoents/Select/Select.component";
import CustomForm from "../../../../custom/compoents/customform/CustomForm.component";
import {
  selectAllSensorsArray,
  selectSensorsErrorMessages,
  selectSensorsIsLoading,
} from "../../../../redux/sensors/sensors.selectors";
import {
  clearSensorsErrorMessages,
  getAllSensorsAsync,
  createSensorAsync,
} from "../../../../redux/sensors/sensors.actions";
import { getAllUsersAsync } from "../../../../redux/users/users.actions";
import { selectUsersArray } from "../../../../redux/users/users.selectors";
import {
  getSitesAsync,
  clearSites,
} from "../../../../redux/sites/sites.actions";
import {
  selectSitesArray,
  selectSitesIsLoading,
  selectSitesErrorMessages,
} from "../../../../redux/sites/sites.selectors";

const SensorsTable = ({
  allSensors,
  allUsers,
  sites,
  isLoading,
  errorMessage,
  sitesIsLoading,
  sitesErrorMessage,
  clearErrorMessages,
  clearSites,
  getAllSensors,
  getAllUsers,
  getSites,
  addSensor,
}) => {
  const [selectedUser, setSelectedUser] = useState("");
  const [nameSearchField, setNameSearchField] = useState("");
  const [siteSearchField, setSiteSearchField] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedSensorType, setSelectedSensorType] = useState("");
  const [page, setPage] = useState(1);
  const intl = useIntl();

  useEffect(() => {
    if (allSensors.length == 0) {
      getAllSensors();
      getAllUsers();
    }
    clearSites();
    if (selectedUser) {
      getSites(selectedUser.uuid);
    }
  }, [selectedUser]);
  let sitesNames = [];
  if (sites.length != 0) {
    sites.map((site) => sitesNames.push(site.name));
  }

  let filteredSensorsArray = [];
  if (allSensors) {
    filteredSensorsArray = allSensors.filter((sensor) =>
      !sensor.user_name || !sensor.site_name || !sensor.status || !sensor.type
        ? false
        : sensor.user_name
            .toLowerCase()
            .includes(nameSearchField.toLowerCase()) &&
          sensor.site_name
            .toLowerCase()
            .includes(siteSearchField.toLowerCase()) &&
          sensor.status.toLowerCase().includes(selectedStatus.toLowerCase()) &&
          sensor.type.toLowerCase().includes(selectedSensorType.toLowerCase())
    );
  }

  const addingSensorValidationSchema = Yup.object().shape({
    name: Yup.string().required(
      intl.formatMessage({ id: "THIS.FIELD.IS.REQUIRED" })
    ),
    description: Yup.string().required(
      intl.formatMessage({ id: "THIS.FIELD.IS.REQUIRED" })
    ),
    type: Yup.string().required(
      intl.formatMessage({ id: "THIS.FIELD.IS.REQUIRED" })
    ),
    site: Yup.string().required(
      intl.formatMessage({ id: "THIS.FIELD.IS.REQUIRED" })
    ),
  });
  const nothing = () => {};

  const addingSensorFormik = useFormik({
    validationSchema: addingSensorValidationSchema,
    initialValues: { name: "", description: "", type: "", site: "" },
    enableReinitialize: true,
    onSubmit: async (values) => {
      let requestBody = {
        user_uuid: selectedUser.uuid,
        sensor_description: values.description,
        sensor_name: values.name,
        sensor_type: values.type,
        custom_icon: values.type,
        site_name: values.site,
        status: "enabled",
        site_uuid: sites.filter((site) => site.name == values.site)[0].uuid,
        user_name: selectedUser.name,
        user_username: selectedUser.username,
      };
      await addSensor(
        requestBody,
        intl.formatMessage({ id: "SENSOR.WAS.SUCCESSFULLY.ADDED" })
      );
      !(errorMessage.createSensor && isLoading.createSensor)
        ? setSelectedUser("")
        : nothing();
    },
  });
  const addingSensorFormFields = [
    { value: "name", placeHolder: intl.formatMessage({ id: "NAME" }) },
    {
      value: "description",
      placeHolder: intl.formatMessage({ id: "DESCRIPTION" }),
    },
  ];

  const tableAttributes = {
    table: {
      columns: [
        {
          label: intl.formatMessage({ id: "NAME" }),
        },
        {
          label: intl.formatMessage({ id: "TYPE" }),
        },
        {
          label: intl.formatMessage({ id: "DESCRIPTION" }),
        },
        {
          label: intl.formatMessage({ id: "USER.FULL.NAME" }),
        },
        {
          label: intl.formatMessage({ id: "SITE" }),
        },
        {
          label: intl.formatMessage({ id: "STATUS" }),
        },
        {
          label: intl.formatMessage({ id: "ACTIONS" }),
        },
      ],
      tableRow: (sensor, index) => (
        <SensorsTableRow key={index} sensor={sensor} />
      ),
    },
    emptyMessage: intl.formatMessage({ id: "NO.SENSORS.AVAILABLE" }),
    title: {
      title: intl.formatMessage({ id: "ALL.AVAILABLE.SENSORS.TABLE" }),
      icon: <DnsRoundedIcon />,
    },
    pagination: {
      handleChange: (event, value) => {
        setPage(value);
      },
      rowsPerPage: 7,
      currentPage: page,
    },
    search: [
      {
        placeHolder: intl.formatMessage({ id: "SEARCH.BY.USER.NAME" }),
        handleChange: (e) => {
          setNameSearchField(e.target.value);
        },
        variant: "standard",
        style: { width: "45%", marginRight: "6px", maxWidth: "240px" },
        size: "medium",
      },
      {
        placeHolder: intl.formatMessage({ id: "SEARCH.BY.SENSOR.SITE" }),
        handleChange: (e) => {
          setSiteSearchField(e.target.value);
        },
        variant: "standard",
        style: { width: "45%", marginRight: "6px", maxWidth: "240px" },
        size: "medium",
      },
    ],
    select: [
      {
        array: [
          "sos",
          "window",
          "smoke",
          "temperature&humidity",
          "motion",
          "door",
          "other",
        ],
        handleChange: (e) => {
          setSelectedSensorType(e.target.value);
        },
        value: selectedSensorType,
        label: intl.formatMessage({ id: "SENSOR.TYPE" }),
        variant: "standard",
        style: { width: "35%", marginRight: "6px", maxWidth: "200px" },
      },
      {
        array: ["enabled", "disabled"],
        handleChange: (e) => {
          setSelectedStatus(e.target.value);
        },
        value: selectedStatus,
        label: intl.formatMessage({ id: "STATUS" }),
        variant: "standard",
        style: { width: "35%", marginRight: "6px", maxWidth: "200px" },
      },
    ],
    dialogButtons: [
      {
        buttonTitle: intl.formatMessage({ id: "ADD.SENSOR" }),
        buttonClassName: "btn btn-primary font-weight-bolder font-size-sm",
        dialogTitle: intl.formatMessage({ id: "ADDING.SENSOR" }),
        closingAction: () => {
          clearErrorMessages();
          addingSensorFormik.resetForm();
          setSelectedUser("");
        },
        dialogContent: (
          <Scrollbar style={{ height: 400, width: "100%" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Autocomplete
                style={{ width: "99%" }}
                varian
                options={allUsers}
                value={selectedUser}
                getOptionLabel={(user) => user.name}
                onChange={(event, newValue) => {
                  setSelectedUser(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={intl.formatMessage({ id: "USER.NAME" })}
                    variant="outlined"
                    style={{ marginBottom: "15px", marginTop: "10px" }}
                  />
                )}
              />
              {selectedUser && (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Divider style={{ marginBottom: "15px" }} />
                  {sitesIsLoading.getSites ? (
                    <LinearProgress />
                  ) : sitesErrorMessage.getSites ? (
                    <div
                      style={{ width: "100%" }}
                      className="mb-10 alert alert-custom alert-light-danger alert-dismissible"
                    >
                      <div
                        style={{ textAlign: "center" }}
                        className="alert-text font-weight-bold"
                      >
                        <FormattedMessage id="SOMETHING.WENT.WRONG.,.PLEASE.REFRESH" />
                      </div>
                    </div>
                  ) : sites.length == 0 ? (
                    <div
                      style={{ width: "100%" }}
                      className="mb-10 alert alert-custom alert-light-danger alert-dismissible"
                    >
                      <div
                        style={{ textAlign: "center" }}
                        className="alert-text font-weight-bold"
                      >
                        <FormattedMessage id="THIS.USER.HAS.NO.SITES" />
                      </div>
                    </div>
                  ) : (
                    <CustomForm
                      fields={addingSensorFormFields}
                      errorMessage={errorMessage.createSensor}
                      isLoading={isLoading.createSensor}
                      formik={addingSensorFormik}
                      buttonTitle={intl.formatMessage({ id: "ADD.SENSOR" })}
                      additional={[
                        <Select
                          style={{ width: "100%" }}
                          array={sitesNames}
                          otherProps={addingSensorFormik.getFieldProps("site")}
                          none={true}
                          placeHolder={intl.formatMessage({ id: "SITES" })}
                        />,
                        <Select
                          style={{ width: "100%" }}
                          array={[
                            "sos",
                            "window",
                            "smoke",
                            "temperature&humidity",
                            "motion",
                            "door",
                            "other",
                          ]}
                          otherProps={addingSensorFormik.getFieldProps("type")}
                          none={true}
                          placeHolder={intl.formatMessage({
                            id: "SENSOR.TYPE",
                          })}
                        />,
                      ]}
                    />
                  )}
                </div>
              )}
            </div>
          </Scrollbar>
        ),
      },
    ],
  };

  return (
    <>
      {isLoading.getAllSensors ? (
        <LinearProgress />
      ) : errorMessage.getAllSensors ? (
        <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
          <div
            style={{ textAlign: "center" }}
            className="alert-text font-weight-bold"
          >
            <FormattedMessage id="SOMETHING.WENT.WRONG.,.PLEASE.REFRESH" />
          </div>
        </div>
      ) : (
        <TableWidget
          select={tableAttributes.select}
          search={tableAttributes.search}
          pagination={tableAttributes.pagination}
          dialogButtons={tableAttributes.dialogButtons}
          table={tableAttributes.table}
          title={tableAttributes.title}
          emptyMessage={tableAttributes.emptyMessage}
          givenArray={filteredSensorsArray}
        />
      )}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  allSensors: selectAllSensorsArray,
  allUsers: selectUsersArray,
  sites: selectSitesArray,
  isLoading: selectSensorsIsLoading,
  errorMessage: selectSensorsErrorMessages,
  sitesIsLoading: selectSitesIsLoading,
  sitesErrorMessage: selectSitesErrorMessages,
});

const mapDispatchToProps = (dispatch) => ({
  clearErrorMessages: () => dispatch(clearSensorsErrorMessages()),
  clearSites: () => dispatch(clearSites()),
  getAllSensors: () => dispatch(getAllSensorsAsync()),
  getAllUsers: () => dispatch(getAllUsersAsync()),
  getSites: (uuid) => dispatch(getSitesAsync(uuid)),
  addSensor: (requestBody, message) =>
    dispatch(createSensorAsync(requestBody, false, message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SensorsTable);
