import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Button, Divider, LinearProgress, CircularProgress } from "@material-ui/core";
import SearchBar from "../../../custom/compoents/SearchBar/SearchBar.component";
import Dialog from "../../../custom/compoents/Dialog/Dialog.component";
import Select from "../../../custom/compoents/Select/Select.component";
import UserDetailsCard from "../userdetailspage/components/userdetailscard/UserDetailsCard.component";
import * as Yup from "yup";
import { req } from "../../../axios/axios";
import { useLang } from "../../../_metronic/i18n";
import { useFormik } from "formik";
import { Scrollbar } from "react-scrollbars-custom";
import { useIntl, FormattedMessage } from "react-intl";
import CustomForm from "../../../custom/compoents/customform/CustomForm.component";
import {
	selectUsersIsLoading,
	selectUsersErrorMessages,
	selectUsersArray,
	selectSearchedUser,
} from "../../../redux/users/users.selectors";
import {
	getUserInfoByEmailAsync,
	addUserFailure,
	clearSearchedUser,
	clearUsersErrorMessages,
	addUserAsync,
} from "../../../redux/users/users.actions";
import { selectCurrentUser } from "../../../redux/auth/auth.selectors";

const UsersSearchPage = ({
	users,
	isLoading,
	errorMessages,
	searchedUser,
	clearUsersErrorMessages,
	clearSearchedUser,
	getUserInfoByEmail,
	addUser,
	currentUser,
}) => {
	useEffect(() => {
		clearSearchedUser();
		clearUsersErrorMessages();
	}, []);
	const [searchField, setSearchField] = useState("");
	const [searchButtonStatus, setSearchButtonStatus] = useState(true);
	const [role, setRole] = useState("customer");
	const [password, setPassword] = useState("");
	const currentLanguage = useLang();
	const dispatch = useDispatch();

	const intl = useIntl();

	const formatDate = () => {
		var d = new Date(),
			month = "" + (d.getMonth() + 1),
			day = "" + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2) month = "0" + month;
		if (day.length < 2) day = "0" + day;

		return [year, month, day].join("-");
	};

	const getInputClasses = fieldname => {
		if (addingUserFormik.touched[fieldname] && addingUserFormik.errors[fieldname]) {
			return "is-invalid";
		}

		if (addingUserFormik.touched[fieldname] && !addingUserFormik.errors[fieldname]) {
			return "is-valid";
		}

		return "";
	};
	const addingUserSchema = Yup.object().shape({
		name: Yup.string().required(intl.formatMessage({ id: "THIS.FIELD.IS.REQUIRED" })),
		phone_number: Yup.number()
			.typeError(intl.formatMessage({ id: "ONLY.NUMBERS.ARE.VALID.IN.THIS.FIELD" }))
			.positive(intl.formatMessage({ id: "NUMBERS.SHOULD.BE.POSITIVE" }))
			.required(intl.formatMessage({ id: "THIS.FIELD.IS.REQUIRED" }))
			.test("len", intl.formatMessage({ id: "ONLY.9.DIGITS.ARE.VALID" }), value =>
				value ? value.toString().length == 9 : null,
			),
		address: Yup.string().required(
			intl.formatMessage({ id: "THIS.FIELD.IS.REQUIRED" }),
		),
		email: Yup.string()
			.email(intl.formatMessage({ id: "WRONG.EMAIL.FORMAT" }))
			.required(intl.formatMessage({ id: "THIS.FIELD.IS.REQUIRED" })),
		username: Yup.string().required(
			intl.formatMessage({ id: "THIS.FIELD.IS.REQUIRED" }),
		),
		password: Yup.string()
			.required(intl.formatMessage({ id: "THIS.FIELD.IS.REQUIRED" }))
			.matches(
				/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$/,
				intl.formatMessage({
					id:
						"THE.PASSWORD.MUST.CONTAIN.AT.LEAST.ONE.UPPERCASE.LETTER,.ONE.LOWERCASE.LETTER.AND.ONE.NUMBER",
				}),
			),
		confirm_password: Yup.string()
			.required(intl.formatMessage({ id: "THIS.FIELD.IS.REQUIRED" }))
			.test("len", intl.formatMessage({ id: "PASSWORDS.MUST.MATCH" }), value =>
				value ? value === password : null,
			),
	});

	const addingUserFormik = useFormik({
		initialValues: {
			username: "",
			password: "",
			confirm_password: "",
			name: "",
			email: "",
			phone_number: "",
			address: "",
			start_date: formatDate(),
			end_date: formatDate(),
			type: "prepaid",
		},
		validationSchema: addingUserSchema,
		enableReinitialize: true,
		onSubmit: async values => {
			try {
				const result = await req.get(
					`/api-user/user_info_email?email=${values.email}`,
					{
						params: {
							email: values.email,
						},
					},
				);
				if (result) {
					dispatch(addUserFailure(intl.formatMessage({ id: "EMAIL.ALREADY.USED" })));
				}
			} catch (error) {
				const requestBody = {
					name: values.name,
					username: values.username,
					password: values.password,
					address: values.address,
					email: values.email,
					status: "enabled",
					phone_number: values.phone_number,
					role: role,
					subscription: {
						start_date: values.start_date.split("-").join("/"),
						end_date: values.end_date.split("-").join("/"),
						type: values.type,
					},
				};
				if (role === "admin" || role === "staff") delete requestBody.subscription;
				addUser(
					requestBody,
					intl.formatMessage({ id: "USER.WAS.SUCCESSFULLY.ADDED" }),
				);
			}
		},
	});

	const addingUserFormFields = [
		{ value: "username", placeHolder: intl.formatMessage({ id: "USERNAME" }) },
		{
			value: "password",
			type: "password",
			placeHolder: intl.formatMessage({ id: "PASSWORD" }),
			onChange: true,
			setState: value => setPassword(value),
		},
		{
			value: "confirm_password",
			type: "password",
			placeHolder: intl.formatMessage({ id: "CONFIRM.PASSWORD" }),
		},
		{ value: "name", placeHolder: intl.formatMessage({ id: "FULL.NAME" }) },
		{ value: "email", placeHolder: intl.formatMessage({ id: "EMAIL" }) },
		{
			value: "phone_number",
			placeHolder: intl.formatMessage({ id: "PHONE.NUMBER" }),
		},
		{ value: "address", placeHolder: intl.formatMessage({ id: "ADDRESS" }) },
	];

	const dialogAttributes = {
		buttonTitle: intl.formatMessage({ id: "ADD.USER" }),
		buttonClassName: "btn btn-primary font-weight-bolder font-size-sm mr-3",
		dialogTitle: intl.formatMessage({ id: "ADDING.USER" }),
		closingAction: () => {
			clearUsersErrorMessages();
			addingUserFormik.resetForm();
		},
		dialogContent: (
			<Scrollbar style={{ height: 400, width: "100%" }}>
				<div style={{ display: "flex", flexDirection: "column" }}>
					<Select
						style={{ width: "100%", marginBottom: "15px" }}
						array={
							currentUser.role === "admin"
								? ["customer", "staff", "admin"]
								: ["customer"]
						}
						placeHolder={intl.formatMessage({ id: "ROLE" })}
						handleChange={e => {
							setRole(e.target.value);
							addingUserFormik.resetForm();
						}}
						value={role}
					/>
					<CustomForm
						fields={addingUserFormFields}
						errorMessage={errorMessages.addUser}
						isLoading={isLoading.addUser}
						formik={addingUserFormik}
						buttonTitle={intl.formatMessage({ id: "ADD.USER" })}
						additional={
							role === "customer"
								? [
										<div className="form-group fv-plugins-icon-container">
											<h5>
												{" "}
												<FormattedMessage id="SUBSCRIPTION.START.DATE" />{" "}
											</h5>
											<input
												placeholder={intl.formatMessage({
													id: "SUBSCRIPTION.START.DATE",
												})}
												type="date"
												className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
													"start_date",
												)}`}
												name={"start_date"}
												{...addingUserFormik.getFieldProps("start_date")}
											/>
											{addingUserFormik.touched["start_date"] &&
											addingUserFormik.errors["start_date"] ? (
												<div className="fv-plugins-message-container">
													<div className="fv-help-block">
														{addingUserFormik.errors["start_date"]}
													</div>
												</div>
											) : null}
										</div>,
										<div className="form-group fv-plugins-icon-container">
											<h5>
												{" "}
												<FormattedMessage id="SUBSCRIPTION.END.DATE" />{" "}
											</h5>
											<input
												placeholder={intl.formatMessage({
													id: "SUBSCRIPTION.END.DATE",
												})}
												type="date"
												className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
													"end_date",
												)}`}
												name={"end_date"}
												{...addingUserFormik.getFieldProps("end_date")}
											/>
											{addingUserFormik.touched["end_date"] &&
											addingUserFormik.errors["end_date"] ? (
												<div className="fv-plugins-message-container">
													<div className="fv-help-block">
														{addingUserFormik.errors["end_date"]}
													</div>
												</div>
											) : null}
										</div>,
										<>
											<h5>
												{" "}
												<FormattedMessage id="SUBSCRIPTION.TYPE" />{" "}
											</h5>
											<Select
												style={{ width: "100%" }}
												array={["prepaid"]}
												otherProps={addingUserFormik.getFieldProps("type")}
												placeHolder={intl.formatMessage({
													id: "SUBSCRIPTION.TYPE",
												})}
											/>
										</>,
								  ]
								: []
						}
					/>
				</div>
			</Scrollbar>
		),
	};

	return (
		<>
			{" "}
			<h1
				style={{
					marginBottom: "20px",
					marginTop: "10px",
					fontWeight: "bold",
					fontSize: "40px",
				}}
			>
				<FormattedMessage id="USERS.SEARCH" />
			</h1>
			{isLoading.getAllUsers ? (
				<LinearProgress />
			) : (
				<div className="row">
					<div className="col-lg-12">
						<div className="card card-custom card-stretch gutter-b ">
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "space-between",
									width: "100%",
								}}
								className="card-header border-0 py-3 align-items-center"
							>
								<form
									className="py-3"
									style={{
										display: "flex",
										flexDirection: "row",
									}}
								>
									<div
										style={
											currentLanguage == "en"
												? {
														width: "55%",
														maxWidth: "300px",
														marginRight: "10px",
												  }
												: {
														width: "55%",
														maxWidth: "300px",
														marginLeft: "10px",
												  }
										}
									>
										{" "}
										<SearchBar
											placeHolder={intl.formatMessage({
												id: "SEARCH.BY.EMAIL",
											})}
											handleChange={e => setSearchField(e.target.value)}
											variant="outlined"
											fullWidth={true}
											size="small"
										/>
									</div>
									<div
										style={{
											width: "15vw",
											maxWidth: "110px",
											marginTop: "3px",
										}}
									>
										<Button
											disabled={
												!searchButtonStatus || isLoading.getUserInfoByEmail
											}
											variant="contained"
											color="primary"
											type="submit"
											disableRipple
											onClick={() => {
												getUserInfoByEmail(searchField);
											}}
										>
											{isLoading.getUserInfoByEmail ? (
												<CircularProgress size={20} color="inherit" />
											) : (
												<FormattedMessage id="SEARCH" />
											)}
										</Button>
									</div>
								</form>
								<div>
									<Dialog
										buttonTitle={dialogAttributes.buttonTitle}
										buttonClassName={dialogAttributes.buttonClassName}
										dialogTitle={dialogAttributes.dialogTitle}
										closingAction={dialogAttributes.closingAction}
									>
										{dialogAttributes.dialogContent}
									</Dialog>
								</div>
							</div>
							<div style={{ padding: "10px" }}>
								{searchedUser ? (
									errorMessages.getUserInfoByEmail ? (
										<div>
											{" "}
											<Divider style={{ marginBottom: "15px" }} />
											<h2 style={{ textAlign: "center", margin: "20px" }}>
												<FormattedMessage id="USER.NOT.FOUND" />
											</h2>
										</div>
									) : isLoading.getUserInfoByEmail ? (
										<LinearProgress />
									) : (
										<div>
											<Divider style={{ marginBottom: "15px" }} />
											<UserDetailsCard
												userRole={searchedUser.role}
												notificationTokens={
													searchedUser.notification_tokens
														? searchedUser.notification_tokens
														: null
												}
												user={Object.keys(searchedUser).reduce(
													(object, key) => {
														if (
															key !== "role" &&
															key !== "uuid" &&
															key !== "subscription" &&
															key !== "notification_tokens" &&
															key !== "updatedAt"
														) {
															object[key] = searchedUser[key];
														} else if (
															key === "subscription" &&
															searchedUser.role !== "admin"
														) {
															object["start_date"] =
																searchedUser[key].start_date;
															object["end_date"] =
																searchedUser[key].end_date;
															object["type"] = searchedUser[key].type;
														} else if (key === "uuid") {
															object["uuid"] = searchedUser[key];
														}
														return object;
													},
													{},
												)}
												changeSearchButtonSearch={boolean =>
													setSearchButtonStatus(boolean)
												}
											/>
										</div>
									)
								) : errorMessages.getUserInfoByEmail ? (
									<div>
										{" "}
										<Divider style={{ marginBottom: "15px" }} />
										<h2 style={{ textAlign: "center", margin: "20px" }}>
											<FormattedMessage id="USER.NOT.FOUND" />
										</h2>
									</div>
								) : null}
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

const mapStateToProps = () =>
	createStructuredSelector({
		users: selectUsersArray,
		isLoading: selectUsersIsLoading,
		errorMessages: selectUsersErrorMessages,
		searchedUser: selectSearchedUser,
		currentUser: selectCurrentUser,
	});
const mapDispatchToProps = dispatch => ({
	getUserInfoByEmail: email => dispatch(getUserInfoByEmailAsync(email)),
	addUser: (requestBody, message) => dispatch(addUserAsync(requestBody, message)),
	clearUsersErrorMessages: () => dispatch(clearUsersErrorMessages()),
	clearSearchedUser: () => dispatch(clearSearchedUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersSearchPage);
