import React, { useState, useEffect } from "react";
import { Carousel } from "react-bootstrap";
import { Typography } from "@material-ui/core";
import { useLang } from "../../../_metronic/i18n";
import Dialog from "../../compoents/Dialog/Dialog.component";
import { FormattedMessage, useIntl } from "react-intl";
import {
  selectCarouselImages,
  selectCarouselErrorMessages,
  selectCarouselIsloading,
} from "../../../redux/carousel/carousel.selectors";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  addImageAsync,
  deleteImageAsync,
  clearErrorMessages,
} from "../../../redux/carousel/carousel.actions";
import CircularProgress from "@material-ui/core/CircularProgress";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

const ImagesCarousel = ({
  isLoading,
  errorMessage,
  carouselImages,
  startAddingImage,
  clearErrorMessages,
  startDeletingImage,
}) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [image, setImage] = useState();
  const [imageToBeDeleted, setImageToBeDeleted] = useState();
  const handleSelect = (selectedIndex, e) => {
    setCurrentImage(selectedIndex);
  };

  const currentLang = useLang();
  const intl = useIntl();

  const attributes = {
    adding: {
      buttonTitle: intl.formatMessage({ id: "ADD.IMAGE" }),
      buttonClassName: "btn btn-primary font-weight-bolder font-size-sm mr-2",
      dialogTitle: intl.formatMessage({ id: "ADDING.A.PHOTO" }),
      closingAction: () => {
        setImage();
        clearErrorMessages();
      },
      action: () =>
        startAddingImage(image, intl.formatMessage({ id: "IMAGE.UPLOADED" })),
      errorMessage: errorMessage.addImage,
      isLoading: isLoading.addImage,
      dialogActions: [
        {
          close: false,
          disabled: !image || isLoading.addImage,
          buttonTitle: isLoading.addImage ? (
            <CircularProgress size={20} color="inherit" />
          ) : (
            intl.formatMessage({ id: "UPLOAD" })
          ),
          buttonProps: {
            variant: "contained",
            color: "primary",
            disableRipple: true,
            autoFocus: true,
          },
        },
      ],
    },
    deleting: {
      buttonTitle: intl.formatMessage({ id: "DELETE" }),
      buttonClassName: "btn btn-danger font-weight-bolder font-size-sm mr-0",
      dialogTitle: intl.formatMessage({ id: "YOU'RE.ABOUT.TO.DELETE.A.PHOTO" }),
      closingAction: () => {
        clearErrorMessages();
        setImageToBeDeleted();
      },
      openingAction: () => setImageToBeDeleted(currentImage),
      action: async () => {
        const id = carouselImages[imageToBeDeleted].uuid;
        await startDeletingImage(
          id,
          intl.formatMessage({ id: "IMAGE.DELETED" })
        );
      },
      errorMessage: errorMessage.deleteImage,
      isLoading: isLoading.deleteImage,
      dialogActions: [
        {
          close: true,
          disabled: isLoading.deleteImage,
          buttonTitle: intl.formatMessage({ id: "CANCEL" }),
          buttonProps: {
            variant: "contained",
            color: "primary",
            disableRipple: true,
            autoFocus: true,
          },
        },
        {
          close: false,
          disabled: isLoading.deleteImage,
          buttonTitle: isLoading.deleteImage ? (
            <CircularProgress size={20} color="inherit" />
          ) : (
            intl.formatMessage({ id: "DELETE" })
          ),
          buttonProps: {
            variant: "contained",
            color: "secondary",
            disableRipple: true,
            autoFocus: true,
          },
        },
      ],
    },
  };

  return (
    <div className="card card-custom card-stretch gutter-b">
      {/* Head */}
      <div
        style={{
          paddingLeft: "1.25rem",
          paddingRight: "1.25rem",
        }}
        className="card-header border-0 py-5 align-items-center justify-content-between"
      >
        <h1
          style={{
            fontWeight: "bold",
            fontSize: "23px",
            alignItems: "start",
            marginTop: "7px",
          }}
        >
          <FormattedMessage id="CAROUSEL" />
        </h1>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Dialog
            buttonTitle={attributes.adding.buttonTitle}
            buttonClassName={attributes.adding.buttonClassName}
            dialogTitle={attributes.adding.dialogTitle}
            dialogActions={attributes.adding.dialogActions}
            closingAction={attributes.adding.closingAction}
            action={attributes.adding.action}
            errorMessage={attributes.adding.errorMessage}
            isLoading={attributes.adding.isLoading}
          >
            <input
              onChange={(e) => setImage(e.target.files[0])}
              style={{ margin: "10px" }}
              type="file"
            />
          </Dialog>
          <Dialog
            buttonTitle={attributes.deleting.buttonTitle}
            buttonClassName={attributes.deleting.buttonClassName}
            dialogTitle={attributes.deleting.dialogTitle}
            dialogActions={attributes.deleting.dialogActions}
            closingAction={attributes.deleting.closingAction}
            action={attributes.deleting.action}
            errorMessage={attributes.deleting.errorMessage}
            openingAction={attributes.deleting.openingAction}
            isLoading={attributes.deleting.isLoading}
          >
            <Typography>
              {" "}
              <FormattedMessage id="ARE.YOU.SURE.THAT.YOU.WANT.TO.DELETE.THIS.PHOTO" />
            </Typography>
          </Dialog>
        </div>
      </div>
      <div
        style={{
          marginBottom: "15px",
          paddingLeft: "1.25rem",
          paddingRight: "1.25rem",
        }}
        className="card-body pt-0 pb-3"
      >
        <div className="tab-content">
          <Carousel
            prevIcon={
              currentLang === "ar" ? (
                <span
                  aria-hidden="true"
                  className="carousel-control-next-icon"
                />
              ) : (
                <span
                  aria-hidden="true"
                  className="carousel-control-prev-icon"
                />
              )
            }
            nextIcon={
              currentLang === "ar" ? (
                <span
                  aria-hidden="true"
                  className="carousel-control-prev-icon"
                />
              ) : (
                <span
                  aria-hidden="true"
                  className="carousel-control-next-icon"
                />
              )
            }
            activeIndex={currentImage}
            onSelect={handleSelect}
          >
            {carouselImages.length ? (
              carouselImages.map((image, index) => (
                <Carousel.Item key={index}>
                  <img
                    className="d-block w-100"
                    src={image.url}
                    style={{ objectFit: "cover", height: "300px" }}
                    alt="carousel"
                  />
                </Carousel.Item>
              ))
            ) : (
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={toAbsoluteUrl("/media/bg/no_images_yet.png")}
                  style={{ objectFit: "cover", height: "400px" }}
                  alt="carousel"
                />
              </Carousel.Item>
            )}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  carouselImages: selectCarouselImages,
  isLoading: selectCarouselIsloading,
  errorMessage: selectCarouselErrorMessages,
});

const mapDispatchToProps = (dispatch) => ({
  startAddingImage: (image, message) => dispatch(addImageAsync(image, message)),
  startDeletingImage: (id, message) => dispatch(deleteImageAsync(id, message)),
  clearErrorMessages: () => dispatch(clearErrorMessages()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImagesCarousel);
