import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  selectAuthErrorMessage,
  selectAuthIsLoading,
} from "../../../../redux/auth/auth.selectors";
import { logInAsync } from "../../../../redux/auth/auth.actions";
import { createStructuredSelector } from "reselect";

const initialValues = {
  username: "",
  password: "",
};

const Login = ({ intl, errorMessage, isLoading, logIn }) => {
  const LoginSchema = Yup.object().shape({
    username: Yup.string().required(
      intl.formatMessage({
        id: "THIS.FIELD.IS.REQUIRED",
      })
    ),
    password: Yup.string().required(
      intl.formatMessage({
        id: "THIS.FIELD.IS.REQUIRED",
      })
    ),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const onSubmit = (credintials) => {
    logIn(credintials);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: onSubmit,
  });

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-5 mb-lg-10">
        <h3 className="font-size-h1">
          <FormattedMessage id="LOGIN" />
        </h3>
        <p className="text-muted font-weight-bold">
          <FormattedMessage id="ENTER.YOUR.USERNAME.AND.PASSWORD" />
        </p>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {errorMessage ? (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div
              style={{ textAlign: "center" }}
              className="alert-text font-weight-bold"
            >
              <FormattedMessage id="USERNAME.OR.PASSWORD.WAS.WRONG" />
            </div>
          </div>
        ) : null}

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={intl.formatMessage({ id: "USERNAME" })}
            type="username"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "username"
            )}`}
            name="username"
            {...formik.getFieldProps("username")}
          />
          {formik.touched.username && formik.errors.username ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.username}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={intl.formatMessage({ id: "PASSWORD" })}
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={isLoading || !(formik.dirty && formik.isValid)}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
            style={{ width: "100%" }}
          >
            <span>
              <FormattedMessage id="LOGIN" />
            </span>
            {isLoading ? (
              <span className="ml-3 spinner spinner-white"></span>
            ) : null}
          </button>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  errorMessage: selectAuthErrorMessage,
  isLoading: selectAuthIsLoading,
});

const mapDispatchToProps = (dispatch) => ({
  logIn: (credintials) => dispatch(logInAsync(credintials)),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Login));
