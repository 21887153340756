const companyTypes = {
  GET_COMPANY_INFO_START: "GET_COMPANY_INFO_START",
  GET_COMPANY_INFO_SUCCESS: "GET_COMPANY_INFO_SUCCESS",
  GET_COMPANY_INFO_FAILURE: "GET_COMPANY_INFO_FAILURE",
  UPDATE_COMPANY_INFO_START: "UPDATE_COMPANY_INFO_START",
  UPDATE_COMPANY_INFO_SUCCESS: "UPDATE_COMPANY_INFO_SUCCESS",
  UPDATE_COMPANY_INFO_FAILURE: "UPDATE_COMPANY_INFO_FAILURE",
};

export default companyTypes;
