import React, { useState } from "react";
import { TextField, Typography } from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import SettingsRoundedIcon from "@material-ui/icons/SettingsRounded";
import { useFormik } from "formik";
import { useLang } from "../../../../../_metronic/i18n";
import * as Yup from "yup";
import Dialog from "../../../../../custom/compoents/Dialog/Dialog.component";
import CustomForm from "../../../../../custom/compoents/customform/CustomForm.component";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Scrollbar } from "react-scrollbars-custom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useHistory } from "react-router-dom";
import { useIntl, FormattedMessage } from "react-intl";
import {
	selectUsersErrorMessages,
	selectUsersIsLoading,
} from "../../../../../redux/users/users.selectors";
import {
	clearUsersErrorMessages,
	resetPasswordAsync,
	changeStatusAsync,
	updateUserAsync,
	changePasswordAsync,
} from "../../../../../redux/users/users.actions";
import {
	clearNotificationsErrorMessages,
	sendNotificationToSingleUserAsync,
} from "../../../../../redux/notifications/notifications.actions";
import {
	selectNotificationsErrorMessages,
	selectNotificationsIsLoading,
} from "../../../../../redux/notifications/notifications.selectors";
import { selectCurrentUser } from "../../../../../redux/auth/auth.selectors";

const UserDetailsCard = ({
	user,
	currentUser,
	userRole,
	notificationTokens,
	visitedUser,
	changeSearchButtonSearch,
	clearUsersErrorMessages,
	clearNotificationsErrorMessages,
	resetPassword,
	changePassword,
	changeStatus,
	updateUser,
	sendNotification,
	errorMessage,
	isLoading,
	notificationsErrorMessage,
	notificationsIsLoading,
}) => {
	const [isEditing, setIsEditing] = useState(false);

	const nothing = () => {};
	const history = useHistory();
	const currentLanguage = useLang();
	const intl = useIntl();
	//--------UPDATING USER DETAILS------------
	const updatingUserDetailsSchema = Yup.object().shape({
		name: Yup.string().required(intl.formatMessage({ id: "THIS.FIELD.IS.REQUIRED" })),
		phone_number: Yup.number()
			.typeError(intl.formatMessage({ id: "ONLY.NUMBERS.ARE.VALID.IN.THIS.FIELD" }))
			.positive(intl.formatMessage({ id: "NUMBERS.SHOULD.BE.POSITIVE" }))
			.required(intl.formatMessage({ id: "THIS.FIELD.IS.REQUIRED" }))
			.test("len", intl.formatMessage({ id: "ONLY.9.DIGITS.ARE.VALID" }), value =>
				value ? value.toString().length == 9 : null,
			),
		email: Yup.string()
			.email(intl.formatMessage({ id: "WRONG.EMAIL.FORMAT" }))
			.required(intl.formatMessage({ id: "THIS.FIELD.IS.REQUIRED" })),
		address: Yup.string().required(
			intl.formatMessage({ id: "THIS.FIELD.IS.REQUIRED" }),
		),
	});

	const updatingUserDetailsFormik = useFormik({
		initialValues: Object.keys(user).reduce((object, key) => {
			if (key == "start_date" || key == "end_date") {
				object[key] = user[key].split("/").join("-");
			} else if (key != "uuid") {
				object[key] = user[key];
			}
			return object;
		}, {}),
		enableReinitialize: true,
		validationSchema: updatingUserDetailsSchema,
		onSubmit: async values => {
			const requestBody =
				userRole === "admin" || userRole === "staff"
					? {
							user_uuid: user.uuid,
							name: values.name,
							address: values.address,
							email: values.email,
							phone_number: values.phone_number,
					  }
					: {
							user_uuid: user.uuid,
							name: values.name,
							address: values.address,
							email: values.email,
							phone_number: values.phone_number,
							subscription: {
								start_date: values.start_date.split("-").join("/"),
								end_date: values.end_date.split("-").join("/"),
								type: values.type,
							},
					  };
			await updateUser(
				requestBody,
				visitedUser ? false : user.email,
				visitedUser ? user.uuid : false,
				{
					success: intl.formatMessage({
						id: "USER.DETAILS.WERE.SUCCESSFULLY.UPDATED",
					}),
					error: intl.formatMessage({
						id: "SOMETHING.WENT.WRONG.,.PLEASE.TRY.AGAIN",
					}),
				},
			);
			errorMessage.updateUser ? nothing() : setIsEditing(false);
			changeSearchButtonSearch ? changeSearchButtonSearch(true) : nothing();
		},
	});
	const updatingUserDetailsFormLabels = {
		username: intl.formatMessage({ id: "USERNAME" }),
		name: intl.formatMessage({ id: "FULL.NAME" }),
		email: intl.formatMessage({ id: "EMAIL" }),
		phone_number: intl.formatMessage({ id: "PHONE.NUMBER" }),
		status: intl.formatMessage({ id: "STATUS" }),
		address: intl.formatMessage({ id: "ADDRESS" }),
		start_date: intl.formatMessage({ id: "SUBSCRIPTION.START.DATE" }),
		end_date: intl.formatMessage({ id: "SUBSCRIPTION.END.DATE" }),
		type: intl.formatMessage({ id: "SUBSCRIPTION.TYPE" }),
	};
	//--------------CHANGING USER PASSWORD-------------
	const changingUserPasswordSchema = Yup.object().shape({
		old_password: Yup.string()
			.required(intl.formatMessage({ id: "THIS.FIELD.IS.REQUIRED" }))
			.matches(
				/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$/,
				intl.formatMessage({
					id:
						"THE.PASSWORD.MUST.CONTAIN.AT.LEAST.ONE.UPPERCASE.LETTER,.ONE.LOWERCASE.LETTER.AND.ONE.NUMBER",
				}),
			),
		new_password: Yup.string()
			.required(intl.formatMessage({ id: "THIS.FIELD.IS.REQUIRED" }))
			.matches(
				/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$/,
				intl.formatMessage({
					id:
						"THE.PASSWORD.MUST.CONTAIN.AT.LEAST.ONE.UPPERCASE.LETTER,.ONE.LOWERCASE.LETTER.AND.ONE.NUMBER",
				}),
			),
		confirm_new_password: Yup.string()
			.required(intl.formatMessage({ id: "THIS.FIELD.IS.REQUIRED" }))
			.matches(
				/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$/,
				intl.formatMessage({
					id:
						"THE.PASSWORD.MUST.CONTAIN.AT.LEAST.ONE.UPPERCASE.LETTER,.ONE.LOWERCASE.LETTER.AND.ONE.NUMBER",
				}),
			),
	});
	const changingUserPasswordFormik = useFormik({
		initialValues: {
			old_password: "",
			new_password: "",
			confirm_new_password: "",
		},
		enableReinitialize: true,
		validationSchema: changingUserPasswordSchema,
		onSubmit: values =>
			changePassword(
				{
					old_password: values.old_password,
					new_password: values.new_password,
					confirm_new_password: values.confirm_new_password,
				},
				user.email,
				intl.formatMessage({ id: "PASSWORD.WAS.SUCCESSFULLY.CHANGED" }),
			),
	});
	const changingUserPasswordFields = [
		{
			value: "old_password",
			placeHolder: intl.formatMessage({ id: "OLD.PASSWORD" }),
			type: "password",
		},
		{
			value: "new_password",
			placeHolder: intl.formatMessage({ id: "NEW.PASSWORD" }),
			type: "password",
		},
		{
			value: "confirm_new_password",
			placeHolder: intl.formatMessage({ id: "CONFIRM.NEW.PASSWORD" }),
			type: "password",
		},
	];

	//--------------SENDING NOTIFICATION-------------
	const sendingNotificationValidationSchema = Yup.object().shape({
		title: Yup.string().required(intl.formatMessage({ id: "THIS.FIELD.IS.REQUIRED" })),
		description: Yup.string().required(
			intl.formatMessage({ id: "THIS.FIELD.IS.REQUIRED" }),
		),
	});

	const sendingNotificationFormik = useFormik({
		initialValues: { title: "", description: "", url: "" },
		enableReinitialize: true,
		validationSchema: sendingNotificationValidationSchema,
		onSubmit: values => {
			sendNotification(
				{
					title: values.title,
					description: values.description,
					notification_tokens: notificationTokens,
				},
				visitedUser ? false : user.email,
				visitedUser ? user.uuid : false,
				intl.formatMessage({ id: "NOTIFICATION.WAS.SENT" }),
			);
		},
	});

	const ability = {
		className: {
			disabled: "danger",
			enabled: "success",
		},
		dialogTitle: {
			disabled: intl.formatMessage({ id: "ACTIVATE" }),
			enabled: intl.formatMessage({ id: "DEACTIVATE" }),
		},
		reverse: {
			disabled: "enabled",
			enabled: "disabled",
		},
	};
	const attributes = {
		ability: {
			buttonClassName: `btn btn-icon btn-light btn-sm w-100 ${
				currentUser.username === user.username ? "disabled" : ""
			}`,
			buttonTitle: (
				<span className="svg-icon svg-icon-md svg-icon-primary ">
					<SVG
						title={intl.formatMessage({ id: "CHANGE.STATUS" })}
						src={toAbsoluteUrl("/media/svg/icons/General/Settings-1.svg")}
					/>{" "}
					<span className="ml-2">
						{" "}
						<FormattedMessage id="CHANGE.STATUS" />{" "}
					</span>
				</span>
			),
			dialogTitle:
				intl.formatMessage({ id: "YOU'RE.ABOUT.TO" }) +
				ability.dialogTitle[user.status] +
				intl.formatMessage({ id: "THIS.ACCOUNT" }),
			closingAction: () => clearUsersErrorMessages(),
			action: () =>
				changeStatus(
					{
						username: user.username,
						user_uuid: user.uuid,
						status: ability.reverse[user.status],
					},
					visitedUser ? false : user.email,
					visitedUser ? user.uuid : false,
					intl.formatMessage({ id: "STATUS.WAS.SUCCESSFULLY.CHANGED" }),
				),
			isLoading: isLoading.changeStatus,
			errorMessage: errorMessage.changeStatus,
			dialogActions: [
				{
					close: false,
					disabled: isLoading.changeStatus,
					buttonTitle: isLoading.changeStatus ? (
						<CircularProgress size={20} color="inherit" />
					) : (
						intl.formatMessage({ id: "YES" })
					),
					buttonProps: {
						variant: "contained",
						color: "secondary",
						disableRipple: true,
						autoFocus: true,
					},
				},
				{
					close: true,
					disabled: isLoading.changeStatus,
					buttonTitle: intl.formatMessage({ id: "NO" }),
					buttonProps: {
						variant: "contained",
						color: "primary",
						disableRipple: true,
						autoFocus: true,
					},
				},
			],
		},
		resetting: {
			buttonClassName: "btn btn-icon btn-light btn-sm w-100",
			buttonTitle: (
				<span className="svg-icon svg-icon-md svg-icon-primary">
					<SVG
						title={intl.formatMessage({ id: "RESET.PASSWORD" })}
						src={toAbsoluteUrl("/media/svg/icons/General/Lock.svg")}
					/>{" "}
					<span className="ml-2">
						{" "}
						<FormattedMessage id="RESET.PASSWORD" />{" "}
					</span>
				</span>
			),
			dialogTitle: intl.formatMessage({ id: "RESETTING.PASSWORD" }),
			closingAction: () => clearUsersErrorMessages(),
			action: () =>
				resetPassword(
					user.username,
					intl.formatMessage({ id: "PASSWORD.WAS.SUCCESSFULLY.RESETTED" }),
				),
			isLoading: isLoading.resetPassword,
			errorMessage: errorMessage.resetPassword,
			dialogActions: [
				{
					close: false,
					disabled: isLoading.resetPassword,
					buttonTitle: isLoading.resetPassword ? (
						<CircularProgress size={20} color="inherit" />
					) : (
						intl.formatMessage({ id: "RESET" })
					),
					buttonProps: {
						variant: "contained",
						color: "secondary",
						disableRipple: true,
						autoFocus: true,
					},
				},
				{
					close: true,
					disabled: isLoading.resetPassword,
					buttonTitle: intl.formatMessage({ id: "NO" }),
					buttonProps: {
						variant: "contained",
						color: "primary",
						disableRipple: true,
						autoFocus: true,
					},
				},
			],
		},
		changingPassword: {
			buttonClassName: "btn btn-icon btn-light btn-sm w-100",
			buttonTitle: (
				<span className="svg-icon svg-icon-md svg-icon-primary">
					<SVG
						title={intl.formatMessage({ id: "CHANGE.PASSWORD" })}
						src={toAbsoluteUrl("/media/svg/icons/General/Lock.svg")}
					/>{" "}
					<span className="ml-2">
						{" "}
						<FormattedMessage id="CHANGE.PASSWORD" />{" "}
					</span>
				</span>
			),
			dialogTitle: intl.formatMessage({ id: "CHANGING.PASSWORD" }),
			closingAction: () => clearUsersErrorMessages(),
			dialogContent: (
				<CustomForm
					formik={changingUserPasswordFormik}
					isLoading={isLoading.changePassword}
					errorMessage={errorMessage.changePassword}
					fields={changingUserPasswordFields}
					buttonTitle={intl.formatMessage({ id: "CHANGE.PASSWORD" })}
				/>
			),
		},
		sendingNotification: {
			buttonClassName: "btn btn-icon btn-light btn-sm w-100",
			buttonTitle: (
				<span className="svg-icon svg-icon-md svg-icon-primary">
					<SVG
						title={intl.formatMessage({ id: "SEND.NOTIFICATION" })}
						src={toAbsoluteUrl("/media/svg/icons/Communication/Send.svg")}
					/>{" "}
					<span className="ml-2">
						{" "}
						<FormattedMessage id="SEND.NOTIFICATION" />{" "}
					</span>
				</span>
			),
			dialogTitle: intl.formatMessage({ id: "SENDING.NOTIFICATION" }),
			closingAction: () => clearNotificationsErrorMessages(),
			dialogContent: (
				<Scrollbar style={{ height: 400, width: "100%" }}>
					<CustomForm
						fields={[]}
						errorMessage={notificationsErrorMessage.sendNotificationToSingleUser}
						isLoading={notificationsIsLoading.sendNotificationToSingleUser}
						formik={sendingNotificationFormik}
						buttonTitle={intl.formatMessage({ id: "SEND.NOTIFICATION" })}
						additional={[
							<div style={{ display: "flex", flexDirection: "column" }}>
								<h5>
									{" "}
									<FormattedMessage id="TITLE" />{" "}
								</h5>
								<TextField
									variant="outlined"
									fullWidth
									style={
										sendingNotificationFormik.touched["title"] &&
										sendingNotificationFormik.errors["title"]
											? null
											: { marginBottom: "20px" }
									}
									error={
										sendingNotificationFormik.touched["title"] &&
										sendingNotificationFormik.errors["title"]
											? true
											: false
									}
									{...sendingNotificationFormik.getFieldProps("title")}
								/>
								{sendingNotificationFormik.touched["title"] &&
								sendingNotificationFormik.errors["title"] ? (
									<span style={{ marginBottom: "20px" }} className="text-danger">
										{sendingNotificationFormik.errors["title"]}
									</span>
								) : null}
							</div>,
							<div style={{ display: "flex", flexDirection: "column" }}>
								<h5>
									{" "}
									<FormattedMessage id="DESCRIPTION" />{" "}
								</h5>
								<TextField
									variant="outlined"
									fullWidth
									multiline
									rows={4}
									style={
										sendingNotificationFormik.touched["description"] &&
										sendingNotificationFormik.errors["description"]
											? null
											: { marginBottom: "20px" }
									}
									error={
										sendingNotificationFormik.touched["description"] &&
										sendingNotificationFormik.errors["description"]
											? true
											: false
									}
									{...sendingNotificationFormik.getFieldProps("description")}
								/>
								{sendingNotificationFormik.touched["description"] &&
								sendingNotificationFormik.errors["description"] ? (
									<span style={{ marginBottom: "20px" }} className="text-danger">
										{sendingNotificationFormik.errors["description"]}
									</span>
								) : null}
							</div>,
						]}
					/>
				</Scrollbar>
			),
		},
	};

	return (
		<>
			<div className="card card-custom card-stretch gutter-b pt-3">
				<div className="row">
					<form onSubmit={updatingUserDetailsFormik.handleSubmit}>
						<div
							style={{
								padding: "10px",
								display: "flex",
								flexDirection: "row",
								justifyContent: "space-between",
								width: "100%",
								alignItems: "center",
								marginBottom: "15px",
							}}
						>
							<div
								style={
									currentLanguage == "en"
										? {
												display: "flex",
												flexDirection: "row",
												marginLeft: "20px",
										  }
										: {
												display: "flex",
												flexDirection: "row",
												marginRight: "20px",
										  }
								}
							>
								<AccountCircle />
								<h4
									style={
										currentLanguage == "en"
											? { marginLeft: "3px" }
											: { marginRight: "3px" }
									}
								>
									<FormattedMessage id="USER.DETAILS" />
								</h4>
							</div>
							{(userRole === "admin" || userRole === "staff") &&
							currentUser.role === "staff" &&
							currentUser.username !== user.username ? (
								<div></div>
							) : (
								<div>
									{isEditing ? (
										<div
											style={
												currentLanguage == "en"
													? {
															display: "flex",
															flexDirection: "row",
															marginRight: "20px",
													  }
													: {
															display: "flex",
															flexDirection: "row",
															marginLeft: "20px",
													  }
											}
										>
											<button
												className="btn btn-danger font-weight-bolder font-size-sm mr-2"
												disabled={isLoading.updateUser}
												onClick={() => {
													setIsEditing(false);
													updatingUserDetailsFormik.resetForm();
													changeSearchButtonSearch
														? changeSearchButtonSearch(true)
														: nothing();
												}}
											>
												<FormattedMessage id="CANCEL" />
											</button>{" "}
											<button
												className="btn btn-primary font-weight-bolder font-size-sm mr-0"
												type="submit"
												disabled={
													!(
														updatingUserDetailsFormik.dirty &&
														updatingUserDetailsFormik.isValid
													) || isLoading.updateUser
												}
											>
												{isLoading.updateUser ? (
													<CircularProgress size={13} color="inherit" />
												) : (
													intl.formatMessage({ id: "SAVE" })
												)}
											</button>{" "}
										</div>
									) : (
										<a
											className="btn btn-success font-weight-bolder font-size-sm mr-7"
											onClick={() => {
												setIsEditing(true);
												changeSearchButtonSearch
													? changeSearchButtonSearch(false)
													: nothing();
											}}
										>
											<FormattedMessage id="EDIT" />
										</a>
									)}
								</div>
							)}
						</div>
						<div className="row mb-3 px-15">
							{Object.keys(user).map((key, index) => (
								<>
									{(userRole === "admin" || userRole === "staff") &&
									index > 6 ? null : (
										<>
											{index == 7 ? (
												<div
													className="col-xxl-12"
													style={{
														width: "100%",
														margin: "auto",
														marginBottom: "20px",
													}}
												>
													<h2
														style={{
															textAlign: "start",
														}}
													>
														<FormattedMessage id="SUBSCRIPTION" />
													</h2>
												</div>
											) : null}
											{key == "uuid" ? null : (
												<div
													key={index}
													className={`col-md-${
														index > 6 ? "4" : "6"
													} col-s-12`}
												>
													<div
														style={{
															width: "100%",
															margin: "auto",
															display: "flex",
															flexDirection: "column",
															alignItems: "start",
														}}
													>
														<h6> {updatingUserDetailsFormLabels[key]} </h6>{" "}
														{index > 6 ? (
															<div className="form-group fv-plugins-icon-container mb-2 w-100">
																<input
																	type={index == 9 ? "text" : "date"}
																	className={`form-control h-auto py-5 px-6 `}
																	readOnly={!isEditing ? true : false}
																	name={key}
																	{...updatingUserDetailsFormik.getFieldProps(
																		key,
																	)}
																/>
															</div>
														) : (
															<TextField
																key={index}
																fullWidth
																style={
																	updatingUserDetailsFormik.touched[
																		key
																	] &&
																	updatingUserDetailsFormik.errors[key]
																		? null
																		: { marginBottom: "20px" }
																}
																variant="outlined"
																InputProps={{
																	readOnly:
																		key === "username" ||
																		key === "status" ||
																		!isEditing
																			? true
																			: false,
																}}
																error={
																	updatingUserDetailsFormik.touched[
																		key
																	] &&
																	updatingUserDetailsFormik.errors[key]
																		? true
																		: false
																}
																{...updatingUserDetailsFormik.getFieldProps(
																	key,
																)}
															/>
														)}
														{updatingUserDetailsFormik.touched[key] &&
														updatingUserDetailsFormik.errors[key] ? (
															<span
																style={{ marginBottom: "20px" }}
																className="text-danger"
															>
																{updatingUserDetailsFormik.errors[key]}
															</span>
														) : null}
													</div>
												</div>
											)}
										</>
									)}
								</>
							))}
						</div>
					</form>
					{(userRole === "admin" || userRole === "staff") &&
					currentUser.role === "staff" &&
					currentUser.username !== user.username ? (
						<></>
					) : (
						<div
							style={{
								padding: "10px",
								display: "flex",
								flexDirection: "row",
								width: "100%",
								alignItems: "start",
							}}
						>
							<div
								style={
									currentLanguage == "en"
										? {
												display: "flex",
												flexDirection: "row",
												marginLeft: "20px",
										  }
										: {
												display: "flex",
												flexDirection: "row",
												marginRight: "20px",
										  }
								}
							>
								<SettingsRoundedIcon />
								<h4
									style={
										currentLanguage == "en"
											? { marginLeft: "3px" }
											: { marginRight: "3px" }
									}
								>
									<FormattedMessage id="USER.SETTINGS" />
								</h4>
							</div>
						</div>
					)}
					{(userRole === "admin" || userRole === "staff") &&
					currentUser.role === "staff" &&
					currentUser.username !== user.username ? (
						<></>
					) : (
						<div
							style={{
								width: "85%",
								margin: "auto",
							}}
							className="row mb-3"
						>
							<div
								className={`col-sm-${
									userRole === "admin" ||
									userRole === "staff" ||
									(!visitedUser && userRole === "customer")
										? "6"
										: "4"
								} col-12 mt-2 text-center`}
							>
								{user.username === currentUser.username ? (
									<Dialog
										buttonClassName={
											attributes.changingPassword.buttonClassName
										}
										buttonTitle={attributes.changingPassword.buttonTitle}
										dialogTitle={attributes.changingPassword.dialogTitle}
									>
										{attributes.changingPassword.dialogContent}
									</Dialog>
								) : (
									<Dialog
										buttonClassName={attributes.resetting.buttonClassName}
										buttonTitle={attributes.resetting.buttonTitle}
										dialogTitle={attributes.resetting.dialogTitle}
										dialogActions={attributes.resetting.dialogActions}
										action={attributes.resetting.action}
										errorMessage={attributes.resetting.errorMessage}
										isLoading={attributes.resetting.isLoading}
										closingAction={attributes.resetting.closingAction}
									>
										<Typography>
											<FormattedMessage id="ARE.YOU.SURE.THAT.U.WANT.TO.RESET.THIS.ACCOUNT'S.PASSWORD" />
										</Typography>
									</Dialog>
								)}
							</div>
							<div
								className={`col-sm-${
									userRole === "admin" ||
									userRole === "staff" ||
									(!visitedUser && userRole === "customer")
										? "6"
										: "4"
								} col-12 mt-2 text-center`}
							>
								<Dialog
									buttonClassName={attributes.ability.buttonClassName}
									buttonTitle={attributes.ability.buttonTitle}
									dialogTitle={attributes.ability.dialogTitle}
									dialogActions={attributes.ability.dialogActions}
									action={attributes.ability.action}
									errorMessage={attributes.ability.errorMessage}
									isLoading={attributes.ability.isLoading}
									closingAction={attributes.ability.closingAction}
								>
									<Typography>
										<FormattedMessage id="ARE.YOU.SURE.THAT.U.WANT.TO" />
										{ability.dialogTitle[user.status]}{" "}
										<FormattedMessage id="THIS.ACCOUNT" />
									</Typography>
								</Dialog>
							</div>
							{userRole === "customer" ? (
								<div
									className={`col-sm-${
										userRole === "admin" ||
										userRole === "staff" ||
										(!visitedUser && userRole === "customer")
											? "6"
											: "4"
									} col-12 mt-2 text-center`}
								>
									<Dialog
										buttonClassName={
											attributes.sendingNotification.buttonClassName
										}
										buttonTitle={attributes.sendingNotification.buttonTitle}
										dialogTitle={attributes.sendingNotification.dialogTitle}
										closingAction={attributes.sendingNotification.closingAction}
									>
										{attributes.sendingNotification.dialogContent}
									</Dialog>{" "}
								</div>
							) : null}
							{visitedUser ||
							userRole === "admin" ||
							userRole === "staff" ? null : (
								<div className="col-sm-6 col-12 mt-2 text-center">
									<a
										onClick={() => {
											history.push({
												pathname: "/userdetails",
												state: {
													uuid: user.uuid,
												},
											});
										}}
										className="btn btn-icon btn-light btn-sm w-100"
									>
										<span className="svg-icon svg-icon-md svg-icon-primary ">
											<SVG
												src={toAbsoluteUrl(
													"/media/svg/icons/General/User.svg",
												)}
												title={intl.formatMessage({ id: "VISIT.USER" })}
											/>{" "}
											<span className="ml-2">
												{" "}
												<FormattedMessage id="VISIT.USER" />{" "}
											</span>
										</span>
									</a>
								</div>
							)}
						</div>
					)}
				</div>
			</div>
		</>
	);
};

const mapStateToProps = createStructuredSelector({
	isLoading: selectUsersIsLoading,
	errorMessage: selectUsersErrorMessages,
	notificationsErrorMessage: selectNotificationsErrorMessages,
	notificationsIsLoading: selectNotificationsIsLoading,
	currentUser: selectCurrentUser,
});

const mapDispatchToProps = dispatch => ({
	clearUsersErrorMessages: () => dispatch(clearUsersErrorMessages()),
	resetPassword: (username, message) => dispatch(resetPasswordAsync(username, message)),
	changeStatus: (requestBody, email, uuid, message) =>
		dispatch(changeStatusAsync(requestBody, email, uuid, message)),
	updateUser: (requestBody, email, uuid, messages) =>
		dispatch(updateUserAsync(requestBody, email, uuid, messages)),
	changePassword: (requestBody, email, message) =>
		dispatch(changePasswordAsync(requestBody, email, message)),
	clearNotificationsErrorMessages: () => dispatch(clearNotificationsErrorMessages()),
	sendNotification: (requestBody, email, uuid, message) =>
		dispatch(sendNotificationToSingleUserAsync(requestBody, email, uuid, message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserDetailsCard);
