import authReducer from "./auth/auth.reducer";
import usersReducer from "./users/users.reducer";
import carouselReducer from "./carousel/carousel.reducer";
import sitesReducer from "./sites/sites.reducer";
import sensorsReducer from "./sensors/sensors.reducer";
import companyReducer from "./company/company.reducer";
import NotificationsReducer from "./notifications/notifications.reducer";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";

const persistConfig = {
  key: "root",
  storage,
  blacklist: [
    "auth",
    "carousel",
    "users",
    "sites",
    "sensors",
    "company",
    "notifications",
  ],
};
//----------AUTH-----------
const authPersistConfig = {
  key: "auth",
  storage,
  blacklist: ["isLoading", "errorMessage"],
};
//----------USERS-----------
const usersPersistConfig = {
  key: "users",
  storage,
  blacklist: ["isLoading", "errorMessages"],
};
//----------CAROUSEL--------
const carouselPersistConfig = {
  key: "carousel",
  storage,
  blacklist: ["isLoading", "errorMessages"],
};
//----------SITES--------
const sitesPersistConfig = {
  key: "sites",
  storage,
  blacklist: ["isLoading", "errorMessages"],
};
//----------SENSORS--------
const sensorsPersistConfig = {
  key: "sensors",
  storage,
  blacklist: ["isLoading", "errorMessages"],
};
//----------COMPANY--------
const companyPersistConfig = {
  key: "company",
  storage,
  blacklist: ["isLoading", "errorMessages"],
};
//----------COMPANY--------
const notificationsPersistConfig = {
  key: "notifications",
  storage,
  blacklist: ["isLoading", "errorMessages"],
};

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  users: persistReducer(usersPersistConfig, usersReducer),
  carousel: persistReducer(carouselPersistConfig, carouselReducer),
  sites: persistReducer(sitesPersistConfig, sitesReducer),
  sensors: persistReducer(sensorsPersistConfig, sensorsReducer),
  company: persistReducer(companyPersistConfig, companyReducer),
  notifications: persistReducer(
    notificationsPersistConfig,
    NotificationsReducer
  ),
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer;
