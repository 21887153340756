import { createSelector } from "reselect";

export const selectAuth = (state) => state.auth;

//-------------1ST-LEVEL-SELECTORS-----------------

export const selectCurrentUser = createSelector(
  [selectAuth],
  (auth) => auth.currentUser
);

export const selectAuthIsLoading = createSelector(
  [selectAuth],
  (auth) => auth.isLoading
);

export const selectAuthErrorMessage = createSelector(
  [selectAuth],
  (auth) => auth.errorMessage
);

//-------------2ND-LEVEL-SELECTORS-----------------

export const selectUsername = createSelector(
  [selectCurrentUser],
  (currentUser) => currentUser.username
);
