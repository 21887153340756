import React, { useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { LinearProgress } from "@material-ui/core";
import ImagesCarouselTips from "../../../custom/widgets/ImagesCarousel/ImagesWidgetTips.component";
import ImagesCarousel from "../../../custom/widgets/ImagesCarousel/ImagesCarousel.component";
import NotificationsCharts from "./NotificationsCharts/NotificationsCharts.component";
import NotificationsTable from "./NotificationsTable/NotificationsTable.component";
import { getImagesAsync } from "../../../redux/carousel/carousel.actions";
import { getNotificationsHistoryAsync } from "../../../redux/notifications/notifications.actions";
import { FormattedMessage } from "react-intl";
import {
  selectNotificationsErrorMessages,
  selectNotificationsIsLoading,
} from "../../../redux/notifications/notifications.selectors";
import {
  selectCarouselErrorMessages,
  selectCarouselIsloading,
} from "../../../redux/carousel/carousel.selectors";

const DashboardPage = ({
  getImages,
  getNotifications,
  carouselIsLoading,
  carouselErrorMessage,
  notificationsIsLoading,
  notificationsErrorMessage,
}) => {
  useEffect(() => {
    getImages();
    getNotifications();
  }, []);
  return (
    <>
      <h1
        style={{
          marginBottom: "20px",
          marginTop: "10px",
          fontWeight: "bold",
          fontSize: "40px",
        }}
      >
        <FormattedMessage id="DASHBOARD" />
      </h1>
      {notificationsIsLoading.getNotificationsHistory ||
      carouselIsLoading.getImages ? (
        <LinearProgress />
      ) : notificationsErrorMessage.getNotificationsHistory ||
        carouselErrorMessage.getImages ? (
        <div
          style={{ textAlign: "center" }}
          className="mb-10 alert alert-custom alert-light-danger alert-dismissible"
        >
          <div className="alert-text font-weight-bold">
            <FormattedMessage id="SOMETHING.WENT.WRONG.,.PLEASE.TRY.AGAIN" />
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-lg-6">
            <ImagesCarouselTips />
          </div>
          <div className="col-lg-6">
            <ImagesCarousel />
          </div>
          <div className="col-lg-12">
            <NotificationsCharts />
          </div>
          <div className="col-lg-12">
            <NotificationsTable />
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  carouselIsLoading: selectCarouselIsloading,
  carouselErrorMessage: selectCarouselErrorMessages,
  notificationsIsLoading: selectNotificationsIsLoading,
  notificationsErrorMessage: selectNotificationsErrorMessages,
});

const mapDispatchToProps = (dispatch) => ({
  getImages: () => dispatch(getImagesAsync()),
  getNotifications: () => dispatch(getNotificationsHistoryAsync()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
