import sensorsTypes from "./sensors.types";
import { req } from "../../axios/axios";
import { NotificationManager } from "react-notifications";

//-----------GET ALL SENSORS------------
export const getAllSensorsStart = () => ({
  type: sensorsTypes.GET_ALL_SENSORS_START,
});
export const getAllSensorsSuccess = (sensors) => ({
  type: sensorsTypes.GET_ALL_SENSORS_SUCCESS,
  payload: sensors,
});
export const getAllSensorsFailure = (errorMesssage) => ({
  type: sensorsTypes.GET_ALL_SENSORS_FAILURE,
  payload: errorMesssage,
});

export const getAllSensorsAsync = () => {
  return async (dispatch) => {
    try {
      dispatch(getAllSensorsStart());
      const result = await req.get("/api-sensors/sensors");
      dispatch(getAllSensorsSuccess(result.data));
    } catch (error) {
      dispatch(getAllSensorsFailure(error.message));
    }
  };
};
//-----------GET SENSORS BY USER------------
export const getSensorsByUserStart = () => ({
  type: sensorsTypes.GET_SENSORS_BY_USER_START,
});
export const getSensorsByUserSuccess = (sensors) => ({
  type: sensorsTypes.GET_SENSORS_BY_USER_SUCCESS,
  payload: sensors,
});
export const getSensorsByUserFailure = (errorMessage) => ({
  type: sensorsTypes.GET_SENSORS_BY_USER_FAILURE,
  payload: errorMessage,
});

export const getSensorsByUserAsync = (uuid) => {
  return async (dispatch) => {
    try {
      dispatch(getSensorsByUserStart());
      const result = await req.get(
        `/api-sensors/user_sensors?user_uuid=${uuid}`,
        {
          params: {
            user_uuid: uuid,
          },
        }
      );
      dispatch(getSensorsByUserSuccess(result.data));
    } catch (error) {
      dispatch(getSensorsByUserFailure(error.message));
    }
  };
};
//-----------GET SENSORS BY SITE------------
export const getSensorsBySiteStart = () => ({
  type: sensorsTypes.GET_SENSORS_BY_SITE_START,
});
export const getSensorsBySiteSuccess = (sensors) => ({
  type: sensorsTypes.GET_SENSORS_BY_SITE_SUCCESS,
  payload: sensors,
});
export const getSensorsBySiteFailure = (errorMessage) => ({
  type: sensorsTypes.GET_SENSORS_BY_SITE_FAILURE,
  payload: errorMessage,
});

export const getSensorsBySiteAsync = (user_uuid, site_uuid) => {
  return async (dispatch) => {
    try {
      dispatch(getSensorsBySiteStart());
      const result = await req.get(
        `/api-sensors/site_sensors?user_uuid=${user_uuid}&site_uuid=${site_uuid}`,
        {
          params: {
            user_uuid: user_uuid,
            site_uuid: site_uuid,
          },
        }
      );
      dispatch(getSensorsBySiteSuccess(result.data));
    } catch (error) {
      dispatch(getSensorsBySiteFailure(error.message));
    }
  };
};
//-----------GET SENSOR INFO------------
export const getSensorInfoStart = () => ({
  type: sensorsTypes.GET_SENSOR_INFO_START,
});
export const getSensorInfoSuccess = (info) => ({
  type: sensorsTypes.GET_SENSOR_INFO_SUCCESS,
  payload: info,
});
export const getSensorInfoFailure = (errorMessage) => ({
  type: sensorsTypes.GET_SENSOR_INFO_FAILURE,
  payload: errorMessage,
});

export const getSensorInfoAsync = (user_uuid, site_uuid, sensor_uuid) => {
  return async (dispatch) => {
    try {
      dispatch(getSensorInfoStart());
      const result = await req.get(
        `/api-sensors/sensor?user_uuid=${user_uuid}&site_uuid=${site_uuid}&sensor_uuid=${sensor_uuid}`,
        {
          params: {
            user_uuid: user_uuid,
            sensor_uuid: sensor_uuid,
            site_uuid: site_uuid,
          },
        }
      );
      dispatch(getSensorInfoSuccess(result.data));
    } catch (error) {
      dispatch(getSensorInfoFailure(error.message));
    }
  };
};
//-----------GET SENSOR READINGS------------
export const getSensorReadingsStart = () => ({
  type: sensorsTypes.GET_SENSOR_READINGS_START,
});
export const getSensorReadingsSuccess = (readings) => ({
  type: sensorsTypes.GET_SENSOR_READINGS_SUCCESS,
  payload: readings,
});
export const getSensorReadingsFailure = (errorMessage) => ({
  type: sensorsTypes.GET_SENSOR_READINGS_FAILURE,
  payload: errorMessage,
});

export const getSensorReadingsAsync = (sensor_uuid) => {
  return async (dispatch) => {
    try {
      dispatch(getSensorReadingsStart());
      const result = await req.get(
        `/api-sensors/sensor_readings?sensor_uuid=${sensor_uuid}`,
        {
          params: {
            sensor_uuid: sensor_uuid,
          },
        }
      );
      result.data.map(
        (reading) =>
          (reading.Data.time =
            reading.Data.time.slice(0, 10) +
            " / " +
            reading.Data.time.slice(11, 19))
      );
      dispatch(getSensorReadingsSuccess(result.data));
    } catch (error) {
      dispatch(getSensorReadingsFailure(error.message));
    }
  };
};
//-----------CREATE SENSOR------------
export const createSensorStart = () => ({
  type: sensorsTypes.CREATE_SENSOR_START,
});
export const createSensorSuccess = () => ({
  type: sensorsTypes.CREATE_SENSOR_SUCCESS,
});
export const createSensorFailure = (errorMessage) => ({
  type: sensorsTypes.CREATE_SENSOR_FAILURE,
  payload: errorMessage,
});

export const createSensorAsync = (requestBody, uuid, message) => {
  return async (dispatch) => {
    try {
      dispatch(createSensorStart());
      await req.post("/api-sensors/sensor", requestBody);
      if (uuid) {
        dispatch(getSensorsByUserAsync(uuid));
      } else {
        dispatch(getAllSensorsAsync());
      }
      NotificationManager.success(message);
      dispatch(createSensorSuccess());
    } catch (error) {
      dispatch(createSensorFailure(error.message));
    }
  };
};
//-----------UPDATE SENSOR------------
export const updateSensorStart = () => ({
  type: sensorsTypes.UPDATE_SENSOR_START,
});
export const updateSensorSuccess = () => ({
  type: sensorsTypes.UPDATE_SENSOR_SUCCESS,
});
export const updateSensorFailure = (errorMessage) => ({
  type: sensorsTypes.UPDATE_SENSOR_FAILURE,
  payload: errorMessage,
});

export const updateSensorAsync = (requestBody, uuid, uuids, messages) => {
  return async (dispatch) => {
    try {
      dispatch(updateSensorStart());
      await req.put("/api-sensors/sensor", requestBody);
      if (uuid) {
        dispatch(getSensorsByUserAsync(uuid));
      } else if (uuids) {
        dispatch(
          getSensorInfoAsync(
            uuids.user_uuid,
            uuids.site_uuid,
            uuids.sensor_uuid
          )
        );
      } else {
        dispatch(getAllSensorsAsync());
      }
      NotificationManager.success(messages.success);
      dispatch(updateSensorSuccess());
    } catch (error) {
      dispatch(updateSensorFailure(error.message));
      NotificationManager.error(messages.error);
    }
  };
};
//-----------DELETE SENSOR------------
export const deleteSensorStart = () => ({
  type: sensorsTypes.DELETE_SENSOR_START,
});
export const deleteSensorSuccess = () => ({
  type: sensorsTypes.DELETE_SENSOR_SUCCESS,
});
export const deleteSensorFailure = (errorMessage) => ({
  type: sensorsTypes.DELETE_SENSOR_FAILURE,
  payload: errorMessage,
});

export const deleteSensorAsync = (requestBody, uuid, message) => {
  return async (dispatch) => {
    try {
      dispatch(deleteSensorStart());
      await req.delete("/api-sensors/sensor", {
        data: requestBody,
      });
      if (uuid) {
        dispatch(getSensorsByUserAsync(uuid));
      } else {
        dispatch(getAllSensorsAsync());
      }
      NotificationManager.success(message);
      dispatch(deleteSensorSuccess());
    } catch (error) {
      dispatch(deleteSensorFailure(error.message));
    }
  };
};
//-----------OTHERS------------
export const clearSensors = () => ({
  type: sensorsTypes.CLEAR_SENSORS,
});
export const clearAllSensors = () => ({
  type: sensorsTypes.CLEAR_ALL_SENSORS,
});
export const clearSensorsErrorMessages = () => ({
  type: sensorsTypes.CLEAR_SENSORS_ERROR_MESSAGES,
});
