const usersTypes = {
  ADD_USER_START: "ADD_USER_START",
  ADD_USER_SUCCESS: "ADD_USER_SUCCESS",
  ADD_USER_FAILURE: "ADD_USER_FAILURE",
  UPDATE_USER_START: "UPDATE_USER_START",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_USER_FAILURE: "UPDATE_USER_FAILURE",
  RESET_PASSWORD_START: "RESET_PASSWORD_START",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",
  CHANGE_PASSWORD_START: "CHANGE_PASSWORD_START",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAILURE: "CHANGE_PASSWORD_FAILURE",
  CHANGE_STATUS_START: "CHANGE_STATUS_START",
  CHANGE_STATUS_SUCCESS: "CHANGE_STATUS_SUCCESS",
  CHANGE_STATUS_FAILURE: "CHANGE_STATUS_FAILURE",
  GET_USER_INFO_BY_UUID_START: "GET_USER_INFO_BY_UUID_START",
  GET_USER_INFO_BY_UUID_SUCCESS: "GET_USER_INFO_BY_UUID_SUCCESS",
  GET_USER_INFO_BY_UUID_FAILURE: "GET_USER_INFO_BY_UUID_FAILURE",
  GET_USER_INFO_BY_EMAIL_START: "GET_USER_INFO_BY_EMAIL_START",
  GET_USER_INFO_BY_EMAIL_SUCCESS: "GET_USER_INFO_BY_EMAIL_SUCCESS",
  GET_USER_INFO_BY_EMAIL_FAILURE: "GET_USER_INFO_BY_EMAIL_FAILURE",
  GET_ALL_USERS_START: "GET_ALL_USERS_START",
  GET_ALL_USERS_SUCCESS: "GET_ALL_USERS_SUCCESS",
  GET_ALL_USERS_FAILURE: "GET_ALL_USERS_FAILURE",
  CLEAR_SEARCHED_USER: "CLEAR_SEARCHED_USER",
  CLEAR_USERS_ERROR_MESSAGES: "CLEAR_USERS_ERROR_MESSAGES",
  CLEAR_SELECTED_USER: "CLEAR_SELECTED_USER",
  CLEAR_STATE: "CLEAR_STATE",
};

export default usersTypes;
