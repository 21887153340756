import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { useIntl, FormattedMessage } from "react-intl";
import {
  selectSensorsErrorMessages,
  selectSensorsIsLoading,
} from "../../../../../redux/sensors/sensors.selectors";
import { selectSelectedUser } from "../../../../../redux/users/users.selectors";
import {
  updateSensorAsync,
  clearSensorsErrorMessages,
  deleteSensorAsync,
} from "../../../../../redux/sensors/sensors.actions";
import Dialog from "../../../../../custom/compoents/Dialog/Dialog.component";
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "../../../../../custom/compoents/Select/Select.component";
import CustomForm from "../../../../../custom/compoents/customform/CustomForm.component";
import SVG from "react-inlinesvg";
import { Typography } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { useFormik } from "formik";
import * as Yup from "yup";

const UserSensorsTableRow = ({
  sensor,
  selectedUser,
  errorMessage,
  isLoading,
  clearErrorMessages,
  deleteSensor,
  updateSensor,
}) => {
  const history = useHistory();
  const intl = useIntl();

  //-------------UPDATING SENSOR-----------------
  const updatingSensorValidationSchema = Yup.object().shape({
    name: Yup.string().required(
      intl.formatMessage({ id: "THIS.FIELD.IS.REQUIRED" })
    ),
    description: Yup.string().required(
      intl.formatMessage({ id: "THIS.FIELD.IS.REQUIRED" })
    ),
    status: Yup.string().required(
      intl.formatMessage({ id: "THIS.FIELD.IS.REQUIRED" })
    ),
  });
  const updatingSensorFormik = useFormik({
    validationSchema: updatingSensorValidationSchema,
    initialValues: {
      name: sensor.name,
      description: sensor.description,
      status: sensor.status,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      let requestBody = {
        sensor_description: values.description,
        sensor_name: values.name,
        sensor_type: sensor.type,
        site_name: sensor.site_name,
        sensor_uuid: sensor.uuid,
        status: values.status,
        custom_icon: sensor.custom_icon,
        user_uuid: sensor.user_uuid,
        site_uuid: sensor.site_uuid,
      };
      updateSensor(requestBody, selectedUser.uuid, {
        success: intl.formatMessage({ id: "SENSOR.WAS.SUCCESSFULLY.UPDATED" }),
        error: intl.formatMessage({
          id: "SOMETHING.WENT.WRONG.,.PLEASE.TRY.AGAIN",
        }),
      });
    },
  });
  const updatingSensorFormFields = [
    { value: "name", placeHolder: intl.formatMessage({ id: "NAME" }) },
    {
      value: "description",
      placeHolder: intl.formatMessage({ id: "DESCRIPTION" }),
    },
  ];
  //--------------------------------------------
  const dialogsAttributes = {
    deletingSensor: {
      buttonTitle: (
        <span className="svg-icon svg-icon-md svg-icon-primary">
          <SVG
            src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}
            title={intl.formatMessage({ id: "DELETE.SENSOR" })}
          />
        </span>
      ),
      buttonClassName: "btn btn-icon btn-light btn-sm",
      dialogTitle: intl.formatMessage({ id: "DELETING.SENSOR" }),
      closingAction: () => clearErrorMessages(),
      isLoading: isLoading.deleteSensor,
      errorMessage: errorMessage.deleteSensor,
      action: () =>
        deleteSensor(
          {
            user_uuid: sensor.user_uuid,
            site_uuid: sensor.site_uuid,
            sensor_uuid: sensor.uuid,
          },
          sensor.user_uuid,
          intl.formatMessage({ id: "SENSOR.WAS.SUCCESSFULLY.DELETED" })
        ),
      dialogActions: [
        {
          close: false,
          disabled: isLoading.deleteSensor,
          buttonTitle: isLoading.deleteSensor ? (
            <CircularProgress size={20} color="inherit" />
          ) : (
            <FormattedMessage id="DELETE" />
          ),
          buttonProps: {
            variant: "contained",
            color: "secondary",
            disableRipple: true,
            autoFocus: true,
          },
        },
        {
          close: true,
          buttonTitle: <FormattedMessage id="NO" />,
          disabled: isLoading.deleteSensor,
          buttonProps: {
            variant: "contained",
            color: "primary",
            disableRipple: true,
            autoFocus: true,
          },
        },
      ],
    },
    editingSensor: {
      buttonTitle: (
        <span className="svg-icon svg-icon-md svg-icon-primary ">
          <SVG
            src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
            title={intl.formatMessage({ id: "UPDATE.SENSOR" })}
          />
        </span>
      ),
      buttonClassName: "btn btn-icon btn-light btn-sm ml-2",
      dialogTitle: intl.formatMessage({ id: "UPDATE.SENSOR" }),
      closingAction: () => {
        clearErrorMessages();
        updatingSensorFormik.resetForm();
      },
    },
  };

  return (
    <tr>
      <td className="pl-0 py-4">
        <div className="d-flex align-items-center">
          <div>
            {/* <SVG src={toAbsoluteUrl("/media/svg/icons/General/Lock.svg")}/> */}
            <span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 ml-3 font-size-lg">
              {sensor.name}
            </span>
          </div>
        </div>
      </td>
      <td className="pl-0 py-4">
        <div className="d-flex align-items-center">
          <div>
            <span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 ml-3 font-size-lg">
              {sensor.type}
            </span>
          </div>
        </div>
      </td>
      <td className="pl-0 py-4">
        <div className="d-flex align-items-center">
          <div>
            <span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 ml-3 font-size-lg">
              {sensor.site_name}
            </span>
          </div>
        </div>
      </td>
      <td className="pl-0 py-4">
        <div className="d-flex align-items-center">
          <div>
            <span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 ml-3 font-size-lg">
              {sensor.description}
            </span>
          </div>
        </div>
      </td>
      <td className="text-left">
        <span
          className={`label label-lg label-light-${
            sensor.status === "disabled" ? "danger" : "success"
          } label-inline`}
        >
          {sensor.status}
        </span>
      </td>
      <td className="text-left ">
        <span
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Dialog
            buttonClassName={dialogsAttributes.deletingSensor.buttonClassName}
            buttonTitle={dialogsAttributes.deletingSensor.buttonTitle}
            dialogTitle={dialogsAttributes.deletingSensor.dialogTitle}
            dialogActions={dialogsAttributes.deletingSensor.dialogActions}
            action={dialogsAttributes.deletingSensor.action}
            closingAction={dialogsAttributes.deletingSensor.closingAction}
            errorMessage={dialogsAttributes.deletingSensor.errorMessage}
            isLoading={dialogsAttributes.deletingSensor.isLoading}
          >
            <Typography>
              <FormattedMessage id="ARE.YOU.SURE.THAT.YOU.WANT.TO.DELETE.THIS.SENSOR" />
            </Typography>
          </Dialog>
          <Dialog
            buttonClassName={dialogsAttributes.editingSensor.buttonClassName}
            buttonTitle={dialogsAttributes.editingSensor.buttonTitle}
            dialogTitle={dialogsAttributes.editingSensor.dialogTitle}
            closingAction={dialogsAttributes.editingSensor.closingAction}
          >
            <CustomForm
              fields={updatingSensorFormFields}
              errorMessage={errorMessage.updateSensor}
              isLoading={isLoading.updateSensor}
              formik={updatingSensorFormik}
              buttonTitle={intl.formatMessage({ id: "SUBMIT.CHANGES" })}
              additional={[
                <Select
                  style={{ width: "100%" }}
                  array={["disabled", "enabled"]}
                  otherProps={{
                    ...updatingSensorFormik.getFieldProps("status"),
                  }}
                  value={updatingSensorFormik.getFieldProps("status").value}
                  none={true}
                  placeHolder={intl.formatMessage({ id: "STATUS" })}
                  error={
                    updatingSensorFormik.touched["status"] &&
                    updatingSensorFormik.errors["status"]
                      ? true
                      : false
                  }
                />,
              ]}
            />
          </Dialog>
          <a
            onClick={() =>
              history.push({
                pathname: "/sensordetails",
                state: {
                  user_uuid: sensor.user_uuid,
                  site_uuid: sensor.site_uuid,
                  sensor_uuid: sensor.uuid,
                },
              })
            }
            className="btn btn-icon btn-light btn-sm ml-2"
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/General/Visible.svg")}
                title={intl.formatMessage({ id: "VISIT.SENSOR" })}
              />
            </span>
          </a>
        </span>
      </td>
    </tr>
  );
};

const mapStateToProps = createStructuredSelector({
  isLoading: selectSensorsIsLoading,
  errorMessage: selectSensorsErrorMessages,
  selectedUser: selectSelectedUser,
});

const mapDispatchToProps = (dispatch) => ({
  clearErrorMessages: () => dispatch(clearSensorsErrorMessages()),
  deleteSensor: (requestBody, uuid, message) =>
    dispatch(deleteSensorAsync(requestBody, uuid, message)),
  updateSensor: (requestBody, uuid, messages) =>
    dispatch(updateSensorAsync(requestBody, uuid, false, messages)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserSensorsTableRow);
