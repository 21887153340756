import authTypes from "./auth.types";

const INITIAL_STATE = {
  currentUser: "",
  errorMessage: "",
  isLoading: false,
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case authTypes.LOG_IN_START:
      return {
        ...state,
        isLoading: true,
      };
    case authTypes.LOG_IN_SUCCESS:
      return {
        ...state,
        currentUser: action.payload,
        errorMessage: "",
        isLoading: false,
      };
    case authTypes.LOG_IN_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
        isLoading: false,
      };
    case authTypes.LOG_OUT:
      return {
        ...state,
        currentUser: "",
      };
    case authTypes.UPDATE_TOKEN:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          tokens: { ...state.currentUser.tokens, access_token: action.payload },
        },
      };
    default:
      return state;
  }
};

export default authReducer;
