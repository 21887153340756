import React, { useMemo } from "react";
import objectPath from "object-path";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { LanguageSelectorDropdown } from "../extras/dropdowns/LanguageSelectorDropdown";
import { QuickUserToggler } from "../extras/QuiclUserToggler";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { selectCurrentUser } from "../../../../redux/auth/auth.selectors";

const Topbar = ({ currentUser }) => {
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      viewLanguagesDisplay: objectPath.get(
        uiService.config,
        "extras.languages.display"
      ),
      viewUserDisplay: objectPath.get(uiService.config, "extras.user.display"),
    };
  }, [uiService]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
      className="topbar"
    >
      <h5>{currentUser.username}</h5>
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        {layoutProps.viewLanguagesDisplay && <LanguageSelectorDropdown />}

        {layoutProps.viewUserDisplay && <QuickUserToggler />}
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(Topbar);
