import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { selectCurrentUser } from "../../../../redux/auth/auth.selectors";
import { logOut } from "../../../../redux/auth/auth.actions";
import { createStructuredSelector } from "reselect";
import { LayoutSplashScreen } from "../../../../_metronic/layout";
import { clearImages } from "../../../../redux/carousel/carousel.actions";

class Logout extends Component {
  componentDidMount() {
    const { logout } = this.props;
    logout();
  }

  render() {
    const { currentUser } = this.props;
    return currentUser ? <LayoutSplashScreen /> : <Redirect to="/auth/login" />;
  }
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => {
    dispatch(logOut());
    dispatch(clearImages());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
