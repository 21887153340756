import React, { useState } from "react";
import SensorReadingsTableRow from "./SensorReadingsTableRow.component";
import TableWidget from "../../../../custom/widgets/TableWidget/TableWidget.component";
import EqualizerRoundedIcon from "@material-ui/icons/EqualizerRounded";
import { useIntl } from "react-intl";

const SensorReadingsTable = ({ sensorReadings, type }) => {
  const [page, setPage] = useState(1);
  const intl = useIntl();

  const readingsColumns = {
    "temperature&humidity": [
      {
        label: intl.formatMessage({ id: "DATE" }),
        minWidth: "100px",
      },
      {
        label: intl.formatMessage({ id: "TIME" }),
      },
      {
        label: intl.formatMessage({ id: "DEVICE.STATUS" }),
      },
      {
        label: intl.formatMessage({ id: "DATA.RATE" }),
      },
      {
        label: intl.formatMessage({ id: "TEMPERATURE.STATUS.AR" }),
      },
      {
        label: intl.formatMessage({ id: "HUMIDITY.STATUS.AR" }),
      },
      {
        label: intl.formatMessage({ id: "TIMEZONE" }),
      },
      {
        label: intl.formatMessage({ id: "BATTERY.VOLTAGE" }),
      },
      {
        label: intl.formatMessage({ id: "HUMIDITY.STATUS" }),
      },
      {
        label: intl.formatMessage({ id: "DEVICE.STATUS.AR" }),
      },
      {
        label: intl.formatMessage({ id: "TEMPERATURE" }),
      },
      {
        label: intl.formatMessage({ id: "HUMIDITY" }),
      },
      {
        label: intl.formatMessage({ id: "TEMPERATURE.STATUS" }),
      },
    ],
    door: [
      {
        label: intl.formatMessage({ id: "DATE" }),
        minWidth: "100px",
      },
      {
        label: intl.formatMessage({ id: "TIME" }),
      },
      {
        label: intl.formatMessage({ id: "ALARM.STATUS.AR" }),
      },
      {
        label: intl.formatMessage({ id: "DEVICE.STATUS" }),
      },
      {
        label: intl.formatMessage({ id: "DATA.RATE" }),
      },
      {
        label: intl.formatMessage({ id: "ALARM.STATUS" }),
      },
      {
        label: intl.formatMessage({ id: "TIMEZONE" }),
      },
      {
        label: intl.formatMessage({ id: "DEVICE.STATUS.AR" }),
      },
    ],
    window: [
      {
        label: intl.formatMessage({ id: "DATE" }),
        minWidth: "100px",
      },
      {
        label: intl.formatMessage({ id: "TIME" }),
      },
      {
        label: intl.formatMessage({ id: "ALARM.STATUS.AR" }),
      },
      {
        label: intl.formatMessage({ id: "DEVICE.STATUS" }),
      },
      {
        label: intl.formatMessage({ id: "DATA.RATE" }),
      },
      {
        label: intl.formatMessage({ id: "ALARM.STATUS" }),
      },
      {
        label: intl.formatMessage({ id: "TIMEZONE" }),
      },
      {
        label: intl.formatMessage({ id: "DEVICE.STATUS.AR" }),
      },
    ],
    sos: [
      {
        label: intl.formatMessage({ id: "DATE" }),
        minWidth: "100px",
      },
      {
        label: intl.formatMessage({ id: "TIME" }),
      },
      {
        label: intl.formatMessage({ id: "ALARM.STATUS.AR" }),
      },
      {
        label: intl.formatMessage({ id: "DEVICE.STATUS" }),
      },
      {
        label: intl.formatMessage({ id: "DATA.RATE" }),
      },
      {
        label: intl.formatMessage({ id: "ALARM.STATUS" }),
      },
      {
        label: intl.formatMessage({ id: "TIMEZONE" }),
      },
      {
        label: intl.formatMessage({ id: "DEVICE.STATUS.AR" }),
      },
    ],
    smoke: [
      {
        label: intl.formatMessage({ id: "DATE" }),
        minWidth: "100px",
      },
      {
        label: intl.formatMessage({ id: "TIME" }),
      },
      {
        label: intl.formatMessage({ id: "ALARM.STATUS.AR" }),
      },
      {
        label: intl.formatMessage({ id: "DEVICE.STATUS" }),
      },
      {
        label: intl.formatMessage({ id: "DATA.RATE" }),
      },
      {
        label: intl.formatMessage({ id: "ALARM.STATUS" }),
      },
      {
        label: intl.formatMessage({ id: "TIMEZONE" }),
      },
      {
        label: intl.formatMessage({ id: "DEVICE.STATUS.AR" }),
      },
    ],
    motion: [
      {
        label: intl.formatMessage({ id: "DATE" }),
        minWidth: "100px",
      },
      {
        label: intl.formatMessage({ id: "TIME" }),
      },
      {
        label: intl.formatMessage({ id: "ALARM.STATUS.AR" }),
      },
      {
        label: intl.formatMessage({ id: "DEVICE.STATUS" }),
      },
      {
        label: intl.formatMessage({ id: "DATA.RATE" }),
      },
      {
        label: intl.formatMessage({ id: "ALARM.STATUS" }),
      },
      {
        label: intl.formatMessage({ id: "TIMEZONE" }),
      },
      {
        label: intl.formatMessage({ id: "DEVICE.STATUS.AR" }),
      },
    ],
    other: [
      {
        label: intl.formatMessage({ id: "DATE" }),
        minWidth: "100px",
      },
      {
        label: intl.formatMessage({ id: "TIME" }),
      },
      {
        label: intl.formatMessage({ id: "ALARM.STATUS.AR" }),
      },
      {
        label: intl.formatMessage({ id: "DEVICE.STATUS" }),
      },
      {
        label: intl.formatMessage({ id: "DATA.RATE" }),
      },
      {
        label: intl.formatMessage({ id: "ALARM.STATUS" }),
      },
      {
        label: intl.formatMessage({ id: "TIMEZONE" }),
      },
      {
        label: intl.formatMessage({ id: "DEVICE.STATUS.AR" }),
      },
    ],
  };
  const tableAttributes = {
    pagination: {
      handleChange: (event, value) => {
        setPage(value);
      },
      rowsPerPage: 7,
      currentPage: page,
    },
    emptyMessage: intl.formatMessage({ id: "NO.READINGS.AVAILABLE" }),
    table: {
      columns: readingsColumns[type],
      tableRow: (value, index) => (
        <SensorReadingsTableRow key={index} sensorReading={value} />
      ),
    },
    title: {
      title: intl.formatMessage({ id: "SENSOR.READINGS" }),
      icon: <EqualizerRoundedIcon />,
    },
  };

  return (
    <>
      <TableWidget
        pagination={tableAttributes.pagination}
        table={tableAttributes.table}
        givenArray={sensorReadings}
        emptyMessage={tableAttributes.emptyMessage}
        title={tableAttributes.title}
      />
    </>
  );
};

export default SensorReadingsTable;
