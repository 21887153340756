import React from "react";
import Dialog from "../../compoents/Dialog/Dialog.component";
import Pagination from "../../compoents/Pagination/Pagination.component";
import SearchBar from "../../compoents/SearchBar/SearchBar.component";
import Select from "../../compoents/Select/Select.component";

const TableWidget = ({
  title,
  search,
  select,
  dialogButtons,
  pagination,
  givenArray,
  table,
  emptyMessage,
}) => {
  return (
    <div className="card card-custom  card-stretch gutter-b pt-3">
      <div className="card-header border-0 align-items-center px-6">
        {" "}
        {title && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              paddingTop: "10px",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              {title.icon ? title.icon : ""}
              <h4 style={title.icon ? { marginLeft: "3px" } : null}>
                {title.title}{" "}
              </h4>
            </div>
            <div>
              {dialogButtons ? (
                dialogButtons.map((dialogButton, index) => (
                  <div style={{ alignItems: "end" }}>
                    <Dialog
                      key={index}
                      buttonTitle={dialogButton.buttonTitle}
                      buttonClassName={dialogButton.buttonClassName}
                      dialogTitle={dialogButton.dialogTitle}
                      closingAction={dialogButton.closingAction}
                    >
                      {dialogButton.dialogContent}
                    </Dialog>
                  </div>
                ))
              ) : (
                <div />
              )}
            </div>
          </div>
        )}
        {!(search || select) && !title ? (
          <div className="card-toolbar">
            {dialogButtons &&
              dialogButtons.map((dialogButton, index) => (
                <Dialog
                  key={index}
                  buttonTitle={dialogButton.buttonTitle}
                  buttonClassName={dialogButton.buttonClassName}
                  dialogTitle={dialogButton.dialogTitle}
                  closingAction={dialogButton.closingAction}
                >
                  {dialogButton.dialogContent}
                </Dialog>
              ))}
          </div>
        ) : null}
        <div
          style={{ padding: "0px", width: "100%" }}
          className="card-body pt-0 pb-0"
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={
                search || select
                  ? {
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      alignItems: "center",
                      marginBottom: "10px",
                    }
                  : null
              }
            >
              {search || select ? (
                <div
                  style={{
                    margin: "5px",
                    marginBottom: "0px",
                    display: "flex",
                    alignItems: "center",
                    width: title ? "100%" : "80%",
                  }}
                >
                  {search
                    ? search.map((item) => (
                        <SearchBar
                          handleChange={item.handleChange}
                          placeHolder={item.placeHolder}
                          variant={item.variant}
                          style={item.style}
                          size={item.size}
                        />
                      ))
                    : null}
                  {select && select.length === 1 && search.length === 1
                    ? select.map((item) => (
                        <Select
                          array={item.array}
                          label={item.label}
                          handleChange={item.handleChange}
                          none={true}
                          style={item.style}
                          variant={item.variant}
                          value={item.value}
                        />
                      ))
                    : null}
                </div>
              ) : null}
              <div
                style={{
                  paddingTop: "12px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {(search || select) && !title
                  ? dialogButtons &&
                    dialogButtons.map((dialogButton, index) => (
                      <div style={{ alignItems: "end" }}>
                        <Dialog
                          key={index}
                          buttonTitle={dialogButton.buttonTitle}
                          buttonClassName={dialogButton.buttonClassName}
                          dialogTitle={dialogButton.dialogTitle}
                          closingAction={dialogButton.closingAction}
                        >
                          {dialogButton.dialogContent}
                        </Dialog>
                      </div>
                    ))
                  : null}
              </div>
            </div>
            {select && select.length > 1 ? (
              <div
                style={{
                  margin: "5px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "start",
                  width: "100%",
                }}
              >
                {select.map((item) => (
                  <Select
                    array={item.array}
                    label={item.label}
                    handleChange={item.handleChange}
                    none={true}
                    style={item.style}
                    variant={item.variant}
                    value={item.value}
                  />
                ))}
              </div>
            ) : null}
            {givenArray.length !== 0 ? (
              <div style={{ marginTop: "12px" }} className="table-responsive">
                <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                  <thead>
                    <tr className="text-left text-uppercase">
                      {table.columns.length !== 0 ? (
                        table.columns.map((column, index) => (
                          <th
                            key={index}
                            className={column.className}
                            style={
                              column.minWidth
                                ? { minWidth: column.minWidth }
                                : {}
                            }
                          >
                            {column.label}
                          </th>
                        ))
                      ) : (
                        <th>No Columns Available</th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {givenArray
                      .filter(
                        (element, index) =>
                          index >=
                            (pagination.currentPage - 1) *
                              pagination.rowsPerPage &&
                          index <
                            pagination.rowsPerPage * pagination.currentPage
                      )
                      .map((value, index) => table.tableRow(value, index))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div
                style={{ width: "100%" }}
                className="my-5 alert alert-custom  alert-light-danger alert-dismissible"
              >
                <div
                  style={{ textAlign: "center" }}
                  className="alert-text font-weight-bold"
                >
                  {emptyMessage}
                </div>
              </div>
            )}
          </div>
        </div>
        <div
          className="card-footer  border-0"
          style={{
            padding: "15px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Pagination
            pagesCount={Math.ceil(givenArray.length / pagination.rowsPerPage)}
            currentPage={pagination.page}
            handleChange={pagination.handleChange}
          />
        </div>
      </div>
    </div>
  );
};

export default TableWidget;
