import React from "react";
import TextField from "@material-ui/core/TextField";

const SearchBar = ({
  placeHolder,
  handleChange,
  fullWidth,
  style,
  variant,
  size,
}) => (
  <TextField
    color="primary"
    label={placeHolder}
    type="search"
    size={size}
    variant={variant}
    onChange={handleChange}
    style={{ ...style }}
    fullWidth={Boolean(fullWidth)}
  />
);

export default SearchBar;
