import React, { useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { KTUtil } from "../../../../_metronic/_assets/js/components/util";
import ApexCharts from "apexcharts";
import { selectNotificationsArray } from "../../../../redux/notifications/notifications.selectors";
import { useIntl, FormattedMessage } from "react-intl";

const NotificationsCharts = ({ notifications }) => {
  const intl = useIntl();
  const getUniversalChartOptions = (height) => {
    const options = {
      series: [
        notifications[0].successful,
        notifications[0].remaining,
        notifications[0].errored,
        notifications[0].failed,
      ],
      chart: {
        height: height,
        type: "pie",
      },
      colors: ["#28a745", "#3a3b3c", "#8b0000", "#d52626"],
      stroke: {
        lineCap: "round",
      },
      labels: [
        intl.formatMessage({ id: "SUCCESSFUL" }),
        intl.formatMessage({ id: "REMAINING" }),
        intl.formatMessage({ id: "ERRORED" }),
        intl.formatMessage({ id: "FAILED" }),
      ],
    };
    return options;
  };
  const getPlatformChartOptions = (height) => {
    const options = {
      series: [
        notifications[0].android_delivery === "untracked"
          ? 0
          : notifications[0].android_delivery,
        notifications[0].ios_delivery === "untracked"
          ? 0
          : notifications[0].ios_delivery,
      ],
      chart: {
        height: height,
        type: "pie",
      },
      colors: ["#a4c639", "#3a3b3c"],
      stroke: {
        lineCap: "round",
      },
      labels: [
        intl.formatMessage({ id: "ANDROID" }),
        intl.formatMessage({ id: "IOS" }),
      ],
    };
    return options;
  };
  useEffect(() => {
    const element = document.getElementById("universal_delivery_chart");
    const element2 = document.getElementById("platform_delivery_chart");
    if (!element) {
      return;
    }

    const height = parseInt(KTUtil.css(element, "height"));
    const options = getUniversalChartOptions(height);
    const options2 = getPlatformChartOptions(height);

    const chart = new ApexCharts(element, options);
    const chart2 = new ApexCharts(element2, options2);
    chart.render();
    chart2.render();
  }, []);
  return (
    <>
      <div calssName="row">
        <div className="card card-custom card-stretch gutter-b">
          {notifications.length === 0 ? (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
              <div
                style={{ textAlign: "center" }}
                className="alert-text font-weight-bold"
              >
                <FormattedMessage id="NO.NOTIFICATIONS.WERE.SENT.YET" />
              </div>
            </div>
          ) : (
            <>
              <div className="card-header border-0 pt-5">
                <h1
                  style={{
                    fontWeight: "bold",
                    fontSize: "23px",
                    alignItems: "start",
                    marginTop: "7px",
                  }}
                >
                  <FormattedMessage id="LATEST.NOTIFICATION.STATS" />
                </h1>
              </div>
              <div className="card-body">
                <div className="row">
                  <div
                    className="col-lg-6"
                    id="universal_delivery_chart"
                    style={{ height: "200px" }}
                  ></div>
                  <div
                    className="col-lg-6"
                    id="platform_delivery_chart"
                    style={{ height: "200px" }}
                  ></div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  notifications: selectNotificationsArray,
});

export default connect(mapStateToProps)(NotificationsCharts);
