import usersTypes from "./users.types";

const INITIAL_STATE = {
  isLoading: {
    addUser: false,
    updateUser: false,
    changeStatus: false,
    resetPassword: false,
    getUserInfoByUuid: false,
    getUserInfoByEmail: false,
    getAllUsers: false,
    changePassword: false,
  },
  errorMessages: {
    addUser: "",
    updateUser: "",
    changeStatus: "",
    resetPassword: "",
    getUserInfoByUuid: "",
    getUserInfoByEmail: "",
    getAllUsers: "",
    changePassword: "",
  },
  users: "",
  searchedUser: "",
  selectedUser: "",
};

const usersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    //-----STARTS----------
    case usersTypes.ADD_USER_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, addUser: true },
      };
    case usersTypes.UPDATE_USER_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, updateUser: true },
      };
    case usersTypes.CHANGE_STATUS_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, changeStatus: true },
      };
    case usersTypes.RESET_PASSWORD_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, resetPassword: true },
      };
    case usersTypes.GET_USER_INFO_BY_UUID_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, getUserInfoByUuid: true },
      };
    case usersTypes.GET_USER_INFO_BY_EMAIL_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, getUserInfoByEmail: true },
      };
    case usersTypes.GET_ALL_USERS_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, getAllUsers: true },
      };
    case usersTypes.CHANGE_PASSWORD_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, changePassword: true },
      };
    //-------SUCCESSES-----------
    case usersTypes.ADD_USER_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, addUser: false },
        errorMessages: { ...state.errorMessages, addUser: "" },
      };
    case usersTypes.UPDATE_USER_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, updateUser: false },
        errorMessages: { ...state.errorMessages, updateUser: "" },
      };
    case usersTypes.CHANGE_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, changeStatus: false },
        errorMessages: { ...state.errorMessages, changeStatus: "" },
      };
    case usersTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, resetPassword: false },
        errorMessages: { ...state.errorMessages, resetPassword: "" },
      };
    case usersTypes.GET_USER_INFO_BY_UUID_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, getUserInfoByUuid: false },
        errorMessages: { ...state.errorMessages, getUserInfoByUuid: "" },
        selectedUser: action.payload,
      };
    case usersTypes.GET_USER_INFO_BY_EMAIL_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, getUserInfoByEmail: false },
        errorMessages: { ...state.errorMessages, getUserInfoByEmail: "" },
        searchedUser: action.payload,
      };
    case usersTypes.GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, getAllUsers: false },
        errorMessages: { ...state.errorMessages, getAllUsers: "" },
        users: action.payload,
      };
    case usersTypes.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, changePassword: false },
        errorMessages: { ...state.errorMessages, changePassword: "" },
      };
    //--------FAILURES---------------
    case usersTypes.ADD_USER_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, addUser: false },
        errorMessages: { ...state.errorMessages, addUser: action.payload },
      };
    case usersTypes.UPDATE_USER_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, updateUser: false },
        errorMessages: { ...state.errorMessages, updateUser: action.payload },
      };
    case usersTypes.CHANGE_STATUS_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, changeStatus: false },
        errorMessages: { ...state.errorMessages, changeStatus: action.payload },
      };
    case usersTypes.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, resetPassword: false },
        errorMessages: {
          ...state.errorMessages,
          resetPassword: action.payload,
        },
      };
    case usersTypes.GET_USER_INFO_BY_UUID_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, getUserInfoByUuid: false },
        errorMessages: {
          ...state.errorMessages,
          getUserInfoByUuid: action.payload,
        },
      };
    case usersTypes.GET_USER_INFO_BY_EMAIL_FAILURE:
      return {
        isLoading: { ...state.isLoading, getUserInfoByEmail: false },
        errorMessages: {
          ...state.errorMessages,
          getUserInfoByEmail: action.payload,
        },
      };
    case usersTypes.GET_ALL_USERS_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, getAllUsers: false },
        errorMessages: { ...state.errorMessages, getAllUsers: action.payload },
      };
    case usersTypes.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, changePassword: false },
        errorMessages: {
          ...state.errorMessages,
          changePassword: action.payload,
        },
      };
    //------OTHERS-------------
    case usersTypes.CLEAR_SEARCHED_USER:
      return {
        ...state,
        searchedUser: "",
      };
    case usersTypes.CLEAR_SELECTED_USER:
      return {
        ...state,
        selectedUser: "",
      };
    case usersTypes.CLEAR_USERS_ERROR_MESSAGES:
      return {
        ...state,
        errorMessages: {
          ...state.errorMessages,
          addUser: "",
          updateUser: "",
          changeStatus: "",
          resetPassword: "",
          getUserInfoByUuid: "",
          getUserInfoByEmail: "",
          getAllUsers: "",
        },
      };
    //-----------DEFAULT------------
    default:
      return state;
  }
};

export default usersReducer;
