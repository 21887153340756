import React from "react";
import { Pagination as MaterialPagination } from "@material-ui/lab";

const Pagination = ({ pagesCount, handleChange, currentPage }) => (
  <div>
    <MaterialPagination
      boundaryCount={2}
      siblingCount={2}
      size="large"
      color="primary"
      count={pagesCount}
      page={currentPage}
      onChange={handleChange}
    />
  </div>
);

export default Pagination;
