import React from "react";
import { FormattedMessage } from "react-intl";

const CustomForm = ({
	fields,
	additional,
	errorMessage,
	isLoading,
	buttonTitle,
	formik,
}) => {
	const getInputClasses = fieldname => {
		if (formik.touched[fieldname] && formik.errors[fieldname]) {
			return "is-invalid";
		}

		if (formik.touched[fieldname] && !formik.errors[fieldname]) {
			return "is-valid";
		}

		return "";
	};

	return (
		<div className="login-form login-signin" style={{ display: "block" }}>
			<form
				style={{ marginRight: "5px" }}
				id="kt_login_signin_form"
				className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
				onSubmit={formik.handleSubmit}
			>
				{errorMessage && (
					<div
						style={{ textAlign: "center" }}
						className="mb-10 alert alert-custom alert-light-danger alert-dismissible"
					>
						<div className="alert-text font-weight-bold">
							<span>
								<FormattedMessage id="SOMETHING.WENT.WRONG.,.PLEASE.TRY.AGAIN" />
							</span>
						</div>
					</div>
				)}

				{fields.map((field, index) => (
					<div className="form-group fv-plugins-icon-container">
						{field.title ? <h5>{field.title}</h5> : null}
						<input
							key={index}
							placeholder={field.placeHolder}
							type={field.type ? field.type : "text"}
							className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
								field.value,
							)}`}
							name={field.value}
							{...formik.getFieldProps(field.value)}
							onChange={
								field.onChange
									? e => {
											formik.handleChange(e);
											field.setState(e.target.value);
									  }
									: formik.handleChange
							}
						/>
						{formik.touched[field.value] && formik.errors[field.value] ? (
							<div className="fv-plugins-message-container">
								<div className="fv-help-block">{formik.errors[field.value]}</div>
							</div>
						) : null}
					</div>
				))}
				{additional
					? additional.map(field => (
							<div style={{ marginBottom: "1rem" }}>{field}</div>
					  ))
					: null}

				<div
					style={{ width: "100%" }}
					className="form-group d-flex flex-wrap flex-center"
				>
					<button
						type="submit"
						disabled={isLoading || !(formik.dirty && formik.isValid)}
						className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
					>
						<span>{buttonTitle}</span>
						{isLoading && <span className="ml-3 spinner spinner-white"></span>}
					</button>
				</div>
			</form>
		</div>
	);
};

export default CustomForm;
