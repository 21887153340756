import React from "react";

const SensorReadingsTableRow = ({ sensorReading }) => {
  return (
    <tr>
      <td className="pl-0 py-8">
        <div className="d-flex align-items-center">
          <div>
            <span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 ml-3 font-size-lg">
              {sensorReading.Data["time"].slice(0, 10)
                ? sensorReading.Data["time"].slice(0, 10)
                : " - "}
            </span>
          </div>
        </div>
      </td>
      <td className="pl-0 py-8">
        <div className="d-flex align-items-center">
          <div>
            <span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 ml-3 font-size-lg">
              {sensorReading.Data["time"].slice(13, 21)
                ? sensorReading.Data["time"].slice(13, 21)
                : " - "}
            </span>
          </div>
        </div>
      </td>
      {Object.keys(sensorReading.Data)
        .filter((data) => {
          if (
            data !== "user_uuid" &&
            data !== "site_uuid" &&
            data !== "type" &&
            data !== "time" &&
            data !== "type_ar" &&
            data !== "dev_id" &&
            sensorReading.Data.type !== "temperature&humidity"
          ) {
            return data;
          } else if (
            data !== "user_uuid" &&
            data !== "site_uuid" &&
            data !== "type" &&
            data !== "time" &&
            data !== "type_ar" &&
            data !== "dev_id" &&
            data !== "alarm_status"
          ) {
            return data;
          }
        })
        .map((reading, index) => (
          <td key={index} className="pl-0 py-8">
            <div className="d-flex align-items-center">
              <div>
                <span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 ml-3 font-size-lg">
                  {sensorReading.Data[reading]
                    ? sensorReading.Data[reading]
                    : " - "}
                </span>
              </div>
            </div>
          </td>
        ))}
    </tr>
  );
};

export default SensorReadingsTableRow;
