/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import SVGInline from "react-svg-inline";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectCurrentUser } from "../../../../../redux/auth/auth.selectors";

const AsideMenuList = ({ layoutProps, currentUser }) => {
	const location = useLocation();
	const getMenuItemActive = (url, hasSubmenu = false) => {
		return checkIsActive(location, url)
			? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
			: "";
	};

	return (
		<>
			{currentUser.role === "admin" ? (
				<ul className={`menu-nav bg-primary ${layoutProps.ulClasses}`}>
					{/*begin::1 Level*/}
					<li
						className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
						aria-haspopup="true"
					>
						<NavLink className="menu-link" to="/dashboard">
							<span className="svg-icon menu-icon">
								<SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
							</span>
							<span className="menu-text text-white">
								{<FormattedMessage id="DASHBOARD" />}
							</span>
						</NavLink>
					</li>
					{/*end::1 Level*/}
					<li
						className={`menu-item ${getMenuItemActive("/userssearch", false)}`}
						aria-haspopup="true"
					>
						<NavLink className="menu-link" to="/userssearch">
							<span className="svg-icon menu-icon">
								<SVG src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")} />
							</span>
							<span className="menu-text text-white">
								{<FormattedMessage id="USERS.SEARCH" />}
							</span>
						</NavLink>
					</li>

					{/*begin::1 Level*/}
					<li
						className={`menu-item ${getMenuItemActive("/users", false)}`}
						aria-haspopup="true"
					>
						<NavLink className="menu-link" to="/users">
							<span className="svg-icon menu-icon">
								<SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
							</span>
							<span className="text-white menu-text">
								{<FormattedMessage id="USERS" />}
							</span>
						</NavLink>
					</li>
					{/*end::1 Level*/}

					{/*begin::1 Level*/}
					<li
						className={`menu-item ${getMenuItemActive("/sensors", false)}`}
						aria-haspopup="true"
					>
						<NavLink className="menu-link" to="/sensors">
							<span className="svg-icon menu-icon">
								<SVG src={toAbsoluteUrl("/media/svg/icons/Devices/Server.svg")} />
							</span>
							<span className="menu-text text-white">
								{<FormattedMessage id="SENSORS" />}
							</span>
						</NavLink>
					</li>
					{/*src/_metronic/_assets/plugins/keenthemes-icons/svg/062-gear.svg*/}
					<li
						className={`menu-item ${getMenuItemActive("/settings", false)}`}
						aria-haspopup="true"
					>
						<NavLink className="menu-link" to="/settings">
							<span className="svg-icon text-white menu-icon">
								<SVG
									src={toAbsoluteUrl("/media/svg/icons/General/Settings-2.svg")}
								/>
							</span>
							<span className="menu-text text-white">
								{<FormattedMessage id="SETTINGS" />}
							</span>
						</NavLink>
					</li>

					{/*end::1 Level*/}
				</ul>
			) : (
				<ul className={`menu-nav bg-primary ${layoutProps.ulClasses}`}>
					<li
						className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
						aria-haspopup="true"
					>
						<NavLink className="menu-link" to="/dashboard">
							<span className="svg-icon menu-icon">
								<SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
							</span>
							<span className="menu-text text-white">
								{<FormattedMessage id="DASHBOARD" />}
							</span>
						</NavLink>
					</li>
					<li
						className={`menu-item ${getMenuItemActive("/userssearch", false)}`}
						aria-haspopup="true"
					>
						<NavLink className="menu-link" to="/userssearch">
							<span className="svg-icon menu-icon">
								<SVG src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")} />
							</span>
							<span className="menu-text text-white">
								{<FormattedMessage id="USERS.SEARCH" />}
							</span>
						</NavLink>
					</li>
				</ul>
			)}
		</>
	);
};

const mapStateToProps = createStructuredSelector({
	currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(AsideMenuList);
