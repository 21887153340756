import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import DashboardPage from "./pages/dashboardpage/DashboardPage";
import SensorsPage from "./pages/sensorspage/SensorsPage";
import SettingsPage from "./pages/settingspage/SettingsPage";
import UsersPage from "./pages/UsersPage/UsersPage";
import UsersSearchPage from "./pages/userssearchpage/usersSearchPage";
import UserDetailsPage from "./pages/userdetailspage/UserDetailsPage";
import Login from "../app/modules/Auth/pages/Login";
import SensorDetailsPage from "./pages/sensordetailspage/SensorDetailsPage/SensorDetailsPage.component";
import { NotificationContainer } from "react-notifications";
import { connect } from "react-redux";
import { selectCurrentUser } from "../redux/auth/auth.selectors";
import { createStructuredSelector } from "reselect";

const BasePage = ({ currentUser }) => {
	const nothing = () => {};

	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<NotificationContainer />
			<Switch>
				<Redirect exact from="/" to="/dashboard" />
				<ContentRoute path="/auth/login" component={Login} />
				{currentUser.role === "admin" ? (
					<ContentRoute path="/users" component={UsersPage} />
				) : (
					nothing()
				)}
				{currentUser.role === "admin" ? (
					<ContentRoute path="/sensors" component={SensorsPage} />
				) : (
					nothing()
				)}
				{currentUser.role === "admin" ? (
					<ContentRoute path="/settings" component={SettingsPage} />
				) : (
					nothing()
				)}
				<ContentRoute path="/dashboard" component={DashboardPage} />
				<ContentRoute path="/userssearch" component={UsersSearchPage} />
				<ContentRoute path="/userdetails" component={UserDetailsPage} />
				<ContentRoute path="/sensordetails" component={SensorDetailsPage} />
				<Redirect to="error/error-v1" />
			</Switch>
		</Suspense>
	);
};

const mapStateToProps = createStructuredSelector({
	currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(BasePage);
