import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import {
	selectUsersArray,
	selectUsersErrorMessages,
	selectUsersIsLoading,
} from "../../../../redux/users/users.selectors";
import * as Yup from "yup";
import { req } from "../../../../axios/axios";
import { useFormik } from "formik";
import { useIntl, FormattedMessage } from "react-intl";
import {
	addUserAsync,
	getAllUsersAsync,
	clearUsersErrorMessages,
	addUserFailure,
} from "../../../../redux/users/users.actions";
import { createStructuredSelector } from "reselect";
import { LinearProgress } from "@material-ui/core";
import TableWidget from "../../../../custom/widgets/TableWidget/TableWidget.component";
import CustomForm from "../../../../custom/compoents/customform/CustomForm.component";
import Select from "../../../../custom/compoents/Select/Select.component";
import UsersTableRow from "./UsersTableRow.component";
import { Scrollbar } from "react-scrollbars-custom";

const UsersTableWidget = ({
	usersArray,
	addUser,
	getAllUsers,
	clearErrorMessages,
	isLoading,
	errorMessage,
}) => {
	const [searchField, setSearchField] = useState("");
	const [selectedStatus, setSelectedStatus] = useState("");
	const [page, setPage] = useState(1);
	const [role, setRole] = useState("customer");
	const [password, setPassword] = useState("");
	const intl = useIntl();
	const dispatch = useDispatch();

	useEffect(() => {
		getAllUsers();
	}, []);

	let filteredUsersArray = [];
	if (usersArray) {
		filteredUsersArray = usersArray.filter(user =>
			!user.username
				? false
				: user.username.toLowerCase().includes(searchField.toLowerCase()),
		);
	}

	const formatDate = () => {
		var d = new Date(),
			month = "" + (d.getMonth() + 1),
			day = "" + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2) month = "0" + month;
		if (day.length < 2) day = "0" + day;

		return [year, month, day].join("-");
	};

	const getInputClasses = fieldname => {
		if (addingUserFormik.touched[fieldname] && addingUserFormik.errors[fieldname]) {
			return "is-invalid";
		}

		if (addingUserFormik.touched[fieldname] && !addingUserFormik.errors[fieldname]) {
			return "is-valid";
		}

		return "";
	};
	const addingUserSchema = Yup.object().shape({
		name: Yup.string().required(intl.formatMessage({ id: "THIS.FIELD.IS.REQUIRED" })),
		phone_number: Yup.number()
			.typeError(intl.formatMessage({ id: "ONLY.NUMBERS.ARE.VALID.IN.THIS.FIELD" }))
			.positive(intl.formatMessage({ id: "NUMBERS.SHOULD.BE.POSITIVE" }))
			.required(intl.formatMessage({ id: "THIS.FIELD.IS.REQUIRED" }))
			.test("len", intl.formatMessage({ id: "ONLY.9.DIGITS.ARE.VALID" }), value =>
				value ? value.toString().length == 9 : null,
			),
		address: Yup.string().required(
			intl.formatMessage({ id: "THIS.FIELD.IS.REQUIRED" }),
		),
		email: Yup.string()
			.email(intl.formatMessage({ id: "WRONG.EMAIL.FORMAT" }))
			.required(intl.formatMessage({ id: "THIS.FIELD.IS.REQUIRED" })),
		username: Yup.string().required(
			intl.formatMessage({ id: "THIS.FIELD.IS.REQUIRED" }),
		),
		password: Yup.string()
			.required(intl.formatMessage({ id: "THIS.FIELD.IS.REQUIRED" }))
			.matches(
				/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$/,
				intl.formatMessage({
					id:
						"THE.PASSWORD.MUST.CONTAIN.AT.LEAST.ONE.UPPERCASE.LETTER,.ONE.LOWERCASE.LETTER.AND.ONE.NUMBER",
				}),
			),
		confirm_password: Yup.string()
			.required(intl.formatMessage({ id: "THIS.FIELD.IS.REQUIRED" }))
			.test("len", intl.formatMessage({ id: "PASSWORDS.MUST.MATCH" }), value =>
				value ? value === password : null,
			),
	});

	const initialiValues = {
		username: "",
		password: "",
		confirm_password: "",
		name: "",
		email: "",
		phone_number: "",
		address: "",
		start_date: formatDate(),
		end_date: formatDate(),
		type: "prepaid",
	};

	const addingUserFormik = useFormik({
		initialValues: initialiValues,
		validationSchema: addingUserSchema,
		enableReinitialize: true,
		onSubmit: async values => {
			try {
				const result = await req.get(
					`/api-user/user_info_email?email=${values.email}`,
					{
						params: {
							email: values.email,
						},
					},
				);
				if (result) {
					dispatch(addUserFailure(intl.formatMessage({ id: "EMAIL.ALREADY.USED" })));
				}
			} catch (error) {
				const requestBody = {
					name: values.name,
					username: values.username,
					password: values.password,
					address: values.address,
					email: values.email,
					status: "enabled",
					phone_number: values.phone_number,
					role: role,
					subscription: {
						start_date: values.start_date.split("-").join("/"),
						end_date: values.end_date.split("-").join("/"),
						type: values.type,
					},
				};
				if (role === "admin" || role === "staff") delete requestBody.subscription;
				addUser(
					requestBody,
					intl.formatMessage({ id: "USER.WAS.SUCCESSFULLY.ADDED" }),
				);
			}
		},
	});

	const addingUserFormFields = [
		{ value: "username", placeHolder: intl.formatMessage({ id: "USERNAME" }) },
		{
			value: "password",
			type: "password",
			placeHolder: intl.formatMessage({ id: "PASSWORD" }),
			onChange: true,
			setState: value => setPassword(value),
		},
		{
			value: "confirm_password",
			type: "password",
			placeHolder: intl.formatMessage({ id: "CONFIRM.PASSWORD" }),
		},
		{ value: "name", placeHolder: intl.formatMessage({ id: "FULL.NAME" }) },
		{ value: "email", placeHolder: intl.formatMessage({ id: "EMAIL" }) },
		{
			value: "phone_number",
			placeHolder: intl.formatMessage({ id: "PHONE.NUMBER" }),
		},
		{ value: "address", placeHolder: intl.formatMessage({ id: "ADDRESS" }) },
	];

	const attributes = {
		emptyMessage: intl.formatMessage({ id: "NO.USERS.AVAILABLE" }),
		table: {
			columns: [
				{
					label: intl.formatMessage({ id: "USERNAME" }),
				},
				{
					label: intl.formatMessage({ id: "FULL.NAME" }),
				},
				{
					label: intl.formatMessage({ id: "EMAIL" }),
				},
				{
					label: intl.formatMessage({ id: "ADDRESS" }),
				},
				{
					label: intl.formatMessage({ id: "PHONE.NUMBER" }),
				},
				{
					label: intl.formatMessage({ id: "STATUS" }),
				},
				{
					label: intl.formatMessage({ id: "ROLE" }),
				},
				{
					label: intl.formatMessage({ id: "SUBSCRIPTION.START.DATE" }),
				},
				{
					label: intl.formatMessage({ id: "SUBSCRIPTION.END.DATE" }),
				},
				{
					label: intl.formatMessage({ id: "SUBSCRIPTION.TYPE" }),
				},
				{
					label: intl.formatMessage({ id: "ACTIONS" }),
				},
			],
			tableRow: (value, index) => <UsersTableRow key={index} user={value} />,
		},
		pagination: {
			handleChange: (event, value) => {
				setPage(value);
			},
			rowsPerPage: 6,
			currentPage: page,
		},
		search: [
			{
				placeHolder: intl.formatMessage({ id: "SEARCH.BY.USERNAME" }),
				handleChange: e => {
					setSearchField(e.target.value);
				},
				variant: "standard",
				size: "small",
				style: { width: "70%", maxWidth: "220px" },
			},
		],
		select: [
			{
				array: ["enabled", "disabled"],
				handleChange: e => {
					setSelectedStatus(e.target.value);
				},
				value: selectedStatus,
				placeholder: intl.formatMessage({ id: "STATUS" }),
				none: true,
				variant: "standard",
				style: { width: "35%", marginRight: "6px", maxWidth: "200px" },
			},
		],
		dialogButtons: [
			{
				buttonTitle: intl.formatMessage({ id: "ADD.USER" }),
				buttonClassName: "btn btn-primary font-weight-bolder font-size-sm mr-3",
				dialogTitle: intl.formatMessage({ id: "ADDING.USER" }),
				closingAction: () => {
					clearErrorMessages();
					addingUserFormik.resetForm();
				},
				dialogContent: (
					<Scrollbar style={{ height: 400, width: "100%" }}>
						<div style={{ display: "flex", flexDirection: "column" }}>
							<Select
								style={{ width: "100%", marginBottom: "15px" }}
								array={["customer", "staff", "admin"]}
								placeHolder="Role"
								handleChange={e => {
									setRole(e.target.value);
									addingUserFormik.resetForm();
								}}
								value={role}
							/>
							<CustomForm
								adding={true}
								fields={addingUserFormFields}
								errorMessage={errorMessage.addUser}
								isLoading={isLoading.addUser}
								formik={addingUserFormik}
								buttonTitle={intl.formatMessage({ id: "ADD.USER" })}
								additional={
									role === "customer"
										? [
												<Select
													style={{ width: "100%" }}
													array={["prepaid"]}
													otherProps={addingUserFormik.getFieldProps("type")}
													placeHolder={intl.formatMessage({
														id: "SUBSCRIPTION.TYPE",
													})}
												/>,
												<div className="form-group fv-plugins-icon-container">
													<h5>
														{" "}
														<FormattedMessage id="SUBSCRIPTION.START.DATE" />{" "}
													</h5>
													<input
														type="date"
														className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
															"start_date",
														)}`}
														name={"start_date"}
														{...addingUserFormik.getFieldProps(
															"start_date",
														)}
													/>
													{addingUserFormik.touched["start_date"] &&
													addingUserFormik.errors["start_date"] ? (
														<div className="fv-plugins-message-container">
															<div className="fv-help-block">
																{addingUserFormik.errors["start_date"]}
															</div>
														</div>
													) : null}
												</div>,
												<div className="form-group fv-plugins-icon-container">
													<h5>
														{" "}
														<FormattedMessage id="SUBSCRIPTION.END.DATE" />{" "}
													</h5>
													<input
														type="date"
														className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
															"end_date",
														)}`}
														name={"end_date"}
														{...addingUserFormik.getFieldProps("end_date")}
													/>
													{addingUserFormik.touched["end_date"] &&
													addingUserFormik.errors["end_date"] ? (
														<div className="fv-plugins-message-container">
															<div className="fv-help-block">
																{addingUserFormik.errors["end_date"]}
															</div>
														</div>
													) : null}
												</div>,
										  ]
										: null
								}
							/>
						</div>
					</Scrollbar>
				),
			},
		],
	};

	return (
		<>
			{isLoading.getAllUsers ? (
				<LinearProgress />
			) : errorMessage.getAllUsers ? (
				<div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
					<div
						style={{ textAlign: "center" }}
						className="alert-text font-weight-bold"
					>
						<FormattedMessage id="SOMETHING.WENT.WRONG.,.PLEASE.TRY.AGAIN" />
					</div>
				</div>
			) : (
				<TableWidget
					search={attributes.search}
					pagination={attributes.pagination}
					dialogButtons={attributes.dialogButtons}
					table={attributes.table}
					givenArray={filteredUsersArray}
					emptyMessage={attributes.emptyMessage}
				/>
			)}
		</>
	);
};

const mapStateToProps = createStructuredSelector({
	usersArray: selectUsersArray,
	isLoading: selectUsersIsLoading,
	errorMessage: selectUsersErrorMessages,
});

const mapDispatchToProps = dispatch => ({
	addUser: (requestBody, message) => dispatch(addUserAsync(requestBody, message)),
	getAllUsers: () => dispatch(getAllUsersAsync()),
	clearErrorMessages: () => dispatch(clearUsersErrorMessages()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersTableWidget);
