import React from "react";

const NotificationsTableRow = ({ notification }) => {
  return (
    <tr>
      <td className="pl-0 py-8">
        <div className="d-flex align-items-center">
          <div>
            <span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 ml-3 font-size-lg">
              {notification.title}
            </span>
          </div>
        </div>
      </td>
      <td className="pl-0 py-8">
        <div className="d-flex align-items-center">
          <div>
            <span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 ml-3 font-size-lg">
              {notification.description}
            </span>
          </div>
        </div>
      </td>
      <td className="pl-0 py-8">
        <div className="d-flex align-items-center">
          <div>
            <span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 ml-3 font-size-lg">
              {notification.android_delivery === "untracked"
                ? 0
                : notification.android_delivery}
            </span>
          </div>
        </div>
      </td>
      <td className="pl-0 py-8">
        <div className="d-flex align-items-center">
          <div>
            <span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 ml-3 font-size-lg">
              {notification.ios_delivery === "untracked"
                ? 0
                : notification.ios_delivery}
            </span>
          </div>
        </div>
      </td>
      <td className="pl-0 py-8">
        <div className="d-flex align-items-center">
          <div>
            <span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 ml-3 font-size-lg">
              {notification.successful}
            </span>
          </div>
        </div>
      </td>
      <td className="pl-0 py-8">
        <div className="d-flex align-items-center">
          <div>
            <span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 ml-3 font-size-lg">
              {notification.remaining}
            </span>
          </div>
        </div>
      </td>
      <td className="pl-0 py-8">
        <div className="d-flex align-items-center">
          <div>
            <span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 ml-3 font-size-lg">
              {notification.failed}
            </span>
          </div>
        </div>
      </td>
      <td className="pl-0 py-8">
        <div className="d-flex align-items-center">
          <div>
            <span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 ml-3 font-size-lg">
              {notification.errored}
            </span>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default NotificationsTableRow;
