import React, { useState } from "react";
import HomeRoundedIcon from "@material-ui/icons/HomeRounded";
import Dialog from "../../../../../custom/compoents/Dialog/Dialog.component";
import Select from "../../../../../custom/compoents/Select/Select.component";
import { CircularProgress, LinearProgress } from "@material-ui/core";
import CustomForm from "../../../../../custom/compoents/customform/CustomForm.component";
import { useFormik } from "formik";
import { useIntl, FormattedMessage } from "react-intl";
import * as Yup from "yup";
import { Typography, TextField } from "@material-ui/core";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  createSiteAsync,
  deleteSiteAsync,
  updateSiteAsync,
  clearSitesErrorMessages,
} from "../../../../../redux/sites/sites.actions";
import { getSensorsBySiteAsync } from "../../../../../redux/sensors/sensors.actions";
import { selectSelectedUser } from "../../../../../redux/users/users.selectors";
import {
  selectSitesIsLoading,
  selectSitesErrorMessages,
} from "../../../../../redux/sites/sites.selectors";
import {
  selectSensorsBySite,
  selectSensorsErrorMessages,
  selectSensorsIsLoading,
} from "../../../../../redux/sensors/sensors.selectors";

const SitesCard = ({
  sites,
  sensorsBySite,
  isLoading,
  errorMessages,
  sensorsIsLoading,
  sensorsErrorMessage,
  addSite,
  deleteSite,
  updateSite,
  selectedUser,
  clearErrorMessages,
  getSensorsBySite,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [selectedSite, setSelectedSite] = useState(
    sites.length != 0 ? sites[0] : { name: "", description: "", status: "" }
  );
  const intl = useIntl();
  //---------UPDATING SITE FORMIK-------------------
  const updatingSiteSchema = Yup.object().shape({
    name: Yup.string().required(
      intl.formatMessage({ id: "THIS.FIELD.IS.REQUIRED" })
    ),
    description: Yup.string().required(
      intl.formatMessage({ id: "THIS.FIELD.IS.REQUIRED" })
    ),
    status: Yup.string().required(
      intl.formatMessage({ id: "THIS.FIELD.IS.REQUIRED" })
    ),
  });

  const updatingSiteFormik = useFormik({
    initialValues: {
      description: selectedSite.description,
      name: selectedSite.name,
      status: selectedSite.status,
    },
    validationSchema: updatingSiteSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      let requestBody = {};
      requestBody["site_description"] = values["description"];
      requestBody["site_name"] = values["name"];
      requestBody["status"] = values["status"];
      requestBody["site_uuid"] = selectedSite.uuid;
      requestBody["user_uuid"] = selectedUser.uuid;
      updateSite(requestBody, selectedUser.uuid, {
        success: intl.formatMessage({ id: "SITE.WAS.SUCCESSFULLY.UPDATED" }),
        error: intl.formatMessage({
          id: "SOMETHING.WENT.WRONG.,.PLEASE.TRY.AGAIN",
        }),
      });
    },
  });
  //---------ADDING SITE FORMIK-------------------
  const addingSiteSchema = Yup.object().shape({
    name: Yup.string().required(
      intl.formatMessage({ id: "THIS.FIELD.IS.REQUIRED" })
    ),
    description: Yup.string().required(
      intl.formatMessage({ id: "THIS.FIELD.IS.REQUIRED" })
    ),
    icon: Yup.string().required(
      intl.formatMessage({ id: "THIS.FIELD.IS.REQUIRED" })
    ),
  });
  const addingSiteFormik = useFormik({
    initialValues: { name: "", description: "", icon: "" },
    validationSchema: addingSiteSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const requestBody = {};
      requestBody["site_description"] = values["description"];
      requestBody["site_name"] = values["name"];
      requestBody["status"] = "enabled";
      requestBody["user_uuid"] = selectedUser.uuid;
      requestBody["custom_icon"] = values["icon"];
      addSite(
        requestBody,
        selectedUser.uuid,
        intl.formatMessage({ id: "SITE.WAS.SUCCESSFULLY.ADDED" })
      );
    },
  });

  const attributes = {
    adding: {
      buttonTitle: intl.formatMessage({ id: "ADD.SITE" }),
      buttonClassName: "btn btn-primary font-weight-bolder font-size-sm",
      dialogTitle: intl.formatMessage({ id: "ADDING.SITE" }),
      closingAction: () => clearErrorMessages(),
      errorMessage: errorMessages.createSite,
      isLoading: isLoading.createSite,
      dialogContent: (
        <CustomForm
          formik={addingSiteFormik}
          fields={[
            { value: "name", placeHolder: intl.formatMessage({ id: "NAME" }) },
            {
              value: "description",
              placeHolder: intl.formatMessage({ id: "DESCRIPTION" }),
            },
          ]}
          buttonTitle={intl.formatMessage({ id: "ADD.SITE" })}
          isLoading={isLoading.createSite}
          errorMessage={errorMessages.createSite}
          additional={[
            <Select
              placeHolder={intl.formatMessage({ id: "ICON" })}
              style={{ width: "100%" }}
              otherProps={{ ...addingSiteFormik.getFieldProps("icon") }}
              array={[
                "farm",
                "garage",
                "clinic",
                "home",
                "store",
                "warehouse",
                "other-site",
              ]}
            />,
          ]}
        />
      ),
    },
    deleting: {
      buttonTitle: intl.formatMessage({ id: "DELETE" }),
      buttonClassName: "btn btn-danger font-weight-bolder font-size-sm mr-2",
      dialogTitle: "you're about to delete this site",
      closingAction: () => clearErrorMessages(),
      openingAction: () => {
        getSensorsBySite(selectedUser.uuid, selectedSite.uuid);
        clearErrorMessages();
      },
      action: () =>
        deleteSite(
          { user_uuid: selectedUser.uuid, site_uuid: selectedSite.uuid },
          selectedUser.uuid,
          intl.formatMessage({ id: "SITE.WAS.SUCCESSFULLY.DELETED" })
        ),
      errorMessage: errorMessages.deleteSite,
      isLoading: isLoading.deleteSite,
      dialogActions:
        !(
          sensorsErrorMessage.getSensorsBySite ||
          sensorsIsLoading.getSensorsBySite
        ) && sensorsBySite.length == 0
          ? [
              {
                close: false,
                disabled: isLoading.deleteSite,
                buttonTitle: isLoading.deleteSite ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  intl.formatMessage({ id: "DELETE" })
                ),
                buttonProps: {
                  variant: "contained",
                  color: "secondary",
                  disableRipple: true,
                  autoFocus: true,
                },
              },
              {
                close: true,
                disabled: isLoading.deleteSite,
                buttonTitle: intl.formatMessage({ id: "CANCEL" }),
                buttonProps: {
                  variant: "contained",
                  color: "primary",
                  disableRipple: true,
                  autoFocus: true,
                },
              },
            ]
          : null,
      dialogContent: sensorsIsLoading.getSensorsBySite ? (
        <LinearProgress />
      ) : sensorsErrorMessage.getSensorsBySite ? (
        <div
          style={{ width: "100%" }}
          className="mb-10 alert alert-custom alert-light-danger alert-dismissible"
        >
          <div
            style={{ textAlign: "center" }}
            className="alert-text font-weight-bold"
          >
            <FormattedMessage id="SOMETHING.WENT.WRONG.,.PLEASE.TRY.AGAIN" />
          </div>
        </div>
      ) : sensorsBySite.length > 0 ? (
        <div
          style={{ width: "100%" }}
          className="mb-10 alert alert-custom alert-light-danger alert-dismissible"
        >
          <div
            style={{ textAlign: "center" }}
            className="alert-text font-weight-bold"
          >
            <FormattedMessage id="A.SITE.THAT.HAS.SENSORS.CAN'T.BE.DELETED" />
          </div>
        </div>
      ) : (
        <Typography>
          {" "}
          <FormattedMessage id="ARE.YOU.SURE.THAT.YOU.WANT.TO.DELETE.THIS.SITE" />{" "}
        </Typography>
      ),
    },
  };
  return (
    <>
      <form onSubmit={updatingSiteFormik.handleSubmit}>
        <div className="card card-custom card-stretch gutter-b px-6 pb-3">
          <div className="row">
            <div
              style={{
                padding: "10px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
                marginBottom: "15px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <HomeRoundedIcon />
                <h4 style={{ marginLeft: "3px" }}>
                  {" "}
                  <FormattedMessage id="USER.SITES" />{" "}
                </h4>
              </div>
              <div>
                <Dialog
                  buttonTitle={attributes.adding.buttonTitle}
                  buttonClassName={attributes.adding.buttonClassName}
                  dialogTitle={attributes.adding.dialogTitle}
                  closingAction={attributes.adding.closingAction}
                  action={attributes.adding.action}
                  errorMessage={attributes.adding.errorMessage}
                  isLoading={attributes.adding.isLoading}
                >
                  {attributes.adding.dialogContent}
                </Dialog>
              </div>
            </div>

            {sites.length != 0 ? (
              <>
                <div
                  style={{
                    padding: "10px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                    marginBottom: "15px",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <Select
                      disabled={isEditing}
                      style={{ width: "80%" }}
                      array={sites.reduce((array, site) => {
                        array.push(site.name);
                        return array;
                      }, [])}
                      label={intl.formatMessage({ id: "SITES" })}
                      value={selectedSite.name}
                      handleChange={(e) => {
                        setSelectedSite(
                          sites.filter((site) => site.name == e.target.value)[0]
                        );
                      }}
                    />
                  </div>
                  {isEditing ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <button
                        className="btn btn-danger font-weight-bolder font-size-sm mr-2"
                        disabled={isLoading.updateSite}
                        onClick={() => {
                          setIsEditing(false);
                          updatingSiteFormik.resetForm();
                        }}
                      >
                        <FormattedMessage id="CANCEL" />
                      </button>{" "}
                      <button
                        className="btn btn-primary font-weight-bolder font-size-sm mr-0"
                        type="submit"
                        disabled={
                          !(
                            updatingSiteFormik.dirty &&
                            updatingSiteFormik.isValid
                          ) || isLoading.updateSite
                        }
                      >
                        {isLoading.updateSite ? (
                          <CircularProgress size={13} color="inherit" />
                        ) : (
                          <FormattedMessage id="SAVE" />
                        )}
                      </button>{" "}
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Dialog
                        buttonTitle={attributes.deleting.buttonTitle}
                        buttonClassName={attributes.deleting.buttonClassName}
                        dialogActions={attributes.deleting.dialogActions}
                        dialogTitle={attributes.deleting.dialogTitle}
                        closingAction={attributes.deleting.closingAction}
                        action={attributes.deleting.action}
                        errorMessage={attributes.deleting.errorMessage}
                        isLoading={attributes.deleting.isLoading}
                        openingAction={attributes.deleting.openingAction}
                      >
                        {attributes.deleting.dialogContent}
                      </Dialog>
                      <a
                        className="btn btn-success font-weight-bolder font-size-sm "
                        onClick={() => {
                          setIsEditing(true);
                        }}
                      >
                        <FormattedMessage id="EDIT" />
                      </a>
                    </div>
                  )}
                </div>
                <div className="col-md-4 col-s-12">
                  <div
                    style={{
                      width: "100%",
                      margin: "auto",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "start",
                    }}
                  >
                    <h6>
                      {" "}
                      <FormattedMessage id="NAME" />{" "}
                    </h6>{" "}
                    {
                      <TextField
                        fullWidth
                        style={
                          updatingSiteFormik.touched["name"] &&
                          updatingSiteFormik.errors["name"]
                            ? null
                            : { marginBottom: "20px" }
                        }
                        variant="outlined"
                        InputProps={{
                          readOnly: true,
                        }}
                        error={
                          updatingSiteFormik.touched["name"] &&
                          updatingSiteFormik.errors["name"]
                            ? true
                            : false
                        }
                        {...updatingSiteFormik.getFieldProps("name")}
                      />
                    }
                    {updatingSiteFormik.touched["name"] &&
                    updatingSiteFormik.errors["name"] ? (
                      <span
                        style={{ marginBottom: "20px" }}
                        className="text-danger"
                      >
                        {updatingSiteFormik.errors["name"]}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-4 col-s-12">
                  <div
                    style={{
                      width: "100%",
                      margin: "auto",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "start",
                    }}
                  >
                    <h6>
                      {" "}
                      <FormattedMessage id="DESCRIPTION" />{" "}
                    </h6>{" "}
                    {
                      <TextField
                        fullWidth
                        style={
                          updatingSiteFormik.touched["description"] &&
                          updatingSiteFormik.errors["description"]
                            ? null
                            : { marginBottom: "20px" }
                        }
                        variant="outlined"
                        InputProps={{
                          readOnly: !isEditing ? true : false,
                        }}
                        error={
                          updatingSiteFormik.touched["description"] &&
                          updatingSiteFormik.errors["description"]
                            ? true
                            : false
                        }
                        {...updatingSiteFormik.getFieldProps("description")}
                      />
                    }
                    {updatingSiteFormik.touched["description"] &&
                    updatingSiteFormik.errors["description"] ? (
                      <span
                        style={{ marginBottom: "20px" }}
                        className="text-danger"
                      >
                        {updatingSiteFormik.errors["description"]}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-4 col-s-12">
                  <div
                    style={{
                      width: "100%",
                      margin: "auto",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "start",
                    }}
                  >
                    <h6>
                      {" "}
                      <FormattedMessage id="STATUS" />{" "}
                    </h6>{" "}
                    {
                      <Select
                        array={["enabled", "disabled"]}
                        otherProps={{
                          ...updatingSiteFormik.getFieldProps("status"),
                          readOnly: !isEditing,
                        }}
                        style={
                          (updatingSiteFormik.touched["status"] &&
                          updatingSiteFormik.errors["status"]
                            ? null
                            : { marginBottom: "20px", width: "100%" },
                          { width: "100%" })
                        }
                        variant="outlined"
                        error={
                          updatingSiteFormik.touched["status"] &&
                          updatingSiteFormik.errors["status"]
                            ? true
                            : false
                        }
                      />
                    }
                    {updatingSiteFormik.touched["status"] &&
                    updatingSiteFormik.errors["status"] ? (
                      <span
                        style={{ marginBottom: "20px" }}
                        className="text-danger"
                      >
                        {updatingSiteFormik.errors["status"]}
                      </span>
                    ) : null}
                  </div>
                </div>{" "}
              </>
            ) : (
              <div
                style={{ width: "100%" }}
                className="mb-10 alert alert-custom alert-light-danger alert-dismissible"
              >
                <div
                  style={{ textAlign: "center" }}
                  className="alert-text font-weight-bold"
                >
                  <FormattedMessage id="THIS.USER.HAS.NO.SITES" />
                </div>
              </div>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  selectedUser: selectSelectedUser,
  sensorsBySite: selectSensorsBySite,
  isLoading: selectSitesIsLoading,
  errorMessages: selectSitesErrorMessages,
  sensorsIsLoading: selectSensorsIsLoading,
  sensorsErrorMessage: selectSensorsErrorMessages,
});

const mapDispatchToProps = (dispatch) => ({
  addSite: (requestBody, uuid, message) =>
    dispatch(createSiteAsync(requestBody, uuid, message)),
  getSensorsBySite: (user_uuid, site_uuid) =>
    dispatch(getSensorsBySiteAsync(user_uuid, site_uuid)),
  deleteSite: (requestBody, uuid, message) =>
    dispatch(deleteSiteAsync(requestBody, uuid, message)),
  updateSite: (requestBody, uuid, messages) =>
    dispatch(updateSiteAsync(requestBody, uuid, messages)),
  clearErrorMessages: () => dispatch(clearSitesErrorMessages()),
});
export default connect(mapStateToProps, mapDispatchToProps)(SitesCard);
