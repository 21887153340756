import companyTypes from "./company.types";

const INITIAL_STATE = {
  isLoading: {
    getCompanyInfo: false,
    updateCompanyInfo: false,
  },
  errorMessages: {
    getCompanyInfo: "",
    updateCompanyInfo: "",
  },
  arabicDescription: "",
  englishDescription: "",
};

const companyReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    //-------------START----------------
    case companyTypes.GET_COMPANY_INFO_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getCompanyInfo: true,
        },
      };
    case companyTypes.UPDATE_COMPANY_INFO_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          updateCompanyInfo: true,
        },
      };
    //-------------SUCCESS----------------
    case companyTypes.GET_COMPANY_INFO_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getCompanyInfo: false,
        },
        errorMessages: {
          ...state.errorMessages,
          getCompanyInfo: "",
        },
        arabicDescription: action.payload.desc_ar,
        englishDescription: action.payload.desc_eng,
      };
    case companyTypes.UPDATE_COMPANY_INFO_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          updateCompanyInfo: false,
        },
        errorMessages: {
          ...state.errorMessages,
          updateCompanyInfo: "",
        },
      };
    //-------------FAILURE----------------
    case companyTypes.GET_COMPANY_INFO_FAILURE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getCompanyInfo: false,
        },
        errorMessages: {
          ...state.errorMessages,
          getCompanyInfo: action.payload,
        },
      };
    case companyTypes.UPDATE_COMPANY_INFO_FAILURE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          updateCompanyInfo: false,
        },
        errorMessages: {
          ...state.errorMessages,
          updateCompanyInfo: action.paylaod,
        },
      };
    default:
      return state;
  }
};

export default companyReducer;
