import notificationsTypes from "./notifications.types";
import { req } from "../../axios/axios";
import { NotificationManager } from "react-notifications";
import {
  getUserInfoByEmailAsync,
  getUserInfoByUuidAsync,
  getAllUsersAsync,
} from "../users/users.actions";

//-------------GET NOTIFICATIONS HISTORY-----------
export const getNotificationsHistoryStart = () => ({
  type: notificationsTypes.GET_NOTIFICATIONS_HISTORY_START,
});
export const getNotificationsHistorySuccess = (notifications) => ({
  type: notificationsTypes.GET_NOTIFICATIONS_HISTORY_SUCCESS,
  payload: notifications,
});
export const getNotificationsHistoryFailure = (errorMessage) => ({
  type: notificationsTypes.GET_NOTIFICATIONS_HISTORY_FAILURE,
  payload: errorMessage,
});

export const getNotificationsHistoryAsync = () => {
  return async (dispatch) => {
    try {
      dispatch(getNotificationsHistoryStart());
      const result = await req.get(
        "/api_ad_notifications/notification_history"
      );
      dispatch(getNotificationsHistorySuccess(result.data));
    } catch (error) {
      dispatch(getNotificationsHistoryFailure(error.message));
    }
  };
};
//-------------SEND NOTIFICATION TO ALL USERS-----------
export const sendNotificationToAllUsersStart = () => ({
  type: notificationsTypes.SEND_NOTIFICATION_TO_ALL_USERS_START,
});
export const sendNotificationToAllUsersSuccess = () => ({
  type: notificationsTypes.SEND_NOTIFICATION_TO_ALL_USERS_SUCCESS,
});
export const sendNotificationToAllUsersFailure = (errorMessage) => ({
  type: notificationsTypes.SEND_NOTIFICATION_TO_ALL_USERS_FAILURE,
  payload: errorMessage,
});

export const sendNotificationToAllUsersAsync = (requestBody, message) => {
  return async (dispatch) => {
    try {
      dispatch(sendNotificationToAllUsersStart());
      await req.post("/api_ad_notifications/notification_all", requestBody);
      dispatch(getNotificationsHistoryAsync());
      NotificationManager.success(message);
      dispatch(sendNotificationToAllUsersSuccess());
    } catch (error) {
      dispatch(sendNotificationToAllUsersFailure(error.message));
    }
  };
};
//-------------SEND NOTIFICATION TO SINGLE USER-----------
export const sendNotificationToSingleUserStart = () => ({
  type: notificationsTypes.SEND_NOTIFICATION_TO_SINGLE_USER_START,
});
export const sendNotificationToSingleUserSuccess = () => ({
  type: notificationsTypes.SEND_NOTIFICATION_TO_SINGLE_USER_SUCCESS,
});
export const sendNotificationToSingleUserFailure = (errorMessage) => ({
  type: notificationsTypes.SEND_NOTIFICATION_TO_SINGLE_USER_FAILURE,
  payload: errorMessage,
});

export const sendNotificationToSingleUserAsync = (
  requestBody,
  email,
  uuid,
  message
) => {
  return async (dispatch) => {
    try {
      dispatch(sendNotificationToSingleUserStart());
      await req.post("/api_ad_notifications/notification_user", requestBody);
      if (email) {
        dispatch(getUserInfoByEmailAsync(email));
      } else if (uuid) {
        dispatch(getUserInfoByUuidAsync(uuid));
      } else {
        dispatch(getAllUsersAsync());
      }
      NotificationManager.success(message);
      dispatch(sendNotificationToSingleUserSuccess());
    } catch (error) {
      dispatch(sendNotificationToSingleUserFailure(error.message));
    }
  };
};
//-------------OTHERS-----------
export const clearNotificationsErrorMessages = () => ({
  type: notificationsTypes.CLEAR_ERROR_MESSAGES,
});
