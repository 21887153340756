import { createSelector } from "reselect";

export const selectCompany = (state) => state.company;

//----------1ST LEVEL SELECTORS------------

export const selectArabicDescription = createSelector(
  [selectCompany],
  (company) => company.arabicDescription
);

export const selectEnglishDescription = createSelector(
  [selectCompany],
  (company) => company.englishDescription
);

export const selectCompanyIsLoading = createSelector(
  [selectCompany],
  (company) => company.isLoading
);

export const selectCompanyErrorMessages = createSelector(
  [selectCompany],
  (company) => company.errorMessages
);
