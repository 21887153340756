import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import DnsRoundedIcon from "@material-ui/icons/DnsRounded";
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "../../../../custom/compoents/Select/Select.component";
import { useLang } from "../../../../_metronic/i18n";
import { TextField } from "@material-ui/core";
import { connect } from "react-redux";
import { useIntl, FormattedMessage } from "react-intl";
import { createStructuredSelector } from "reselect";
import {
  selectSensorsIsLoading,
  selectSensorsErrorMessages,
} from "../../../../redux/sensors/sensors.selectors";
import { updateSensorAsync } from "../../../../redux/sensors/sensors.actions";

const SensorDetailsCard = ({
  details,
  uuids,
  isLoading,
  errorMessage,
  custom_icon,
  updateSensorDetails,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const currentLanguage = useLang();
  const intl = useIntl();

  const updatingSensorValidationSchema = Yup.object().shape({
    name: Yup.string().required(
      intl.formatMessage({ id: "THIS.FIELD.IS.REQUIRED" })
    ),
    description: Yup.string().required(
      intl.formatMessage({ id: "THIS.FIELD.IS.REQUIRED" })
    ),
    status: Yup.string().required(
      intl.formatMessage({ id: "THIS.FIELD.IS.REQUIRED" })
    ),
  });
  const updatingSensorFormik = useFormik({
    validationSchema: updatingSensorValidationSchema,
    initialValues: {
      name: details.name,
      description: details.description,
      status: details.status,
      user_name: details.user_name,
      type: details.type,
      site_name: details.site_name,
      user_uuid: uuids.user_uuid,
      site_uuid: uuids.site_uuid,
      sensor_uuid: uuids.sensor_uuid,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      let requestBody = {
        sensor_description: values.description,
        sensor_name: values.name,
        sensor_type: values.type,
        site_name: values.site_name,
        sensor_uuid: values.sensor_uuid,
        status: values.status,
        custom_icon: custom_icon,
        user_uuid: values.user_uuid,
        site_uuid: values.site_uuid,
      };
      updateSensorDetails(requestBody, uuids, {
        success: intl.formatMessage({ id: "SENSOR.WAS.SUCCESSFULLY.UPDATED" }),
        error: intl.formatMessage({
          id: "SOMETHING.WENT.WRONG.,.PLEASE.TRY.AGAIN",
        }),
      });
    },
  });
  const lables = {
    user_name: intl.formatMessage({ id: "USER.FULL.NAME" }),
    status: intl.formatMessage({ id: "STATUS" }),
    name: intl.formatMessage({ id: "NAME" }),
    description: intl.formatMessage({ id: "DESCRIPTION" }),
    type: intl.formatMessage({ id: "TYPE" }),
    site_name: intl.formatMessage({ id: "SITE" }),
    user_uuid: intl.formatMessage({ id: "USER.UUID" }),
    site_uuid: intl.formatMessage({ id: "SITE.UUID" }),
    sensor_uuid: intl.formatMessage({ id: "SENSOR.UUID" }),
  };
  return (
    <>
      <div className="card card-custom card-stretch gutter-b pt-3">
        <div className="row">
          <form onSubmit={updatingSensorFormik.handleSubmit}>
            <div
              style={{
                padding: "10px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
                marginBottom: "15px",
              }}
            >
              <div
                style={
                  currentLanguage === "en"
                    ? {
                        display: "flex",
                        flexDirection: "row",
                        marginLeft: "20px",
                      }
                    : {
                        display: "flex",
                        flexDirection: "row",
                        marginRight: "20px",
                      }
                }
              >
                <DnsRoundedIcon />
                <h4
                  style={
                    currentLanguage === "en"
                      ? { marginLeft: "3px" }
                      : { marginRight: "3px" }
                  }
                >
                  <FormattedMessage id="SENSOR.DETAILS" />
                </h4>
              </div>
              <div>
                {isEditing ? (
                  <div
                    style={
                      currentLanguage == "en"
                        ? {
                            display: "flex",
                            flexDirection: "row",
                            marginRight: "20px",
                          }
                        : {
                            display: "flex",
                            flexDirection: "row",
                            marginLeft: "20px",
                          }
                    }
                  >
                    <button
                      className="btn btn-danger font-weight-bolder font-size-sm mr-2"
                      disabled={isLoading.updateSensor}
                      onClick={() => {
                        setIsEditing(false);
                        updatingSensorFormik.resetForm();
                      }}
                    >
                      <FormattedMessage id="CANCEL" />
                    </button>{" "}
                    <button
                      className="btn btn-primary font-weight-bolder font-size-sm mr-0"
                      type="submit"
                      disabled={
                        !(
                          updatingSensorFormik.dirty &&
                          updatingSensorFormik.isValid
                        ) || isLoading.updateSensor
                      }
                    >
                      {isLoading.updateSensor ? (
                        <CircularProgress size={13} color="inherit" />
                      ) : (
                        <FormattedMessage id="SAVE" />
                      )}
                    </button>{" "}
                  </div>
                ) : (
                  <a
                    className="btn btn-success font-weight-bolder font-size-sm mr-7"
                    onClick={() => {
                      setIsEditing(true);
                    }}
                  >
                    <FormattedMessage id="EDIT" />
                  </a>
                )}
              </div>
            </div>
            <div className="row mb-3 px-15">
              {Object.keys(details).map((key, index) => (
                <>
                  {
                    <div key={index} className="col-md-6 col-s-12">
                      <div
                        style={{
                          width: "100%",
                          margin: "auto",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                        }}
                      >
                        <h6> {lables[key]} </h6>{" "}
                        {key === "status" ? (
                          <Select
                            array={["enabled", "disabled"]}
                            style={
                              updatingSensorFormik.touched[key] &&
                              updatingSensorFormik.errors[key]
                                ? { width: "100%" }
                                : { marginBottom: "20px", width: "100%" }
                            }
                            key={index}
                            variant="outlined"
                            inputProps={{
                              readOnly: !isEditing ? true : false,
                            }}
                            error={
                              updatingSensorFormik.touched[key] &&
                              updatingSensorFormik.errors[key]
                                ? true
                                : false
                            }
                            otherProps={{
                              ...updatingSensorFormik.getFieldProps(key),
                            }}
                          />
                        ) : (
                          <TextField
                            key={index}
                            fullWidth
                            style={
                              updatingSensorFormik.touched[key] &&
                              updatingSensorFormik.errors[key]
                                ? null
                                : { marginBottom: "20px" }
                            }
                            variant="outlined"
                            InputProps={{
                              readOnly:
                                key === "user_name" ||
                                key === "type" ||
                                key === "site_name" ||
                                key === "status" ||
                                !isEditing
                                  ? true
                                  : false,
                            }}
                            error={
                              updatingSensorFormik.touched[key] &&
                              updatingSensorFormik.errors[key]
                                ? true
                                : false
                            }
                            {...updatingSensorFormik.getFieldProps(key)}
                          />
                        )}
                        {updatingSensorFormik.touched[key] &&
                        updatingSensorFormik.errors[key] ? (
                          <span
                            style={{ marginBottom: "20px" }}
                            className="text-danger"
                          >
                            {updatingSensorFormik.errors[key]}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  }
                </>
              ))}
              <div
                className="col-xxl-12"
                style={{
                  width: "100%",
                  margin: "auto",
                  marginBottom: "20px",
                }}
              >
                <h2
                  style={{
                    textAlign: "start",
                  }}
                >
                  {" "}
                  UUIDs{" "}
                </h2>
              </div>
              {Object.keys(uuids).map((key, index) => (
                <>
                  {index == 2 ? <div className="col-md-3"></div> : null}
                  {
                    <div key={index} className="col-md-6 col-s-12">
                      <div
                        style={{
                          width: "100%",
                          margin: "auto",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                        }}
                      >
                        <h6> {lables[key]} </h6>{" "}
                        {
                          <TextField
                            key={index}
                            fullWidth
                            style={
                              updatingSensorFormik.touched[key] &&
                              updatingSensorFormik.errors[key]
                                ? null
                                : { marginBottom: "20px" }
                            }
                            variant="outlined"
                            InputProps={{
                              readOnly:
                                key === "user_uuid" ||
                                key === "sensor_uuid" ||
                                key === "site_uuid" ||
                                !isEditing
                                  ? true
                                  : false,
                            }}
                            error={
                              updatingSensorFormik.touched[key] &&
                              updatingSensorFormik.errors[key]
                                ? true
                                : false
                            }
                            {...updatingSensorFormik.getFieldProps(key)}
                          />
                        }
                        {updatingSensorFormik.touched[key] &&
                        updatingSensorFormik.errors[key] ? (
                          <span
                            style={{ marginBottom: "20px" }}
                            className="text-danger"
                          >
                            {updatingSensorFormik.errors[key]}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  }
                  {index == 2 ? <div className="col-md-3"></div> : null}
                </>
              ))}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  isLoading: selectSensorsIsLoading,
  errorMessage: selectSensorsErrorMessages,
});

const mapDispatchToProps = (dispatch) => ({
  updateSensorDetails: (requestBody, uuids, messages) =>
    dispatch(updateSensorAsync(requestBody, false, uuids, messages)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SensorDetailsCard);
