import sitesTypes from "./sites.types";

const INITIAL_VALUES = {
  isLoading: {
    getSites: false,
    getSiteInfo: false,
    createSite: false,
    updateSite: false,
    deleteSite: false,
  },
  errorMessages: {
    getSites: "",
    getSiteInfo: "",
    createSite: "",
    updateSite: "",
    deleteSite: "",
  },
  sites: "",
};
const sitesReducer = (state = INITIAL_VALUES, action) => {
  switch (action.type) {
    //-----------START------------
    case sitesTypes.GET_SITES_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getSites: true,
        },
      };
    case sitesTypes.GET_SITE_INFO_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getSiteInfo: true,
        },
      };
    case sitesTypes.CREATE_SITE_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          createSite: true,
        },
      };
    case sitesTypes.UPDATE_SITE_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          updateSite: true,
        },
      };
    case sitesTypes.DELETE_SITE_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          deleteSite: true,
        },
      };
    //-----------SUCCESS------------
    case sitesTypes.GET_SITES_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getSites: false,
        },
        errorMessages: {
          ...state.errorMessages,
          getSites: "",
        },
        sites: action.payload,
      };
    case sitesTypes.GET_SITE_INFO_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getSiteInfo: false,
        },
        errorMessages: {
          ...state.errorMessages,
          getSiteInfo: "",
        },
      };
    case sitesTypes.CREATE_SITE_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          createSite: false,
        },
        errorMessages: {
          ...state.errorMessages,
          createSite: "",
        },
      };
    case sitesTypes.UPDATE_SITE_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          updateSite: false,
        },
        errorMessages: {
          ...state.errorMessages,
          updateSite: "",
        },
      };
    case sitesTypes.DELETE_SITE_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          deleteSite: false,
        },
        errorMessages: {
          ...state.errorMessages,
          deleteSite: "",
        },
      };
    //-----------FAILURE------------
    case sitesTypes.GET_SITES_FAILURE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getSites: false,
        },
        errorMessages: {
          ...state.errorMessages,
          getSites: action.payload,
        },
      };
    case sitesTypes.GET_SITE_INFO_FAILURE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getSiteInfo: false,
        },
        errorMessages: {
          ...state.errorMessages,
          getSiteInfo: action.payload,
        },
      };
    case sitesTypes.CREATE_SITE_FAILURE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          createSite: false,
        },
        errorMessages: {
          ...state.errorMessages,
          createSite: action.payload,
        },
      };
    case sitesTypes.UPDATE_SITE_FAILURE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          updateSite: false,
        },
        errorMessages: {
          ...state.errorMessages,
          updateSite: action.payload,
        },
      };
    case sitesTypes.DELETE_SITE_FAILURE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          deleteSite: false,
        },
        errorMessages: {
          ...state.errorMessages,
          deleteSite: action.payload,
        },
      };
    //-----------OTHERS------------
    case sitesTypes.CLEAR_SITES:
      return {
        ...state,
        sites: "",
      };
    case sitesTypes.CLEAR_SITES_ERROR_MESSAGES:
      return {
        ...state,
        errorMessages: {
          getSites: "",
          getSiteInfo: "",
          createSite: "",
          updateSite: "",
          deleteSite: "",
        },
      };
    //-----------DEFAULT------------
    default:
      return state;
  }
};
export default sitesReducer;
