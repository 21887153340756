import { createSelector } from "reselect";

export const selectSites = (state) => state.sites;

//-------------1ST-LEVEL-SELECTORS-----------------

export const selectSitesArray = createSelector(
  [selectSites],
  (sites) => sites.sites
);

export const selectSitesIsLoading = createSelector(
  [selectSites],
  (sites) => sites.isLoading
);

export const selectSitesErrorMessages = createSelector(
  [selectSites],
  (sites) => sites.errorMessages
);
