import companyTypes from "./company.types";
import { req } from "../../axios/axios";
import { NotificationManager } from "react-notifications";

//----------GET COMPANY INFO------------
export const getCompanyInfoStart = () => ({
  type: companyTypes.GET_COMPANY_INFO_START,
});
export const getCompanyInfoSuccess = (info) => ({
  type: companyTypes.GET_COMPANY_INFO_SUCCESS,
  payload: info,
});
export const getCompanyInfoFailure = (errorMessage) => ({
  type: companyTypes.UPDATE_COMPANY_INFO_FAILURE,
  payload: errorMessage,
});

export const getCompanyInfoAsync = () => {
  return async (dispatch) => {
    try {
      dispatch(getCompanyInfoStart());
      const result = await req.get("/api-user/company_info");
      dispatch(getCompanyInfoSuccess(result.data));
    } catch (error) {
      dispatch(getCompanyInfoFailure(error.message));
    }
  };
};
//----------UPDATE COMPANY INFO------------
export const updateCompanyInfoStart = () => ({
  type: companyTypes.UPDATE_COMPANY_INFO_START,
});
export const updateCompanyInfoSuccess = () => ({
  type: companyTypes.UPDATE_COMPANY_INFO_SUCCESS,
});
export const updateCompanyinfoFailure = (errorMessage) => ({
  type: companyTypes.UPDATE_COMPANY_INFO_FAILURE,
  payload: errorMessage,
});

export const updateCompanyInfoAsync = (requestBody) => {
  return async (dispatch) => {
    try {
      dispatch(updateCompanyInfoStart());
      const result = await req.put("/api-user/company_info", requestBody);
      dispatch(getCompanyInfoAsync());
      NotificationManager.success("descriptions were updated");
      dispatch(updateCompanyInfoSuccess());
    } catch (error) {
      dispatch(updateCompanyinfoFailure(error.message));
    }
  };
};
