import sitesTypes from "./sites.types";
import { req } from "../../axios/axios";
import { NotificationManager } from "react-notifications";

//-----------GET SITES------------
export const getSitesStart = () => ({
  type: sitesTypes.GET_SITES_START,
});
export const getSitesSuccess = (sites) => ({
  type: sitesTypes.GET_SITES_SUCCESS,
  payload: sites,
});
export const getSitesFailure = (errorMessage) => ({
  type: sitesTypes.GET_SITES_FAILURE,
  payload: errorMessage,
});

export const getSitesAsync = (uuid) => {
  return async (dispatch) => {
    try {
      dispatch(getSitesStart());
      const result = await req.get(`/api-sites/user_sites?user_uuid=${uuid}`, {
        params: {
          user_uuid: uuid,
        },
      });
      dispatch(getSitesSuccess(result.data));
    } catch (error) {
      dispatch(getSitesFailure(error.message));
    }
  };
};
//-----------GET SITE INFO------------
export const getSiteInfoStart = () => ({
  type: sitesTypes.GET_SITE_INFO_START,
});
export const getSiteInfoSuccess = (site) => ({
  type: sitesTypes.GET_SITE_INFO_SUCCESS,
  payload: site,
});
export const getSiteInfoFailure = (errorMessage) => ({
  type: sitesTypes.GET_SITE_INFO_FAILURE,
  payload: errorMessage,
});
//-----------CREATE SITE------------
export const createSiteStart = () => ({
  type: sitesTypes.CREATE_SITE_START,
});
export const createSiteSuccess = () => ({
  type: sitesTypes.CREATE_SITE_SUCCESS,
});
export const createSiteFailure = (errorMessage) => ({
  type: sitesTypes.CREATE_SITE_FAILURE,
  payload: errorMessage,
});

export const createSiteAsync = (requestBody, uuid, message) => {
  return async (dispatch) => {
    try {
      dispatch(createSiteStart());
      await req.post("/api-sites/site", requestBody);
      await dispatch(getSitesAsync(uuid));
      NotificationManager.success(message);
      dispatch(createSiteSuccess());
    } catch (error) {
      dispatch(createSiteFailure(error.message));
    }
  };
};
//-----------UPDATE SITE------------
export const updateSiteStart = () => ({
  type: sitesTypes.UPDATE_SITE_START,
});
export const updateSiteSuccess = () => ({
  type: sitesTypes.UPDATE_SITE_SUCCESS,
});
export const updateSiteFailure = (errorMessage) => ({
  type: sitesTypes.UPDATE_SITE_FAILURE,
  payload: errorMessage,
});

export const updateSiteAsync = (requestBody, uuid, messages) => {
  return async (dispatch) => {
    try {
      dispatch(updateSiteStart());
      await req.put("/api-sites/site", requestBody);
      await dispatch(getSitesAsync(uuid));
      NotificationManager.success(messages.success);
      dispatch(updateSiteSuccess());
    } catch (error) {
      NotificationManager.error(messages.error);
      dispatch(updateSiteFailure(error.message));
    }
  };
};
//-----------DELETE SITE------------
export const deleteSiteStart = () => ({
  type: sitesTypes.DELETE_SITE_START,
});
export const deleteSiteSuccess = () => ({
  type: sitesTypes.DELETE_SITE_SUCCESS,
});
export const deleteSiteFailure = (errorMessage) => ({
  type: sitesTypes.DELETE_SITE_FAILURE,
  payload: errorMessage,
});

export const deleteSiteAsync = (requestBody, uuid, message) => {
  return async (dispatch) => {
    try {
      dispatch(deleteSiteStart());
      await req.delete("/api-sites/site", {
        data: requestBody,
      });
      await dispatch(getSitesAsync(uuid));
      NotificationManager.success(message);
      dispatch(deleteSiteSuccess());
    } catch (error) {
      dispatch(deleteSiteFailure(error.message));
    }
  };
};
//-----------OTHERS------------
export const clearSites = () => ({
  type: sitesTypes.CLEAR_SITES,
});
export const clearSitesErrorMessages = () => ({
  type: sitesTypes.CLEAR_SITES_ERROR_MESSAGES,
});
