import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { getUserInfoByUuidAsync } from "../../../redux/users/users.actions";
import { FormattedMessage } from "react-intl";
import {
  getSitesAsync,
  clearSites,
  clearSitesErrorMessages,
} from "../../../redux/sites/sites.actions";
import {
  getSensorsByUserAsync,
  clearSensors,
  clearSensorsErrorMessages,
} from "../../../redux/sensors/sensors.actions";
import {
  selectSelectedUser,
  selectUsersErrorMessages,
  selectUsersIsLoading,
} from "../../../redux/users/users.selectors";
import {
  selectSitesErrorMessages,
  selectSitesIsLoading,
  selectSitesArray,
} from "../../../redux/sites/sites.selectors";
import {
  selectSensorsArray,
  selectSensorsErrorMessages,
  selectSensorsIsLoading,
} from "../../../redux/sensors/sensors.selectors";
import { LinearProgress } from "@material-ui/core";
import UserDetailsCard from "./components/userdetailscard/UserDetailsCard.component";
import SitesCard from "./components/sitescard/SitesCard.component";
import UserSensorsTable from "./components/usersensorstable/UserSensorsTable.component";

const UserDetailsPage = ({
  getUserInfoByUuid,
  selectedUser,
  usersErrorMessage,
  usersIsLoading,
  getSites,
  sites,
  sitesErrorMessage,
  sitesIsLoading,
  clearSites,
  clearSitesErrorMessages,
  getSensors,
  sensors,
  sensorsErrorMessage,
  sensorsIsLoading,
  clearSensors,
  clearSensorsErrorMessages,
}) => {
  const location = useLocation();
  useEffect(() => {
    clearSites();
    clearSensors();
    clearSitesErrorMessages();
    clearSensorsErrorMessages();
    if (location.state) {
      getUserInfoByUuid(location.state.uuid);
      getSites(location.state.uuid);
      getSensors(location.state.uuid);
    } else if (selectedUser) {
      getUserInfoByUuid(selectedUser.uuid);
      getSites(selectedUser.uuid);
      getSensors(selectedUser.uuid);
    }
  }, []);
  return (
    <>
      {/*  */}
      <div className="row">
        <div className="col-lg-12">
          <div style={{ padding: "10px" }}>
            {selectedUser ? (
              usersErrorMessage.getUserInfoByUuid ||
              sitesErrorMessage.getSites ||
              sensorsErrorMessage.getSensorsByUser ? (
                <div
                  style={{ textAlign: "center" }}
                  className="mb-10 alert alert-custom alert-light-danger alert-dismissible"
                >
                  <div className="alert-text font-weight-bold">
                    <FormattedMessage id="SOMETHING.WENT.WRONG.,.PLEASE.REFRESH" />
                  </div>
                </div>
              ) : usersIsLoading.getUserInfoByUuid ||
                sitesIsLoading.getSites ||
                sensorsIsLoading.getSensorsByUser ? (
                <LinearProgress />
              ) : (
                <>
                  <UserDetailsCard
                    userRole={selectedUser.role}
                    notificationTokens={
                      selectedUser.notification_tokens
                        ? selectedUser.notification_tokens
                        : null
                    }
                    visitedUser
                    user={Object.keys(selectedUser).reduce((object, key) => {
                      if (
                        key !== "role" &&
                        key !== "uuid" &&
                        key !== "subscription" &&
                        key !== "notification_tokens" &&
                        key !== "updatedAt"
                      ) {
                        object[key] = selectedUser[key];
                      } else if (
                        key === "subscription" &&
                        selectedUser.role !== "admin"
                      ) {
                        object["start_date"] = selectedUser[key].start_date;
                        object["end_date"] = selectedUser[key].end_date;
                        object["type"] = selectedUser[key].type;
                      } else if (key === "uuid") {
                        object["uuid"] = selectedUser[key];
                      }
                      return object;
                    }, {})}
                  />
                  <SitesCard
                    sites={sites}
                    isLoading={sitesIsLoading}
                    errorMessages={sitesErrorMessage}
                  />
                  <UserSensorsTable userSensors={sensors} />
                </>
              )
            ) : usersErrorMessage.getUserInfoByUuid ||
              sitesErrorMessage.getSites ||
              sensorsErrorMessage.getSensorsByUser ? (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div
                  style={{ textAlign: "center" }}
                  className="alert-text font-weight-bold"
                >
                  <FormattedMessage id="SOMETHING.WENT.WRONG.,.PLEASE.REFRESH" />
                </div>
              </div>
            ) : usersIsLoading.getUserInfoByUuid ||
              sitesIsLoading.getSites ||
              sensorsIsLoading.getSensorsByUser ? (
              <LinearProgress />
            ) : (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div
                  style={{ textAlign: "center" }}
                  className="alert-text font-weight-bold"
                >
                  <FormattedMessage id="NO.SELECTED.USER" />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  selectedUser: selectSelectedUser,
  sites: selectSitesArray,
  sensors: selectSensorsArray,
  usersErrorMessage: selectUsersErrorMessages,
  usersIsLoading: selectUsersIsLoading,
  sitesErrorMessage: selectSitesErrorMessages,
  sitesIsLoading: selectSitesIsLoading,
  sensorsErrorMessage: selectSensorsErrorMessages,
  sensorsIsLoading: selectSensorsIsLoading,
});

const mapDispatchToProps = (dispatch) => ({
  getUserInfoByUuid: (uuid) => dispatch(getUserInfoByUuidAsync(uuid)),
  getSites: (uuid) => dispatch(getSitesAsync(uuid)),
  getSensors: (uuid) => dispatch(getSensorsByUserAsync(uuid)),
  clearSites: () => dispatch(clearSites()),
  clearSitesErrorMessages: () => dispatch(clearSitesErrorMessages()),
  clearSensors: () => dispatch(clearSensors()),
  clearSensorsErrorMessages: () => dispatch(clearSensorsErrorMessages()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserDetailsPage);
