import { createSelector } from "reselect";

export const selectSensors = (state) => state.sensors;

//-------------1ST-LEVEL-SELECTORS-----------------

export const selectSensorsArray = createSelector(
  [selectSensors],
  (sensors) => sensors.sensors
);

export const selectAllSensorsArray = createSelector(
  [selectSensors],
  (sensors) => sensors.allSensors
);

export const selectSensorsIsLoading = createSelector(
  [selectSensors],
  (sensors) => sensors.isLoading
);

export const selectSensorsErrorMessages = createSelector(
  [selectSensors],
  (sensors) => sensors.errorMessages
);

export const selectSensorsBySite = createSelector(
  [selectSensors],
  (sensors) => sensors.sensorsBySite
);

export const selectSelectedSensor = createSelector(
  [selectSensors],
  (sensors) => sensors.selectedSensor
);

//-------------1ST-LEVEL-SELECTORS-----------------

export const selectSensorReadings = createSelector(
  [selectSelectedSensor],
  (selectedSensor) => selectedSensor.readings
);

export const selectSensorInfo = createSelector(
  [selectSelectedSensor],
  (selectedSensor) => selectedSensor.info
);
