import React from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import { create } from "jss";
import rtl from "jss-rtl";
import { StylesProvider, jssPreset } from "@material-ui/core/styles";
import { useLang } from "../../i18n";

export function MaterialThemeProvider(props) {
  let currentLanguage = useLang();
  const theme = createMuiTheme(
    /**
     * @see https://material-ui.com/customization/themes/#theme-configuration-variables
     */
    {
      direction: `${currentLanguage === "ar" ? "rtl" : ""}`,
      typography: {
        fontFamily: [`${currentLanguage === "ar" ? "Cairo" : "Roboto"}`].join(
          ","
        ),
      },

      palette: {
        primary: {
          // light: will be calculated from palette.primary.main,
          main: "#1473f9",
          // dark: will be calculated from palette.primary.main,
          // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
        },
        secondary: {
          // light: will be calculated from palette.primary.main,
          main: "#d52626",
          // dark: will be calculated from palette.primary.main,
          // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
        },
        error: {
          // light: will be calculated from palette.primary.main,
          main: "#d52626",
          // dark: will be calculated from palette.primary.main,
          // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
        },
      },

      /**
       * @see https://material-ui.com/customization/globals/#default-props
       */
      props: {
        // Name of the component ⚛️
        MuiButtonBase: {
          // The properties to apply
          disableRipple: false, // No more ripple, on the whole application 💣!
        },

        // Set default elevation to 1 for popovers.
        MuiPopover: {
          elevation: 1,
        },
      },
    }
  );

  const { children } = props;

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

export function MaterialStyleProvider(props) {
  let currentLanguage = useLang();
  return currentLanguage === "ar" ? (
    <StylesProvider jss={jss}>{props.children}</StylesProvider>
  ) : (
    <StylesProvider>{props.children}</StylesProvider>
  );
}
