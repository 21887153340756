import notificationTypes from "./notifications.types";

const INITIAL_STATE = {
  isLoading: {
    getNotificationsHistory: false,
    sendNotificationToAllUsers: false,
    sendNotificationToSingleUser: false,
  },
  errorMessages: {
    getNotificationsHistory: "",
    sendNotificationToAllUsers: "",
    sendNotificationToSingleUser: "",
  },
  notifications: "",
};

const notificationsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    //------------START-------------
    case notificationTypes.GET_NOTIFICATIONS_HISTORY_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, getNotificationsHistory: true },
      };
    case notificationTypes.SEND_NOTIFICATION_TO_ALL_USERS_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, sendNotificationToAllUsers: true },
      };
    case notificationTypes.SEND_NOTIFICATION_TO_SINGLE_USER_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, sendNotificationToSingleUser: true },
      };
    //------------SUCCESS-------------
    case notificationTypes.GET_NOTIFICATIONS_HISTORY_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, getNotificationsHistory: false },
        errorMessages: { ...state.errorMessages, getNotificationsHistory: "" },
        notifications: action.payload,
      };
    case notificationTypes.SEND_NOTIFICATION_TO_ALL_USERS_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          sendNotificationToAllUsers: false,
        },
        errorMessages: {
          ...state.errorMessages,
          sendNotificationToAllUsers: "",
        },
      };
    case notificationTypes.SEND_NOTIFICATION_TO_SINGLE_USER_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, sendNotificationToSingleUser: false },
        errorMessages: {
          ...state.errorMessages,
          sendNotificationToSingleUser: "",
        },
      };
    //------------FAILURE-------------
    case notificationTypes.GET_NOTIFICATIONS_HISTORY_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, getNotificationsHistory: false },
        errorMessages: {
          ...state.errorMessages,
          getNotificationsHistory: action.payload,
        },
      };
    case notificationTypes.SEND_NOTIFICATION_TO_ALL_USERS_FAILURE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          sendNotificationToAllUsers: false,
        },
        errorMessages: {
          ...state.errorMessages,
          sendNotificationToAllUsers: action.payload,
        },
      };
    case notificationTypes.SEND_NOTIFICATION_TO_SINGLE_USER_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, sendNotificationToSingleUser: false },
        errorMessages: {
          ...state.errorMessages,
          sendNotificationToSingleUser: action.payload,
        },
      };
    //------------OTHERS-------------
    case notificationTypes.CLEAR_ERROR_MESSAGES:
      return {
        ...state,
        errorMessages: {
          getNotificationsHistory: "",
          sendNotificationToAllUsers: "",
          sendNotificationToSingleUser: "",
        },
      };
    default:
      return state;
  }
};

export default notificationsReducer;
