import sensorsTypes from "./sensors.types";
const INITIAL_VALUES = {
  isLoading: {
    getAllSensors: false,
    getSensorsByUser: false,
    getSensorsBySite: false,
    createSensor: false,
    updateSensor: false,
    deleteSensor: false,
    getSensorReadings: false,
    getSensorInfo: false,
  },
  errorMessages: {
    getAllSensors: "",
    getSensorsByUser: "",
    getSensorsBySite: "",
    createSensor: "",
    updateSensor: "",
    deleteSensor: "",
    getSensorReadings: "",
    getSensorInfo: "",
  },
  selectedSensor: "",
  sensors: "",
  allSensors: "",
  sensorsBySite: "",
};
const sensorsReducer = (state = INITIAL_VALUES, action) => {
  switch (action.type) {
    //-----------START------------
    case sensorsTypes.GET_ALL_SENSORS_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, getAllSensors: true },
      };
    case sensorsTypes.GET_SENSORS_BY_USER_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getSensorsByUser: true,
        },
      };
    case sensorsTypes.GET_SENSORS_BY_SITE_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getSensorsBySite: true,
        },
      };
    case sensorsTypes.GET_SENSOR_READINGS_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getSensorReadings: true,
        },
      };
    case sensorsTypes.GET_SENSOR_INFO_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getSensorInfo: true,
        },
      };
    case sensorsTypes.CREATE_SENSOR_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          createSensor: true,
        },
      };
    case sensorsTypes.UPDATE_SENSOR_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          updateSensor: true,
        },
      };
    case sensorsTypes.DELETE_SENSOR_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          deleteSensor: true,
        },
      };
    //-----------SUCCESS------------
    case sensorsTypes.GET_ALL_SENSORS_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, getAllSensors: false },
        errorMessages: { ...state.errorMessages, getAllSensors: "" },
        allSensors: action.payload,
      };
    case sensorsTypes.GET_SENSORS_BY_USER_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getSensorsByUser: false,
        },
        errorMessages: {
          ...state.errorMessages,
          getSensorsByUser: "",
        },
        sensors: action.payload,
      };
    case sensorsTypes.GET_SENSORS_BY_SITE_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getSensorsBySite: false,
        },
        errorMessages: {
          ...state.errorMessages,
          getSensorsBySite: "",
        },
        sensorsBySite: action.payload,
      };
    case sensorsTypes.GET_SENSOR_READINGS_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getSensorReadings: false,
        },
        errorMessages: {
          ...state.errorMessages,
          getSensorReadings: "",
        },
        selectedSensor: {
          ...state.selectedSensor,
          readings: action.payload,
        },
      };
    case sensorsTypes.GET_SENSOR_INFO_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getSensorInfo: false,
        },
        errorMessages: {
          ...state.errorMessages,
          getSensorInfo: "",
        },
        selectedSensor: {
          ...state.selectedSensor,
          info: action.payload,
        },
      };
    case sensorsTypes.CREATE_SENSOR_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          createSensor: false,
        },
        errorMessages: {
          ...state.errorMessages,
          createSensor: "",
        },
      };
    case sensorsTypes.UPDATE_SENSOR_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          updateSensor: false,
        },
        errorMessages: {
          ...state.errorMessages,
          updateSensor: "",
        },
      };
    case sensorsTypes.DELETE_SENSOR_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          deleteSensor: false,
        },
        errorMessages: {
          ...state.errorMessages,
          deleteSensor: "",
        },
      };
    //-----------FAILURE------------
    case sensorsTypes.GET_ALL_SENSORS_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, getAllSensors: false },
        errorMessages: {
          ...state.errorMessages,
          getAllSensors: action.payload,
        },
      };
    case sensorsTypes.GET_SENSORS_BY_USER_FAILURE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getSensorsByUser: false,
        },
        errorMessages: {
          ...state.errorMessages,
          getSensorsByUser: action.payload,
        },
      };
    case sensorsTypes.GET_SENSORS_BY_SITE_FAILURE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getSensorsBySite: false,
        },
        errorMessages: {
          ...state.errorMessages,
          getSensorsBySite: action.payload,
        },
      };
    case sensorsTypes.CREATE_SENSOR_FAILURE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          createSensor: false,
        },
        errorMessages: {
          ...state.errorMessages,
          createSensor: action.payload,
        },
      };
    case sensorsTypes.GET_SENSOR_INFO_FAILURE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getSensorInfo: false,
        },
        errorMessages: {
          ...state.errorMessages,
          getSensorInfo: action.payload,
        },
      };
    case sensorsTypes.GET_SENSOR_READINGS_FAILURE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getSensorReadings: false,
        },
        errorMessages: {
          ...state.errorMessages,
          getSensorReadings: action.payload,
        },
      };
    case sensorsTypes.UPDATE_SENSOR_FAILURE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          updateSensor: false,
        },
        errorMessages: {
          ...state.errorMessages,
          updateSensor: action.payload,
        },
      };
    case sensorsTypes.DELETE_SENSOR_FAILURE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          deleteSensor: false,
        },
        errorMessages: {
          ...state.errorMessages,
          deleteSensor: action.payload,
        },
      };
    //-----------OTHERS------------
    case sensorsTypes.CLEAR_SENSORS:
      return {
        ...state,
        sensors: "",
      };
    case sensorsTypes.CLEAR_ALL_SENSORS:
      return {
        ...state,
        allSensors: "",
      };
    case sensorsTypes.CLEAR_SENSORS_ERROR_MESSAGES:
      return {
        ...state,
        errorMessages: {
          getAllSensors: "",
          getSensorsByUser: "",
          getSensorsBySite: "",
          createSensor: "",
          updateSensor: "",
          deleteSensor: "",
        },
      };
    //-----------DEFAULT------------
    default:
      return state;
  }
};
export default sensorsReducer;
