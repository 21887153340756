import { createSelector } from "reselect";

const selectNotifications = (state) => state.notifications;

//----------1ST LEVEL SELECTORS------------

export const selectNotificationsArray = createSelector(
  [selectNotifications],
  (notifications) => notifications.notifications
);

export const selectNotificationsIsLoading = createSelector(
  [selectNotifications],
  (notifications) => notifications.isLoading
);

export const selectNotificationsErrorMessages = createSelector(
  [selectNotifications],
  (notifications) => notifications.errorMessages
);
