const notificationsType = {
  GET_NOTIFICATIONS_HISTORY_START: "GET_NOTIFICATIONS_HISTORY_START",
  GET_NOTIFICATIONS_HISTORY_SUCCESS: "GET_NOTIFICATIONS_HISTORY_SUCCESS",
  GET_NOTIFICATIONS_HISTORY_FAILURE: "GET_NOTIFICATIONS_HISTORY_FAILURE",
  SEND_NOTIFICATION_TO_ALL_USERS_START: "SEND_NOTIFICATION_TO_ALL_USERS_START",
  SEND_NOTIFICATION_TO_ALL_USERS_SUCCESS:
    "SEND_NOTIFICATION_TO_ALL_USERS_SUCCESS",
  SEND_NOTIFICATION_TO_ALL_USERS_FAILURE:
    "SEND_NOTIFICATION_TO_ALL_USERS_FAILURE",
  SEND_NOTIFICATION_TO_SINGLE_USER_START:
    "SEND_NOTIFICATION_TO_SINGLE_USER_START",
  SEND_NOTIFICATION_TO_SINGLE_USER_SUCCESS:
    "SEND_NOTIFICATION_TO_SINGLE_USER_SUCCESS",
  SEND_NOTIFICATION_TO_SINGLE_USER_FAILURE:
    "SEND_NOTIFICATION_TO_SINGLE_USER_FAILURE",
  CLEAR_ERROR_MESSAGES: "CLEAR_ERROR_MESSAGES",
};

export default notificationsType;
