import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { FormattedMessage } from "react-intl";
import CloseIcon from "@material-ui/icons/Close";
import {
  Button,
  Dialog as MaterialDialog,
  IconButton,
  Typography,
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const Dialog = ({
  buttonTitle,
  buttonClassName,
  dialogTitle,
  dialogActions,
  closingAction,
  openingAction,
  action,
  children,
  errorMessage,
  isLoading,
}) => {
  const [open, setOpen] = useState(false);
  const [actionDone, setActionDone] = useState(false);

  useEffect(() => {
    if (actionDone && !errorMessage) {
      setOpen(false);
      setActionDone(false);
      closingAction ? closingAction() : nothing();
    }
  }, [actionDone]);

  const nothing = () => {};

  return (
    <div>
      <a
        href="#"
        className={buttonClassName}
        onClick={() => {
          setOpen(true);
          openingAction ? openingAction() : nothing();
        }}
      >
        {buttonTitle}
      </a>
      <MaterialDialog
        fullWidth
        maxWidth="xs"
        aria-labelledby="customized-dialog-title"
        open={open}
        onClose={() => {
          closingAction ? closingAction() : nothing();
          setOpen(false);
          isLoading ? nothing() : setActionDone(false);
        }}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => {
            setOpen(false);
            isLoading ? nothing() : setActionDone(false);
            closingAction ? closingAction() : nothing();
          }}
        >
          {dialogTitle}
        </DialogTitle>
        <DialogContent dividers>
          {errorMessage ? (
            <div className="mb-2 alert alert-custom alert-light-danger alert-dismissible">
              <div
                className="alert-text font-weight-bold"
                style={{ textAlign: "center" }}
              >
                <FormattedMessage id="SOMETHING.WENT.WRONG.,.PLEASE.TRY.AGAIN" />
              </div>
            </div>
          ) : null}
          {children}
        </DialogContent>
        {dialogActions ? (
          <DialogActions>
            {dialogActions.map((button, index) => (
              <Button
                key={index}
                onClick={
                  button.close
                    ? () => {
                        setOpen(false);
                        closingAction ? closingAction() : nothing();
                        isLoading ? nothing() : setActionDone(false);
                      }
                    : async () => {
                        await action();
                        setActionDone(true);
                      }
                }
                disabled={button.disabled}
                {...button.buttonProps}
              >
                {" "}
                {button.buttonTitle}
              </Button>
            ))}
          </DialogActions>
        ) : null}
      </MaterialDialog>
    </div>
  );
};

export default Dialog;
