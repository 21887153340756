import { createSelector } from "reselect";

export const selectUsers = (state) => state.users;

//-------------1ST-LEVEL-SELECTORS-----------------

export const selectUsersArray = createSelector(
  [selectUsers],
  (users) => users.users
);

export const selectUsersIsLoading = createSelector(
  [selectUsers],
  (users) => users.isLoading
);

export const selectUsersErrorMessages = createSelector(
  [selectUsers],
  (users) => users.errorMessages
);

export const selectSearchedUser = createSelector(
  [selectUsers],
  (users) => users.searchedUser
);

export const selectSelectedUser = createSelector(
  [selectUsers],
  (users) => users.selectedUser
);
