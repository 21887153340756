import React from "react";
import { FormattedMessage } from "react-intl";

const ImagesCarouselTips = () => (
  <div className="card card-custom card-stretch gutter-b">
    <div className="card-header border-0 pb-0 pt-3 px-7 ">
      <h1
        style={{
          fontWeight: "bold",
          fontSize: "32px",
          alignItems: "start",
          marginTop: "7px",
        }}
      >
        <FormattedMessage id="INSTRUCTIONS!" />
      </h1>
    </div>
    <div style={{ width: "95%", margin: "auto" }} className="card-body ">
      <div
        style={{ display: "flex", flexDirection: "column" }}
        className="tab-content"
      >
        <span className="mb-7" style={{ fontWeight: "450", lineHeight: "1.2" }}>
          <FormattedMessage id="1ST.INST" />
        </span>
        <span className="mb-4" style={{ fontWeight: "450", lineHeight: "1.2" }}>
          <FormattedMessage id="2ND.INST" />
        </span>
        <div
          style={{
            paddingLeft: "10px",
            paddingRight: "10px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span className="mb-7">
            <FormattedMessage id="1ST.TIP" />
          </span>
          <span className="mb-7">
            <FormattedMessage id="2ND.TIP" />
          </span>
          <span className="mb-7">
            <FormattedMessage id="3RD.TIP" />
          </span>
          <span className="mb-7">
            <FormattedMessage id="4th.TIP" />
          </span>
        </div>
      </div>
    </div>
  </div>
);

export default ImagesCarouselTips;
