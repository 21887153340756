const sensorsTypes = {
  GET_ALL_SENSORS_START: "GET_ALL_SENSORS_START",
  GET_ALL_SENSORS_SUCCESS: "GET_ALL_SENSORS_SUCCESS",
  GET_ALL_SENSORS_FAILURE: "GET_ALL_SENSORS_FAILURE",
  GET_SENSORS_BY_USER_START: "GET_SENSORS_BY_USER_START",
  GET_SENSORS_BY_USER_SUCCESS: "GET_SENSORS_BY_USER_SUCCESS",
  GET_SENSORS_BY_USER_FAILURE: "GET_SENSORS_BY_USER_FAILURE",
  GET_SENSORS_BY_SITE_START: "GET_SENSORS_BY_SITE_START",
  GET_SENSORS_BY_SITE_SUCCESS: "GET_SENSORS_BY_SITE_SUCCESS",
  GET_SENSORS_BY_SITE_FAILURE: "GET_SENSORS_BY_SITE_FAILURE",
  GET_SENSOR_INFO_START: "GET_SENSOR_INFO_START",
  GET_SENSOR_INFO_SUCCESS: "GET_SENSOR_INFO_SUCCESS",
  GET_SENSOR_INFO_FAILURE: "GET_SENSOR_INFO_FAILURE",
  GET_SENSOR_READINGS_START: "GET_SENSOR_READINGS_START",
  GET_SENSOR_READINGS_SUCCESS: "GET_SENSOR_READINGS_SUCCESS",
  GET_SENSOR_READINGS_FAILURE: "GET_SENSOR_READINGS_FAILURE",
  CREATE_SENSOR_START: "CREATE_SENSOR_START",
  CREATE_SENSOR_SUCCESS: "CREATE_SENSOR_SUCCESS",
  CREATE_SENSOR_FAILURE: "CREATE_SENSOR_FAILURE",
  UPDATE_SENSOR_START: "UPDATE_SENSOR_START",
  UPDATE_SENSOR_SUCCESS: "UPDATE_SENSOR_SUCCESS",
  UPDATE_SENSOR_FAILURE: "UPDATE_SENSOR_FAILURE",
  DELETE_SENSOR_START: "DELETE_SENSOR_START",
  DELETE_SENSOR_SUCCESS: "DELETE_SENSOR_SUCCESS",
  DELETE_SENSOR_FAILURE: "DELETE_SENSOR_FAILURE",
  CLEAR_SENSORS: "CLEAR_SENSORS",
  CLEAR_ALL_SENSORS: "CLEAR_ALL_SENSORS",
  CLEAR_SENSORS_ERROR_MESSAGES: "CLEAR_SENSORS_ERROR_MESSAGES",
};
export default sensorsTypes;
