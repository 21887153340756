import carouselTypes from "./carousel.types";

const INITIAL_STATE = {
  carouselImages: [],
  isLoading: {
    getImages: false,
    addImage: false,
    deleteImage: false,
  },
  errorMessages: {
    getImages: "",
    addImage: "",
    deleteImage: "",
  },
};

const carouselReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    //--------START-------------
    case carouselTypes.GET_IMAGES_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, getImages: true },
      };
    case carouselTypes.ADD_IMAGE_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, addImage: true },
      };
    case carouselTypes.DELETE_IMAGE_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, deleteImage: true },
      };
    //--------SUCCESS-------------
    case carouselTypes.GET_IMAGES_SUCCESS:
      return {
        ...state,
        carouselImages: action.payload,
        isLoading: { ...state.isLoading, getImages: false },
        errorMessages: { ...state.errorMessages, getImages: "" },
      };
    case carouselTypes.ADD_IMAGE_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, addImage: false },
        errorMessages: { ...state.errorMessages, addImage: "" },
      };
    case carouselTypes.DELETE_IMAGE_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, deleteImage: false },
        errorMessages: { ...state.errorMessages, deleteImage: "" },
      };
    //---------FAILURES---------------
    case carouselTypes.GET_IMAGES_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, getImages: false },
        errorMessages: { ...state.errorMessages, getImages: action.payload },
      };
    case carouselTypes.ADD_IMAGE_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, addImage: false },
        errorMessages: { ...state.errorMessages, addImage: action.payload },
      };
    case carouselTypes.DELETE_IMAGE_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, deleteImage: false },
        errorMessages: { ...state.errorMessages, deleteImage: action.payload },
      };
    //--------OTHERS-----------
    case carouselTypes.CLEAR_ERROR_MESSAGES:
      return {
        ...state,
        errorMessages: {
          getImages: "",
          addImage: "",
          deleteImage: "",
        },
      };
    case carouselTypes.CLEAR_IMAGES:
      return {
        ...state,
        carouselImages: [],
      };
    default:
      return state;
  }
};

export default carouselReducer;
