import React, { useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useLocation } from "react-router-dom";
import { LinearProgress } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import SensorDetailsCard from "../SensorDetailsCard/SensorDetailsCard.component";
import SensorReadingsTable from "../sensorreadingstable/SensorReadingsTable.component";
import {
  getSensorReadingsAsync,
  getSensorInfoAsync,
  clearSensorsErrorMessages,
} from "../../../../redux/sensors/sensors.actions";
import {
  selectSensorReadings,
  selectSensorInfo,
  selectSensorsErrorMessages,
  selectSensorsIsLoading,
  selectSelectedSensor,
} from "../../../../redux/sensors/sensors.selectors";

const SensorDetailsPage = ({
  selectedSensor,
  sensorReadings,
  sensorInfo,
  errorMessage,
  isLoading,
  getSensorReadings,
  getSensorInfo,
  clearErrorMessages,
}) => {
  const location = useLocation();

  useEffect(() => {
    clearErrorMessages();
    if (location.state) {
      getSensorInfo(
        location.state.user_uuid,
        location.state.site_uuid,
        location.state.sensor_uuid
      );
      getSensorReadings(location.state.sensor_uuid);
    } else if (selectedSensor) {
      getSensorInfo(
        selectedSensor.info.PK,
        selectedSensor.info.site_uuid,
        selectedSensor.info.uuid
      );
      getSensorReadings(selectedSensor.info.uuid);
    }
  }, []);
  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div style={{ padding: "10px" }}>
            {selectedSensor ? (
              errorMessage.getSensorInfo || errorMessage.getSensorReadings ? (
                <div
                  style={{ textAlign: "center" }}
                  className="mb-10 alert alert-custom alert-light-danger alert-dismissible"
                >
                  <div className="alert-text font-weight-bold">
                    <FormattedMessage id="SOMETHING.WENT.WRONG.,.PLEASE.REFRESH" />
                  </div>
                </div>
              ) : isLoading.getSensorInfo || isLoading.getSensorReadings ? (
                <LinearProgress />
              ) : (
                <>
                  <SensorDetailsCard
                    details={{
                      name: sensorInfo.name,
                      type: sensorInfo.type,
                      status: sensorInfo.status,
                      user_name: sensorInfo.user_name,
                      description: sensorInfo.description,
                      site_name: sensorInfo.site_name,
                    }}
                    uuids={{
                      user_uuid: sensorInfo.PK,
                      sensor_uuid: sensorInfo.uuid,
                      site_uuid: sensorInfo.site_uuid,
                    }}
                    custom_icon={sensorInfo.custom_icon}
                  />
                  <SensorReadingsTable
                    sensorReadings={sensorReadings}
                    type={sensorInfo.type}
                  />
                </>
              )
            ) : errorMessage.getSensorInfo || errorMessage.getSensorReadings ? (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div
                  style={{ textAlign: "center" }}
                  className="alert-text font-weight-bold"
                >
                  <FormattedMessage id="SOMETHING.WENT.WRONG.,.PLEASE.REFRESH" />
                </div>
              </div>
            ) : isLoading.getSensorInfo || isLoading.getSensorReadings ? (
              <LinearProgress />
            ) : (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div
                  style={{ textAlign: "center" }}
                  className="alert-text font-weight-bold"
                >
                  <FormattedMessage id="NO.SELECTED.SENSOR" />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  selectedSensor: selectSelectedSensor,
  sensorReadings: selectSensorReadings,
  sensorInfo: selectSensorInfo,
  errorMessage: selectSensorsErrorMessages,
  isLoading: selectSensorsIsLoading,
});

const mapDispatchToProps = (dispatch) => ({
  getSensorReadings: (sensor_uuid) =>
    dispatch(getSensorReadingsAsync(sensor_uuid)),
  getSensorInfo: (user_uuid, site_uuid, sensor_uuid) =>
    dispatch(getSensorInfoAsync(user_uuid, site_uuid, sensor_uuid)),
  clearErrorMessages: () => dispatch(clearSensorsErrorMessages()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SensorDetailsPage);
