import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { clearAllSensors } from "../../../redux/sensors/sensors.actions";
import { useIntl, FormattedMessage } from "react-intl";
import SensorsTable from "./SensorsTable/SensorsTable.component";
import {
  Dialog,
  DialogTitle,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";

const SensorsPage = ({ clearAllSensors }) => {
  const [isShowing, setIsShowing] = useState(false);
  const history = useHistory();
  const intl = useIntl();

  return (
    <>
      {isShowing ? (
        <>
          {" "}
          <h1
            style={{
              marginBottom: "20px",
              marginTop: "10px",
              fontWeight: "bold",
              fontSize: "40px",
            }}
          >
            <FormattedMessage id="SENSORS" />
          </h1>
          <div className="row">
            <div className="col-lg-12">
              <SensorsTable />
            </div>
          </div>
        </>
      ) : (
        <Dialog open={!isShowing} onClose={() => history.push("/")}>
          <DialogTitle id="alert-dialog-title">
            <FormattedMessage id="VISITING.CONFIRMATION" />
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <FormattedMessage id="ARE.YOU.SURE.THAT.YOU.WANT.TO.VISIT.THIS.PAGE.THAT.SHOWS.ALL.SENSORS" />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => {
                setIsShowing(true);
                clearAllSensors();
              }}
              color="primary"
              disableTouchRipple
            >
              <FormattedMessage id="YES" />
            </Button>
            <Button
              variant="contained"
              onClick={() => history.push("/")}
              color="secondary"
              disableTouchRipple
            >
              <FormattedMessage id="NO" />
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  clearAllSensors: () => dispatch(clearAllSensors()),
});

export default connect(null, mapDispatchToProps)(SensorsPage);
