import usersTypes from "./users.types";
import { req } from "../../axios/axios";
import { NotificationManager } from "react-notifications";

//------------ADDING USER----------
export const addUserStart = () => ({
  type: usersTypes.ADD_USER_START,
});
export const addUserSuccess = () => ({
  type: usersTypes.ADD_USER_SUCCESS,
});
export const addUserFailure = (errorMessage) => ({
  type: usersTypes.ADD_USER_FAILURE,
  payload: errorMessage,
});

export const addUserAsync = (requestBody, message) => {
  return async (dispatch) => {
    try {
      dispatch(addUserStart());
      await req.post("/api-auth/signup", requestBody);
      dispatch(getAllUsersAsync());
      NotificationManager.success(message);
      dispatch(addUserSuccess());
    } catch (error) {
      dispatch(addUserFailure(error.message));
    }
  };
};
//------------UPDATING USER------------
export const updateUserStart = () => ({
  type: usersTypes.UPDATE_USER_START,
});
export const updateUserSuccess = () => ({
  type: usersTypes.UPDATE_USER_SUCCESS,
});
export const updateUserFailure = (errorMessage) => ({
  type: usersTypes.UPDATE_USER_FAILURE,
  payload: errorMessage,
});

export const updateUserAsync = (requestBody, email, uuid, messages) => {
  return async (dispatch) => {
    try {
      dispatch(updateUserStart());
      await req.put("/api-user/user_info", requestBody);
      if (email) {
        await dispatch(getUserInfoByEmailAsync(email));
      } else if (uuid) {
        await dispatch(getUserInfoByUuidAsync(uuid));
      } else {
        await dispatch(getAllUsersAsync());
      }
      NotificationManager.success(messages.success);
      dispatch(updateUserSuccess());
    } catch (error) {
      NotificationManager.error(messages.error);
      dispatch(updateUserFailure(error.message));
    }
  };
};
//------------CHANGE STATUS-------------
export const changeStatusStart = () => ({
  type: usersTypes.CHANGE_STATUS_START,
});
export const changeStatusSuccess = () => ({
  type: usersTypes.CHANGE_STATUS_SUCCESS,
});
export const changeStatusFailure = (errorMessage) => ({
  type: usersTypes.CHANGE_STATUS_FAILURE,
  payload: errorMessage,
});

export const changeStatusAsync = (requestBody, email, uuid, message) => {
  return async (dispatch) => {
    try {
      dispatch(changeStatusStart());
      await req.post("/api-auth/change_status", requestBody);
      if (email) {
        await dispatch(getUserInfoByEmailAsync(email));
      } else if (uuid) {
        await dispatch(getUserInfoByUuidAsync(uuid));
      } else {
        await dispatch(getAllUsersAsync());
      }
      NotificationManager.success(message);
      dispatch(changeStatusSuccess());
    } catch (error) {
      dispatch(changeStatusFailure(error.message));
    }
  };
};
//-----------RESETTING PASSWORD----------
export const resetPasswordStart = () => ({
  type: usersTypes.RESET_PASSWORD_START,
});
export const resetPasswordSuccess = () => ({
  type: usersTypes.RESET_PASSWORD_SUCCESS,
});
export const resetPasswordFailure = (errorMessage) => ({
  type: usersTypes.RESET_PASSWORD_FAILURE,
  payload: errorMessage,
});

export const resetPasswordAsync = (username, message) => {
  return async (dispatch) => {
    try {
      dispatch(resetPasswordStart());
      await req.get(`/api-auth/reset_password?username=${username}`, {
        params: {
          username: username,
        },
      });
      NotificationManager.success(message);
      dispatch(resetPasswordSuccess());
    } catch (error) {
      dispatch(resetPasswordFailure(error.message));
    }
  };
};
//-----------GETTING USER INFO BY UUID----------
export const getUserInfoByUuidStart = () => ({
  type: usersTypes.GET_USER_INFO_BY_UUID_START,
});
export const getUserInfoByUuidSuccess = (user) => ({
  type: usersTypes.GET_USER_INFO_BY_UUID_SUCCESS,
  payload: user,
});
export const getUserInfoByUuidFailure = (errorMessage) => ({
  type: usersTypes.GET_USER_INFO_BY_UUID_FAILURE,
  payload: errorMessage,
});

export const getUserInfoByUuidAsync = (uuid) => {
  return async (dispatch) => {
    try {
      dispatch(getUserInfoByUuidStart());
      const result = await req.get(`/api-user/user_info?uuid=${uuid}`, {
        params: {
          uuid: uuid,
        },
      });
      result.data = {
        ...result.data,
        phone_number: result.data.phone_number.slice(4),
      };
      dispatch(getUserInfoByUuidSuccess(result.data));
    } catch (error) {
      dispatch(getUserInfoByUuidFailure(error.message));
    }
  };
};
//-----------GETTING USER INFO BY EMAIL----------
export const getUserInfoByEmailStart = () => ({
  type: usersTypes.GET_USER_INFO_BY_EMAIL_START,
});
export const getUserInfoByEmailSuccess = (user) => ({
  type: usersTypes.GET_USER_INFO_BY_EMAIL_SUCCESS,
  payload: user,
});
export const getUserInfoByEmailFailure = (errorMessage) => ({
  type: usersTypes.GET_USER_INFO_BY_EMAIL_FAILURE,
  payload: errorMessage,
});

export const getUserInfoByEmailAsync = (email) => {
  return async (dispatch) => {
    try {
      dispatch(getUserInfoByEmailStart());
      const result = await req.get(`/api-user/user_info_email?email=${email}`, {
        params: {
          email: email,
        },
      });
      result.data = {
        ...result.data,
        phone_number: result.data.phone_number.slice(4),
      };
      dispatch(getUserInfoByEmailSuccess(result.data));
    } catch (error) {
      dispatch(getUserInfoByEmailFailure(error.message));
    }
  };
};
//-----------GETTING ALL USERS----------
export const getAllUsersStart = () => ({
  type: usersTypes.GET_ALL_USERS_START,
});
export const getAllUsersSuccess = (users) => ({
  type: usersTypes.GET_ALL_USERS_SUCCESS,
  payload: users,
});
export const getAllUsersFailure = (errorMessage) => ({
  type: usersTypes.GET_ALL_USERS_FAILURE,
  paylaod: errorMessage,
});

export const getAllUsersAsync = () => {
  return async (dispatch) => {
    try {
      dispatch(getAllUsersStart());
      const result = await req.get("/api-user/users");
      result.data = result.data.map(
        (user) => (user = { ...user, phone_number: user.phone_number.slice(4) })
      );
      dispatch(getAllUsersSuccess(result.data));
    } catch (error) {
      dispatch(getAllUsersFailure(error.message));
    }
  };
};
//-----------CHANGE PASSSWORD----------
export const changePasswordStart = () => ({
  type: usersTypes.CHANGE_PASSWORD_START,
});
export const changePasswordSuccess = () => ({
  type: usersTypes.CHANGE_PASSWORD_SUCCESS,
});
export const changePasswordFailure = (errorMessage) => ({
  type: usersTypes.CHANGE_PASSWORD_FAILURE,
  payload: errorMessage,
});

export const changePasswordAsync = (requestBody, email, message) => {
  return async (dispatch) => {
    try {
      dispatch(changePasswordStart());
      await req.post("/api-auth/change_password", requestBody);
      if (email) {
        await dispatch(getUserInfoByEmailAsync(email));
      } else {
        await dispatch(getAllUsersAsync());
      }
      NotificationManager.success(message);
      dispatch(changePasswordSuccess());
    } catch (error) {
      dispatch(changePasswordFailure(error.message));
    }
  };
};
//-----------OTHERS----------
export const clearSearchedUser = () => ({
  type: usersTypes.CLEAR_SEARCHED_USER,
});
export const clearSelectedUser = () => ({
  type: usersTypes.CLEAR_SELECTED_USER,
});
export const clearUsersErrorMessages = () => ({
  type: usersTypes.CLEAR_USERS_ERROR_MESSAGES,
});
