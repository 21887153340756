/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";

export function QuickUserToggler() {
  const history = useHistory();

  const logoutClick = () => {
    const toggle = document.getElementById("kt_quick_user_toggle");
    if (toggle) {
      toggle.click();
    }
    history.push("/logout");
  };

  return (
    <>
      <div className="topbar-item">
        <div className=" d-flex align-items-center btn-lg px-2">
          <>
            <button
              className="btn btn-light-primary btn-bold"
              onClick={logoutClick}
            >
              <FormattedMessage id="SIGN.OUT" />
            </button>
          </>
        </div>
      </div>
    </>
  );
}
