/**
 * Entry application component used to compose providers and render Routes.
 * */

import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import Routes from "../app/Routes";
import { I18nProvider, useLang } from "../_metronic/i18n";
import {
  LayoutSplashScreen,
  MaterialThemeProvider,
  MaterialStyleProvider,
} from "../_metronic/layout";

// Standard version

export default function App({ store, persistor, basename }) {
  const currentLanguage = useLang();
  useEffect(() => {
    if (currentLanguage === "ar") {
      document.getElementById("kt_body").style.direction = "rtl";
      document.getElementById("kt_body").setAttribute("dir", "rtl");
      document.getElementById("kt_body").setAttribute("direction", "rtl");
      document.getElementById("kt_body").style.fontFamily = "Cairo,sans-serif";
    } else {
      document.getElementById("kt_body").style.fontFamily = "Cairo,sans-serif";
    }
  }, [currentLanguage]);

  return (
    /* Provide Redux store */
    <Provider store={store}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
        {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
        <React.Suspense fallback={<LayoutSplashScreen />}>
          {/* Override `basename` (e.g: `homepage` in `package.json`) */}
          <BrowserRouter basename={basename}>
            {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
            <MaterialThemeProvider>
              {/* Provide `react-intl` context synchronized with Redux state.  */}
              <MaterialStyleProvider>
                <I18nProvider>
                  {/* Render routes with provided `Layout`. */}
                  <Routes />
                </I18nProvider>
              </MaterialStyleProvider>
            </MaterialThemeProvider>
          </BrowserRouter>
        </React.Suspense>
      </PersistGate>
    </Provider>
  );
}
