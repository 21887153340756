const sitesTypes = {
  GET_SITES_START: "GET_SITES_START",
  GET_SITES_SUCCESS: "GET_SITES_SUCCESS",
  GET_SITES_FAILURE: "GET_SITES_FAILURE",
  GET_SITE_INFO_START: "GET_SITE_INFO_START",
  GET_SITE_INFO_SUCCESS: "GET_SITE_INFO_SUCCESS",
  GET_SITE_INFO_FAILURE: "GET_SITE_INFO_FAILURE",
  CREATE_SITE_START: "CREATE_SITE_START",
  CREATE_SITE_SUCCESS: "CREATE_SITE_SUCCESS",
  CREATE_SITE_FAILURE: "CREATE_SITE_FAILURE",
  UPDATE_SITE_START: "UPDATE_SITE_START",
  UPDATE_SITE_SUCCESS: "UPDATE_SITE_SUCCESS",
  UPDATE_SITE_FAILURE: "UPDATE_SITE_FAILURE",
  DELETE_SITE_START: "DELETE_SITE_START",
  DELETE_SITE_SUCCESS: "DELETE_SITE_SUCCESS",
  DELETE_SITE_FAILURE: "DELETE_SITE_FAILURE",
  CLEAR_SITES: "CLEAR_SITES",
  CLEAR_SITES_ERROR_MESSAGES: "CLEAR_SITES_ERROR_MESSAGES",
};
export default sitesTypes;
