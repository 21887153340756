const carouselTypes = {
  GET_IMAGES_START: "GET_IMAGES_START",
  GET_IMAGES_SUCCESS: "GET_IMAGES_SUCCESS",
  GET_IMAGES_FAILURE: "GET_IMAGES_FAILURE",
  ADD_IMAGE_START: "ADD_IMAGE_START",
  ADD_IMAGE_SUCCESS: "ADD_IMAGE_SUCCESS",
  ADD_IMAGE_FAILURE: "ADD_IMAGE_FAILURE",
  DELETE_IMAGE_START: "DELETE_IMAGE_START",
  DELETE_IMAGE_SUCCESS: "DELETE_IMAGE_SUCCESS",
  DELETE_IMAGE_FAILURE: "DELETE_IMAGE_FAILURE",
  CLEAR_ERROR_MESSAGES: "CLEAR_ERROR_MESSAGES",
  CLEAR_IMAGES: "CLEAR_IMAGES",
};

export default carouselTypes;
