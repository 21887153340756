import React from "react";
import Dialog from "../../../../custom/compoents/Dialog/Dialog.component";
import CustomForm from "../../../../custom/compoents/customform/CustomForm.component";
import { Scrollbar } from "react-scrollbars-custom";
import { useIntl, FormattedMessage } from "react-intl";
import Select from "../../../../custom/compoents/Select/Select.component";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { Typography, TextField, Button, CircularProgress } from "@material-ui/core";
import SVG from "react-inlinesvg";
import * as Yup from "yup";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { useFormik } from "formik";
import {
	selectUsersErrorMessages,
	selectUsersIsLoading,
} from "../../../../redux/users/users.selectors";
import {
	clearUsersErrorMessages,
	resetPasswordAsync,
	changeStatusAsync,
	updateUserAsync,
	changePasswordAsync,
} from "../../../../redux/users/users.actions";
import {
	clearNotificationsErrorMessages,
	sendNotificationToSingleUserAsync,
} from "../../../../redux/notifications/notifications.actions";
import {
	selectNotificationsErrorMessages,
	selectNotificationsIsLoading,
} from "../../../../redux/notifications/notifications.selectors";
import { selectCurrentUser } from "../../../../redux/auth/auth.selectors";

const UsersTableRow = ({
	user,
	isLoading,
	errorMessage,
	currentUser,
	notificationsErrorMessage,
	notificationsIsLoading,
	resetPassword,
	changeStatus,
	changePassword,
	updateUser,
	clearErrorMessages,
	clearNotificationsErrorMessages,
	sendNotification,
}) => {
	const history = useHistory();
	const intl = useIntl();

	const updatingUserDetailsSchema = Yup.object().shape({
		name: Yup.string().required(intl.formatMessage({ id: "THIS.FIELD.IS.REQUIRED" })),
		phone_number: Yup.number()
			.typeError(intl.formatMessage({ id: "ONLY.NUMBERS.ARE.VALID.IN.THIS.FIELD" }))
			.positive(intl.formatMessage({ id: "NUMBERS.SHOULD.BE.POSITIVE" }))
			.required(intl.formatMessage({ id: "THIS.FIELD.IS.REQUIRED" }))
			.test("len", intl.formatMessage({ id: "ONLY.9.DIGITS.ARE.VALID" }), value =>
				value ? value.toString().length == 9 : null,
			),
		email: Yup.string()
			.email(intl.formatMessage({ id: "WRONG.EMAIL.FORMAT" }))
			.required(intl.formatMessage({ id: "THIS.FIELD.IS.REQUIRED" })),
		address: Yup.string().required(
			intl.formatMessage({ id: "THIS.FIELD.IS.REQUIRED" }),
		),
	});

	const updatingUserDetailsFormik = useFormik({
		initialValues: Object.keys(user).reduce((object, key) => {
			if (
				key != "uuid" &&
				key != "role" &&
				key != "status" &&
				key != "username" &&
				key != "updatedAt" &&
				key != "subscription"
			) {
				object[key] = user[key];
			} else if (
				key === "subscription" &&
				user.role !== "admin" && user.role !== "staff"
			) {
				object["start_date"] = user["subscription"].start_date.split("/").join("-");
				object["end_date"] = user["subscription"].end_date.split("/").join("-");
				object["type"] = user["subscription"].type;
			}
			return object;
		}, {}),
		enableReinitialize: true,
		validationSchema: updatingUserDetailsSchema,
		onSubmit: values =>
			updateUser(
				user.role === "admin"
					? {
							user_uuid: user.uuid,
							name: values.name,
							address: values.address,
							email: values.email,
							phone_number: values.phone_number,
					  }
					: {
							user_uuid: user.uuid,
							name: values.name,
							address: values.address,
							email: values.email,
							phone_number: values.phone_number,
							subscription: {
								start_date: values.start_date.split("-").join("/"),
								end_date: values.end_date.split("-").join("/"),
								type: values.type,
							},
					  },
				{
					success: intl.formatMessage({
						id: "USER.DETAILS.WERE.SUCCESSFULLY.UPDATED",
					}),
					error: intl.formatMessage({
						id: "SOMETHING.WENT.WRONG.,.PLEASE.TRY.AGAIN",
					}),
				},
			),
	});
	//--------------CHANGING USER PASSWORD-------------
	const changingUserPasswordSchema = Yup.object().shape({
		old_password: Yup.string()
			.required(intl.formatMessage({ id: "THIS.FIELD.IS.REQUIRED" }))
			.matches(
				/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$/,
				intl.formatMessage({
					id:
						"THE.PASSWORD.MUST.CONTAIN.AT.LEAST.ONE.UPPERCASE.LETTER,.ONE.LOWERCASE.LETTER.AND.ONE.NUMBER",
				}),
			),
		new_password: Yup.string()
			.required(intl.formatMessage({ id: "THIS.FIELD.IS.REQUIRED" }))
			.matches(
				/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$/,
				intl.formatMessage({
					id:
						"THE.PASSWORD.MUST.CONTAIN.AT.LEAST.ONE.UPPERCASE.LETTER,.ONE.LOWERCASE.LETTER.AND.ONE.NUMBER",
				}),
			),
		confirm_new_password: Yup.string()
			.required(intl.formatMessage({ id: "THIS.FIELD.IS.REQUIRED" }))
			.matches(
				/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$/,
				intl.formatMessage({
					id:
						"THE.PASSWORD.MUST.CONTAIN.AT.LEAST.ONE.UPPERCASE.LETTER,.ONE.LOWERCASE.LETTER.AND.ONE.NUMBER",
				}),
			),
	});

	const changingUserPasswordFormik = useFormik({
		initialValues: {
			old_password: "",
			new_password: "",
			confirm_new_password: "",
		},
		enableReinitialize: true,
		validationSchema: changingUserPasswordSchema,
		onSubmit: values =>
			changePassword(
				{
					old_password: values.old_password,
					new_password: values.new_password,
					confirm_new_password: values.confirm_new_password,
				},
				intl.formatMessage({ id: "PASSWORD.WAS.SUCCESSFULLY.CHANGED" }),
			),
	});
	const changingUserPasswordFields = [
		{
			value: "old_password",
			placeHolder: intl.formatMessage({ id: "OLD.PASSWORD" }),
			type: "password",
		},
		{
			value: "new_password",
			placeHolder: intl.formatMessage({ id: "NEW.PASSWORD" }),
			type: "password",
		},
		{
			value: "confirm_new_password",
			placeHolder: intl.formatMessage({ id: "CONFIRM.NEW.PASSWORD" }),
			type: "password",
		},
	];
	//--------------SENDING NOTIFICATION-------------
	const sendingNotificationValidationSchema = Yup.object().shape({
		title: Yup.string().required(intl.formatMessage({ id: "THIS.FIELD.IS.REQUIRED" })),
		description: Yup.string().required(
			intl.formatMessage({ id: "THIS.FIELD.IS.REQUIRED" }),
		),
	});

	const sendingNotificationFormik = useFormik({
		initialValues: { title: "", description: "", url: "" },
		enableReinitialize: true,
		validationSchema: sendingNotificationValidationSchema,
		onSubmit: values => {
			sendNotification(
				{
					title: values.title,
					description: values.description,
					notification_tokens: user.notification_tokens,
				},
				intl.formatMessage({ id: "NOTIFICATION.WAS.SENT" }),
			);
		},
	});
	//------------------------------------------------
	const ability = {
		className: {
			disabled: "danger",
			enabled: "success",
		},
		dialogTitle: {
			disabled: intl.formatMessage({ id: "ACTIVATE" }),
			enabled: intl.formatMessage({ id: "DEACTIVATE" }),
		},
		reverse: {
			disabled: "enabled",
			enabled: "disabled",
		},
	};
	const getInputClasses = fieldname => {
		if (
			updatingUserDetailsFormik.touched[fieldname] &&
			updatingUserDetailsFormik.errors[fieldname]
		) {
			return "is-invalid";
		}
		if (
			updatingUserDetailsFormik.touched[fieldname] &&
			!updatingUserDetailsFormik.errors[fieldname]
		) {
			return "is-valid";
		}

		return "";
	};

	const updatingUserDetailsFormiFields = [
		{
			value: "name",
			placeHolder: intl.formatMessage({ id: "FULL.NAME" }),
			title: intl.formatMessage({ id: "FULL.NAME" }),
		},
		{
			value: "email",
			placeHolder: intl.formatMessage({ id: "EMAIL" }),
			title: intl.formatMessage({ id: "EMAIL" }),
		},
		{
			value: "address",
			placeHolder: intl.formatMessage({ id: "ADDRESS" }),
			title: intl.formatMessage({ id: "ADDRESS" }),
		},
		{
			value: "phone_number",
			placeHolder: intl.formatMessage({ id: "PHONE.NUMBER" }),
			title: intl.formatMessage({ id: "PHONE.NUMBER" }),
		},
	];

	const dialogAttributes = {
		ability: {
			buttonClassName: `btn btn-icon btn-light btn-sm  ${
				user.username === currentUser.username ? "disabled " : ""
			}`,
			buttonTitle: (
				<span className="svg-icon svg-icon-md svg-icon-primary ">
					<SVG
						title={intl.formatMessage({ id: "CHANGE.STATUS" })}
						src={toAbsoluteUrl("/media/svg/icons/General/Settings-1.svg")}
					/>
				</span>
			),
			dialogTitle:
				intl.formatMessage({ id: "YOU'RE.ABOUT.TO" }) +
				ability.dialogTitle[user.status] +
				intl.formatMessage({ id: "THIS.ACCOUNT" }),
			closingAction: () => clearErrorMessages(),
			action: () =>
				changeStatus(
					{
						username: user.username,
						user_uuid: user.uuid,
						status: ability.reverse[user.status],
					},
					intl.formatMessage({ id: "STATUS.WAS.SUCCESSFULLY.CHANGED" }),
				),
			isLoading: isLoading.changeStatus,
			errorMessage: errorMessage.changeStatus,
			dialogContent: (
				<Typography>
					<FormattedMessage id="ARE.YOU.SURE.THAT.U.WANT.TO" />
					{ability.dialogTitle[user.status]} <FormattedMessage id="THIS.ACCOUNT" />
				</Typography>
			),
			dialogActions: [
				{
					close: false,
					disabled: isLoading.changeStatus,
					buttonTitle: isLoading.changeStatus ? (
						<CircularProgress size={20} color="inherit" />
					) : (
						intl.formatMessage({ id: "YES" })
					),
					buttonProps: {
						variant: "contained",
						color: "secondary",
						disableRipple: true,
						autoFocus: true,
					},
				},
				{
					close: true,
					disabled: isLoading.changeStatus,
					buttonTitle: intl.formatMessage({ id: "NO" }),
					buttonProps: {
						variant: "contained",
						color: "primary",
						disableRipple: true,
						autoFocus: true,
					},
				},
			],
		},
		resettingPassword: {
			buttonClassName: "btn btn-icon btn-light btn-sm ml-2",
			buttonTitle: (
				<span className="svg-icon svg-icon-md svg-icon-primary">
					<SVG
						src={toAbsoluteUrl("/media/svg/icons/General/Lock.svg")}
						title={intl.formatMessage({ id: "RESET.PASSWORD" })}
					/>
				</span>
			),
			dialogTitle: intl.formatMessage({ id: "RESETTING.PASSWORD" }),
			closingAction: () => clearErrorMessages(),
			action: () =>
				resetPassword(
					user.username,
					intl.formatMessage({ id: "PASSWORD.WAS.SUCCESSFULLY.RESETTED" }),
				),
			isLoading: isLoading.resetPassword,
			errorMessage: errorMessage.resetPassword,
			dialogContent: (
				<Typography>
					<FormattedMessage id="ARE.YOU.SURE.THAT.U.WANT.TO.RESET.THIS.ACCOUNT'S.PASSWORD" />
				</Typography>
			),
			dialogActions: [
				{
					close: false,
					disabled: isLoading.resetPassword,
					buttonTitle: isLoading.resetPassword ? (
						<CircularProgress size={20} color="inherit" />
					) : (
						<FormattedMessage id="RESET.PASSWORD" />
					),
					buttonProps: {
						variant: "contained",
						color: "secondary",
						disableRipple: true,
						autoFocus: true,
					},
				},
				{
					close: true,
					disabled: isLoading.resetPassword,
					buttonTitle: intl.formatMessage({ id: "NO" }),
					buttonProps: {
						variant: "contained",
						color: "primary",
						disableRipple: true,
						autoFocus: true,
					},
				},
			],
		},
		updatingUser: {
			buttonClassName: "btn btn-icon btn-light btn-sm ml-2 ",
			buttonTitle: (
				<span className="svg-icon svg-icon-md svg-icon-primary">
					<SVG
						src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
						title={intl.formatMessage({ id: "UPDATING.USER.DETAILS" })}
					/>
				</span>
			),
			dialogTitle: intl.formatMessage({ id: "UPDATING.USER.DETAILS" }),
			closingAction: () => {
				clearErrorMessages();
				updatingUserDetailsFormik.resetForm();
			},
			isLoading: isLoading.updateUser,
			errorMessage: errorMessage.updateUser,
			dialogContent: (
				<Scrollbar style={{ height: 400, width: "100%" }}>
					<CustomForm
						fields={updatingUserDetailsFormiFields}
						errorMessage={errorMessage.updateUser}
						isLoading={isLoading.updateUser}
						formik={updatingUserDetailsFormik}
						buttonTitle={intl.formatMessage({ id: "SUBMIT.CHANGES" })}
						additional={
							user.role === "customer"
								? [
										<div className="form-group fv-plugins-icon-container">
											<h5>
												{" "}
												<FormattedMessage id="SUBSCRIPTION.START.DATE" />{" "}
											</h5>
											<input
												type="date"
												className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
													"start_date",
												)}`}
												name={"start_date"}
												{...updatingUserDetailsFormik.getFieldProps(
													"start_date",
												)}
											/>
											{updatingUserDetailsFormik.touched["start_date"] &&
											updatingUserDetailsFormik.errors["start_date"] ? (
												<div className="fv-plugins-message-container">
													<div className="fv-help-block">
														{updatingUserDetailsFormik.errors["start_date"]}
													</div>
												</div>
											) : null}
										</div>,
										<div className="form-group fv-plugins-icon-container">
											<h5>
												{" "}
												<FormattedMessage id="SUBSCRIPTION.END.DATE" />{" "}
											</h5>
											<input
												type="date"
												className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
													"end_date",
												)}`}
												name={"end_date"}
												{...updatingUserDetailsFormik.getFieldProps(
													"end_date",
												)}
											/>
											{updatingUserDetailsFormik.touched["end_date"] &&
											updatingUserDetailsFormik.errors["end_date"] ? (
												<div className="fv-plugins-message-container">
													<div className="fv-help-block">
														{updatingUserDetailsFormik.errors["end_date"]}
													</div>
												</div>
											) : null}
										</div>,
										<div className="form-group fv-plugins-icon-container">
											<h5>
												{" "}
												<FormattedMessage id="SUBSCRIPTION.TYPE" />{" "}
											</h5>
											<Select
												style={{ width: "100%" }}
												array={["prepaid"]}
												otherProps={updatingUserDetailsFormik.getFieldProps(
													"type",
												)}
												placeHolder="Subscription Type"
											/>
										</div>,
								  ]
								: []
						}
					/>
				</Scrollbar>
			),
		},
		changingPassword: {
			buttonClassName: "btn btn-icon btn-light btn-sm ml-2 ",
			buttonTitle: (
				<span className="svg-icon svg-icon-md svg-icon-primary">
					<SVG
						src={toAbsoluteUrl("/media/svg/icons/General/Lock-1.svg")}
						title={intl.formatMessage({ id: "CHANGE.PASSWORD" })}
					/>
				</span>
			),
			dialogTitle: intl.formatMessage({ id: "CHANGING.PASSWORD" }),
			closingAction: () => clearUsersErrorMessages(),
			dialogContent: (
				<CustomForm
					formik={changingUserPasswordFormik}
					isLoading={isLoading.changePassword}
					errorMessage={errorMessage.changePassword}
					fields={changingUserPasswordFields}
					buttonTitle={intl.formatMessage({ id: "CHANGE.PASSWORD" })}
				/>
			),
		},
		sendingNotification: {
			buttonClassName: "btn btn-icon btn-light btn-sm ml-2",
			buttonTitle: (
				<span className="svg-icon svg-icon-md svg-icon-primary">
					<SVG
						title={intl.formatMessage({ id: "SEND.NOTIFICATION" })}
						src={toAbsoluteUrl("/media/svg/icons/Communication/Send.svg")}
					/>{" "}
				</span>
			),
			dialogTitle: intl.formatMessage({ id: "SENDING.NOTIFICATION" }),
			closingAction: () => clearNotificationsErrorMessages(),
			dialogContent: (
				<Scrollbar style={{ height: 400, width: "100%" }}>
					<CustomForm
						fields={[]}
						errorMessage={notificationsErrorMessage.sendNotificationToSingleUser}
						isLoading={notificationsIsLoading.sendNotificationToSingleUser}
						formik={sendingNotificationFormik}
						buttonTitle={intl.formatMessage({ id: "SEND.NOTIFICATION" })}
						additional={[
							<div style={{ display: "flex", flexDirection: "column" }}>
								<h5>
									{" "}
									<FormattedMessage id="TITLE" />{" "}
								</h5>
								<TextField
									variant="outlined"
									fullWidth
									style={
										sendingNotificationFormik.touched["title"] &&
										sendingNotificationFormik.errors["title"]
											? null
											: { marginBottom: "20px" }
									}
									error={
										sendingNotificationFormik.touched["title"] &&
										sendingNotificationFormik.errors["title"]
											? true
											: false
									}
									{...sendingNotificationFormik.getFieldProps("title")}
								/>
								{sendingNotificationFormik.touched["title"] &&
								sendingNotificationFormik.errors["title"] ? (
									<span style={{ marginBottom: "20px" }} className="text-danger">
										{sendingNotificationFormik.errors["title"]}
									</span>
								) : null}
							</div>,
							<div style={{ display: "flex", flexDirection: "column" }}>
								<h5>
									{" "}
									<FormattedMessage id="DESCRIPTION" />{" "}
								</h5>
								<TextField
									variant="outlined"
									fullWidth
									multiline
									rows={4}
									style={
										sendingNotificationFormik.touched["description"] &&
										sendingNotificationFormik.errors["description"]
											? null
											: { marginBottom: "20px" }
									}
									error={
										sendingNotificationFormik.touched["description"] &&
										sendingNotificationFormik.errors["description"]
											? true
											: false
									}
									{...sendingNotificationFormik.getFieldProps("description")}
								/>
								{sendingNotificationFormik.touched["description"] &&
								sendingNotificationFormik.errors["description"] ? (
									<span style={{ marginBottom: "20px" }} className="text-danger">
										{sendingNotificationFormik.errors["description"]}
									</span>
								) : null}
							</div>,
						]}
					/>
				</Scrollbar>
			),
		},
	};

	return (
		<tr>
			<td className="pl-0 py-8">
				<div className="d-flex align-items-center">
					<div>
						{/* <SVG src={toAbsoluteUrl("/media/svg/icons/General/Lock.svg")}/> */}
						<span className="text-dark-75 text-hover-primary font-weight-bolder mb-1 font-size-lg">
							{user.username}
						</span>
					</div>
				</div>
			</td>
			<td className="pl-0 py-8">
				<div className="d-flex align-items-center">
					<div>
						<span className="text-dark-75 text-hover-primary font-weight-bolder mb-1 font-size-lg">
							{user.name}
						</span>
					</div>
				</div>
			</td>
			<td className="pl-0 py-8">
				<div className="d-flex align-items-center">
					<div>
						<span className="text-dark-75 text-hover-primary font-weight-bolder  mb-1 font-size-lg">
							{user.email}
						</span>
					</div>
				</div>
			</td>
			<td className="pl-0 py-8">
				<div className="d-flex align-items-center">
					<div>
						<span className="text-dark-75 text-hover-primary font-weight-bolder mb-1 font-size-lg">
							{user.address}
						</span>
					</div>
				</div>
			</td>
			<td className="pl-0 py-8">
				<div className="d-flex align-items-center">
					<div>
						<span className="text-dark-75 text-hover-primary font-weight-bolder mb-1 font-size-lg">
							{user.phone_number}
						</span>
					</div>
				</div>
			</td>
			<td className="text-left">
				<span
					className={`label label-lg label-light-${
						ability.className[user.status]
					} label-inline`}
				>
					{user.status}
				</span>
			</td>
			<td className="pl-0 py-8">
				<div className="d-flex align-items-center">
					<div>
						<span className="text-dark-75 text-hover-primary font-weight-bolder mb-1 font-size-lg">
							{user.role}
						</span>
					</div>
				</div>
			</td>
			<td className="pl-0 py-8">
				<div className="d-flex align-items-center">
					<div>
						<span className="text-dark-75 text-hover-primary font-weight-bolder mb-1 font-size-lg">
							{user.role === "customer" ? (
								user.subscription.start_date
							) : (
								<span
									style={{
										alignItems: "center",
									}}
								>
									-
								</span>
							)}
						</span>
					</div>
				</div>
			</td>
			<td className="pl-0 py-8">
				<div className="d-flex align-items-center">
					<div>
						<span className="text-dark-75 text-hover-primary font-weight-bolder mb-1 font-size-lg">
							{user.role === "customer" ? (
								user.subscription.end_date
							) : (
								<span
									style={{
										alignItems: "center",
									}}
								>
									-
								</span>
							)}
						</span>
					</div>
				</div>
			</td>
			<td className="pl-0 py-8">
				<div className="d-flex align-items-center">
					<div>
						<span className="text-dark-75 text-hover-primary font-weight-bolder mb-1 font-size-lg">
							{user.role === "customer" ? (
								user.subscription.type
							) : (
								<span
									style={{
										alignItems: "center",
									}}
								>
									-
								</span>
							)}
						</span>
					</div>
				</div>
			</td>
			<td className="text-left">
				<span
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
					}}
				>
					<Dialog
						buttonClassName={dialogAttributes.ability.buttonClassName}
						buttonTitle={dialogAttributes.ability.buttonTitle}
						dialogTitle={dialogAttributes.ability.dialogTitle}
						closingAction={dialogAttributes.ability.closingAction}
						action={dialogAttributes.ability.action}
						isLoading={dialogAttributes.ability.isLoading}
						errorMessage={dialogAttributes.ability.errorMessage}
						dialogActions={dialogAttributes.ability.dialogActions}
					>
						{dialogAttributes.ability.dialogContent}
					</Dialog>
					{currentUser.username === user.username ? (
						<Dialog
							buttonClassName={dialogAttributes.changingPassword.buttonClassName}
							buttonTitle={dialogAttributes.changingPassword.buttonTitle}
							dialogTitle={dialogAttributes.changingPassword.dialogTitle}
						>
							{dialogAttributes.changingPassword.dialogContent}
						</Dialog>
					) : (
						<Dialog
							buttonClassName={dialogAttributes.resettingPassword.buttonClassName}
							buttonTitle={dialogAttributes.resettingPassword.buttonTitle}
							dialogTitle={dialogAttributes.resettingPassword.dialogTitle}
							closingAction={dialogAttributes.resettingPassword.closingAction}
							action={dialogAttributes.resettingPassword.action}
							isLoading={dialogAttributes.resettingPassword.isLoading}
							errorMessage={dialogAttributes.resettingPassword.errorMessage}
							dialogActions={dialogAttributes.resettingPassword.dialogActions}
						>
							{dialogAttributes.resettingPassword.dialogContent}
						</Dialog>
					)}
					<Dialog
						buttonClassName={dialogAttributes.updatingUser.buttonClassName}
						buttonTitle={dialogAttributes.updatingUser.buttonTitle}
						dialogTitle={dialogAttributes.updatingUser.dialogTitle}
						closingAction={dialogAttributes.updatingUser.closingAction}
						isLoading={dialogAttributes.updatingUser.isLoading}
						errorMessage={dialogAttributes.updatingUser.errorMessage}
					>
						{dialogAttributes.updatingUser.dialogContent}
					</Dialog>

					{user.role === "customer" ? (
						<>
							<Dialog
								buttonClassName={
									dialogAttributes.sendingNotification.buttonClassName
								}
								buttonTitle={dialogAttributes.sendingNotification.buttonTitle}
								dialogTitle={dialogAttributes.sendingNotification.dialogTitle}
								closingAction={dialogAttributes.sendingNotification.closingAction}
							>
								{dialogAttributes.sendingNotification.dialogContent}
							</Dialog>
							<a
								onClick={() => {
									history.push({
										pathname: "/userdetails",
										state: {
											uuid: user.uuid,
										},
									});
								}}
								className="btn btn-icon btn-light btn-sm ml-2"
							>
								<span className="svg-icon svg-icon-md svg-icon-primary">
									<SVG
										src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}
										title={intl.formatMessage({ id: "VISIT.USER" })}
									/>
								</span>
							</a>
						</>
					) : null}
				</span>
			</td>
		</tr>
	);
};

const mapStateToProps = createStructuredSelector({
	isLoading: selectUsersIsLoading,
	errorMessage: selectUsersErrorMessages,
	notificationsErrorMessage: selectNotificationsErrorMessages,
	notificationsIsLoading: selectNotificationsIsLoading,
	currentUser: selectCurrentUser,
});

const mapDispatchToProps = dispatch => ({
	resetPassword: (username, message) => dispatch(resetPasswordAsync(username, message)),
	changeStatus: (requestBody, message) =>
		dispatch(changeStatusAsync(requestBody, false, false, message)),
	updateUser: (requestBody, messages) =>
		dispatch(updateUserAsync(requestBody, false, false, messages)),
	clearErrorMessages: () => dispatch(clearUsersErrorMessages()),
	changePassword: (requestBody, message) =>
		dispatch(changePasswordAsync(requestBody, false, message)),
	clearNotificationsErrorMessages: () => dispatch(clearNotificationsErrorMessages()),
	sendNotification: (requestBody, message) =>
		dispatch(sendNotificationToSingleUserAsync(requestBody, false, false, message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersTableRow);
