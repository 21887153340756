import React, { useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Scrollbar } from "react-scrollbars-custom";
import { TextField } from "@material-ui/core";
import NotificationsActiveRoundedIcon from "@material-ui/icons/NotificationsActiveRounded";
import NotificationsTableRow from "./NotificationsTableRow.component";
import CustomForm from "../../../../custom/compoents/customform/CustomForm.component";
import TableWidget from "../../../../custom/widgets/TableWidget/TableWidget.component";
import { FormattedMessage, useIntl } from "react-intl";
import {
  selectNotificationsArray,
  selectNotificationsIsLoading,
  selectNotificationsErrorMessages,
} from "../../../../redux/notifications/notifications.selectors";
import {
  sendNotificationToAllUsersAsync,
  clearNotificationsErrorMessages,
} from "../../../../redux/notifications/notifications.actions";

const NotificationsTable = ({
  notifications,
  isLoading,
  errorMessage,
  sendNotification,
  clearErrorMessages,
}) => {
  const [page, setPage] = useState(1);
  const intl = useIntl();

  //--------SENDING NOTIFICATION-----------
  const sendingNotificationValidationSchema = Yup.object().shape({
    title: Yup.string().required(
      intl.formatMessage({ id: "THIS.FIELD.IS.REQUIRED" })
    ),
    description: Yup.string().required(
      intl.formatMessage({ id: "THIS.FIELD.IS.REQUIRED" })
    ),
    url: Yup.string().url(intl.formatMessage({ id: "WRONG.URL.FORMAT" })),
  });

  const sendingNotificationFormik = useFormik({
    initialValues: { title: "", description: "", url: "" },
    enableReinitialize: true,
    validationSchema: sendingNotificationValidationSchema,
    onSubmit: (values) => {
      let requestBody = {
        title: values.title,
        description: values.description,
      };
      if (values.url) requestBody = { ...requestBody, url: values.url };
      sendNotification(
        requestBody,
        intl.formatMessage({ id: "NOTIFICATION.WAS.SENT" })
      );
    },
  });
  //---------------------------------------

  const attributes = {
    emptyMessage: intl.formatMessage({ id: "NO.NOTIFICATIONS.WERE.SENT.YET" }),
    title: {
      title: intl.formatMessage({ id: "NOTIFICATIONS" }),
      icon: <NotificationsActiveRoundedIcon />,
    },
    table: {
      columns: [
        {
          label: intl.formatMessage({ id: "TITLE" }),
          minWidth: "10vw",
        },
        {
          label: intl.formatMessage({ id: "DESCRIPTION" }),
          minWidth: "12vw",
        },
        {
          label: intl.formatMessage({ id: "ANDROID.DEVICES" }),
        },
        {
          label: intl.formatMessage({ id: "IOS.DEVICES" }),
        },
        {
          label: intl.formatMessage({ id: "SUCCESSFUL" }),
        },
        {
          label: intl.formatMessage({ id: "REMAINING" }),
        },
        {
          label: intl.formatMessage({ id: "FAILED" }),
        },
        {
          label: intl.formatMessage({ id: "ERRORED" }),
        },
      ],
      tableRow: (value, index) => (
        <NotificationsTableRow key={index} notification={value} />
      ),
    },
    pagination: {
      handleChange: (event, value) => {
        setPage(value);
      },
      rowsPerPage: 6,
      currentPage: page,
    },
    dialogButtons: [
      {
        buttonTitle: intl.formatMessage({ id: "SEND.NOTIFICATION" }),
        buttonClassName: "btn btn-primary font-weight-bolder font-size-sm mr-3",
        dialogTitle: intl.formatMessage({ id: "SENDING.NOTIFICATION" }),
        closingAction: () => {
          clearErrorMessages();
          sendingNotificationFormik.resetForm();
        },
        dialogContent: (
          <Scrollbar style={{ height: 400, width: "100%" }}>
            <CustomForm
              fields={[]}
              errorMessage={errorMessage.sendNotificationToAllUsers}
              isLoading={isLoading.sendNotificationToAllUsers}
              formik={sendingNotificationFormik}
              buttonTitle={intl.formatMessage({ id: "SEND.NOTIFICATION" })}
              additional={[
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <h5>
                    <FormattedMessage id="TITLE" />
                  </h5>
                  <TextField
                    variant="outlined"
                    fullWidth
                    style={
                      sendingNotificationFormik.touched["title"] &&
                      sendingNotificationFormik.errors["title"]
                        ? null
                        : { marginBottom: "20px" }
                    }
                    error={
                      sendingNotificationFormik.touched["title"] &&
                      sendingNotificationFormik.errors["title"]
                        ? true
                        : false
                    }
                    {...sendingNotificationFormik.getFieldProps("title")}
                  />
                  {sendingNotificationFormik.touched["title"] &&
                  sendingNotificationFormik.errors["title"] ? (
                    <span
                      style={{ marginBottom: "20px" }}
                      className="text-danger"
                    >
                      {sendingNotificationFormik.errors["title"]}
                    </span>
                  ) : null}
                </div>,
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <h5>
                    <FormattedMessage id="DESCRIPTION" />
                  </h5>
                  <TextField
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                    style={
                      sendingNotificationFormik.touched["description"] &&
                      sendingNotificationFormik.errors["description"]
                        ? null
                        : { marginBottom: "20px" }
                    }
                    error={
                      sendingNotificationFormik.touched["description"] &&
                      sendingNotificationFormik.errors["description"]
                        ? true
                        : false
                    }
                    {...sendingNotificationFormik.getFieldProps("description")}
                  />
                  {sendingNotificationFormik.touched["description"] &&
                  sendingNotificationFormik.errors["description"] ? (
                    <span
                      style={{ marginBottom: "20px" }}
                      className="text-danger"
                    >
                      {sendingNotificationFormik.errors["description"]}
                    </span>
                  ) : null}
                </div>,
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <h5>
                    <FormattedMessage id="ATTACHED.URL" />
                  </h5>
                  <TextField
                    variant="outlined"
                    fullWidth
                    style={
                      sendingNotificationFormik.touched["url"] &&
                      sendingNotificationFormik.errors["url"]
                        ? null
                        : { marginBottom: "20px" }
                    }
                    error={
                      sendingNotificationFormik.touched["url"] &&
                      sendingNotificationFormik.errors["url"]
                        ? true
                        : false
                    }
                    {...sendingNotificationFormik.getFieldProps("url")}
                  />
                  {sendingNotificationFormik.touched["url"] &&
                  sendingNotificationFormik.errors["url"] ? (
                    <span
                      style={{ marginBottom: "20px" }}
                      className="text-danger"
                    >
                      {sendingNotificationFormik.errors["url"]}
                    </span>
                  ) : null}
                </div>,
              ]}
            />
          </Scrollbar>
        ),
      },
    ],
  };

  return (
    <>
      <TableWidget
        table={attributes.table}
        pagination={attributes.pagination}
        dialogButtons={attributes.dialogButtons}
        title={attributes.title}
        emptyMessage={attributes.emptyMessage}
        givenArray={notifications}
      />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  notifications: selectNotificationsArray,
  isLoading: selectNotificationsIsLoading,
  errorMessage: selectNotificationsErrorMessages,
});

const mapDispatchToProps = (dispatch) => ({
  sendNotification: (requestBody, message) =>
    dispatch(sendNotificationToAllUsersAsync(requestBody, message)),
  clearErrorMessages: () => dispatch(clearNotificationsErrorMessages()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsTable);
