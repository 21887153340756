import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import UserSensorsTableRow from "./UserSensorsTableRow.component";
import TableWidget from "../../../../../custom/widgets/TableWidget/TableWidget.component";
import Select from "../../../../../custom/compoents/Select/Select.component";
import CustomForm from "../../../../../custom/compoents/customform/CustomForm.component";
import DnsRoundedIcon from "@material-ui/icons/DnsRounded";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useIntl, FormattedMessage } from "react-intl";
import {
  createSensorAsync,
  clearSensorsErrorMessages,
} from "../../../../../redux/sensors/sensors.actions";
import {
  selectSensorsErrorMessages,
  selectSensorsIsLoading,
} from "../../../../../redux/sensors/sensors.selectors";
import { selectSitesArray } from "../../../../../redux/sites/sites.selectors";
import { selectSelectedUser } from "../../../../../redux/users/users.selectors";

const UserSensorsTable = ({
  userSensors,
  sites,
  selectedUser,
  errorMessage,
  isLoading,
  addSensor,
  clearErrorMessages,
}) => {
  const [searchField, setSearchField] = useState("");
  const [selectedSite, setSelectedSite] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [page, setPage] = useState(1);
  const intl = useIntl();

  let filteredSensorsArray = [];
  if (userSensors.length != 0) {
    filteredSensorsArray = userSensors.filter((sensor) =>
      !sensor.name || !sensor.site_name || !sensor.status
        ? false
        : sensor.name.toLowerCase().includes(searchField.toLowerCase()) &&
          sensor.site_name.toLowerCase().includes(selectedSite.toLowerCase()) &&
          sensor.status.toLowerCase().includes(selectedStatus.toLowerCase())
    );
  }

  //------------ADDING SENSOR--------------
  const addingSensorValidationSchema = Yup.object().shape({
    name: Yup.string().required(
      intl.formatMessage({ id: "THIS.FIELD.IS.REQUIRED" })
    ),
    description: Yup.string().required(
      intl.formatMessage({ id: "THIS.FIELD.IS.REQUIRED" })
    ),
    type: Yup.string().required(
      intl.formatMessage({ id: "THIS.FIELD.IS.REQUIRED" })
    ),
    site: Yup.string().required(
      intl.formatMessage({ id: "THIS.FIELD.IS.REQUIRED" })
    ),
  });
  const addingSensorFormik = useFormik({
    validationSchema: addingSensorValidationSchema,
    initialValues: { name: "", description: "", type: "", site: "" },
    enableReinitialize: true,
    onSubmit: (values) => {
      let requestBody = {
        user_uuid: selectedUser.uuid,
        sensor_description: values.description,
        sensor_name: values.name,
        sensor_type: values.type,
        custom_icon: values.type,
        site_name: values.site,
        status: "enabled",
        site_uuid: sites.filter((site) => site.name == values.site)[0].uuid,
        user_name: selectedUser.name,
        user_username: selectedUser.username,
      };
      addSensor(
        requestBody,
        selectedUser.uuid,
        intl.formatMessage({ id: "SENSOR.WAS.SUCCESSFULLY.ADDED" })
      );
    },
  });
  const addingSensorFormFields = [
    { value: "name", placeHolder: intl.formatMessage({ id: "NAME" }) },
    {
      value: "description",
      placeHolder: intl.formatMessage({ id: "DESCRIPTION" }),
    },
  ];
  let sitesNames = [];
  if (sites.length != 0) {
    sites.map((site) => sitesNames.push(site.name));
  }
  //---------------------------------------------

  const tableAttributes = {
    table: {
      columns: [
        {
          label: intl.formatMessage({ id: "NAME" }),
        },
        {
          label: intl.formatMessage({ id: "TYPE" }),
        },
        {
          label: intl.formatMessage({ id: "SITE" }),
        },
        {
          label: intl.formatMessage({ id: "DESCRIPTION" }),
        },
        {
          label: intl.formatMessage({ id: "STATUS" }),
        },
        {
          label: intl.formatMessage({ id: "ACTIONS" }),
        },
      ],
      tableRow: (sensor, index) => (
        <UserSensorsTableRow key={index} sensor={sensor} />
      ),
    },
    emptyMessage: intl.formatMessage({ id: "NO.SENSORS.AVAILABLE" }),
    title: {
      title: intl.formatMessage({ id: "USER'S.SENSORS" }),
      icon: <DnsRoundedIcon />,
    },
    pagination: {
      handleChange: (event, value) => {
        setPage(value);
      },
      rowsPerPage: 7,
      currentPage: page,
    },
    search: [
      {
        placeHolder: intl.formatMessage({ id: "SEARCH.BY.SENSOR.NAME" }),
        handleChange: (e) => {
          setSearchField(e.target.value);
        },
        variant: "standard",
        style: { width: "55%", marginRight: "6px", maxWidth: "220px" },
        size: "medium",
      },
    ],
    select: [
      {
        array: sitesNames,
        handleChange: (e) => {
          setSelectedSite(e.target.value);
        },
        value: selectedSite,
        label: intl.formatMessage({ id: "SITE" }),
        variant: "standard",
        style: { width: "35%", marginRight: "6px", maxWidth: "200px" },
      },
      {
        array: ["enabled", "disabled"],
        handleChange: (e) => {
          setSelectedStatus(e.target.value);
        },
        value: selectedStatus,
        label: intl.formatMessage({ id: "STATUS" }),
        variant: "standard",
        style: { width: "35%", marginRight: "6px", maxWidth: "200px" },
      },
    ],
    dialogButtons: [
      {
        buttonTitle: intl.formatMessage({ id: "ADD.SENSOR" }),
        buttonClassName: "btn btn-primary font-weight-bolder font-size-sm",
        dialogTitle: intl.formatMessage({ id: "ADDING.SENSOR" }),
        closingAction: () => {
          clearErrorMessages();
          addingSensorFormik.resetForm();
        },
        dialogContent: (
          <CustomForm
            fields={addingSensorFormFields}
            errorMessage={errorMessage.createSensor}
            isLoading={isLoading.createSensor}
            formik={addingSensorFormik}
            buttonTitle={intl.formatMessage({ id: "ADD.SENSOR" })}
            additional={[
              <Select
                style={{ width: "100%" }}
                array={sitesNames}
                otherProps={addingSensorFormik.getFieldProps("site")}
                none={true}
                placeHolder={intl.formatMessage({ id: "SITES" })}
              />,
              <Select
                style={{ width: "100%" }}
                array={[
                  "sos",
                  "window",
                  "smoke",
                  "temperature&humidity",
                  "motion",
                  "door",
                  "other",
                ]}
                otherProps={addingSensorFormik.getFieldProps("type")}
                none={true}
                placeHolder={intl.formatMessage({ id: "SENSOR.TYPE" })}
              />,
            ]}
          />
        ),
      },
    ],
  };
  return (
    <TableWidget
      select={tableAttributes.select}
      search={tableAttributes.search}
      pagination={tableAttributes.pagination}
      dialogButtons={tableAttributes.dialogButtons}
      table={tableAttributes.table}
      title={tableAttributes.title}
      emptyMessage={tableAttributes.emptyMessage}
      givenArray={filteredSensorsArray}
    />
  );
};

const mapStateToProps = createStructuredSelector({
  sites: selectSitesArray,
  selectedUser: selectSelectedUser,
  isLoading: selectSensorsIsLoading,
  errorMessage: selectSensorsErrorMessages,
});

const mapDispatchToProps = (dispatch) => ({
  addSensor: (requestBody, uuid, message) =>
    dispatch(createSensorAsync(requestBody, uuid, message)),
  clearErrorMessages: () => dispatch(clearSensorsErrorMessages()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserSensorsTable);
